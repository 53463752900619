const strings: any = {
  alertTitle: "Kinside Enrollment Platform",
  altLogo: "LegUp powered by Kinside logo",
  answer: "Answer",
  appTitle: "Provider Platform",
  appTitleParent: "Child Care Enrollment",
  appTitlePartnerForm: "{Name}'s Child Care Access",
  appTitleWaitlist: "{Name}'s Waitlist",
  back: "Back",
  cancel: "Cancel",
  delete: "Delete",
  ok: "Ok",
  save: "Save",
  done: "Done",
  continue: "Continue",
  companyName: "Kinside, Inc.",
  confirm: "Confirm",
  edit: "Edit",
  remove: "Remove",
  duplicate: "Duplicate",
  contactMessage:
    "Please reach out to the Kinside team at {Email} and someone will be in touch as soon as possible!",
  adminOnly: "These sections are only visible to KinTeam",
  cost: {
    hourly: "/ hour",
    daily: "/ day",
    weekly: "/ week",
    monthly: "/ month",
    annually: "/ year",
  },
  days: [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ],
  dayAbbreviations: ["M", "Tu", "W", "Th", "F", "Sa", "Su"],
  dayShort: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  fillWaitlistMessage:
    "Clicking this button will start the process of emailing eligible families on this waitlist until a match is found. This functionality is not yet implemented. You can open this waitlist to see the children on it and manually reach out to them in order for now.",
  findCenter: "Find Programs",
  helpMessage:
    "Your Provider Operations team can help you with any questions you may have.",
  helpQuestions: "Questions?",
  legupEmail: "support@kinside.com",
  loading: "Loading...",
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  next: "Next",
  noFirstName: "Baby",
  noLastName: "Family",
  no: "No",
  seatName: "Seat ID {ID}",
  search: "Search",
  searching: "Searching...",
  usefulNew: "New Feature!",
  usefulThanks: "Thank you for your feedback",
  usefulTip: "Was this information helpful?",
  yes: "Yes",
  banner: {
    timezone: "Heads up: Times below are shown in the [{Timezone}] time zone.",
  },
  action: {
    noActions: "You have no seats requiring your attention at this time.",
    buttonSave: "Save changes",
    changeContract: "Resend Registration Form",
    colAnswered: "Answered",
    colChild: "Child",
    colSeatName: "Seat Name",
    colStatus: "Status",
    colParent: "Parent / Guardian",
    colDate: "Date",
    confirmChange: "Do you want to move this seat status to \"{Status}\"?",
    confirmRemove: "Do you want to remove {Name} from your waitlist?",
    confirmDeleteGeneralTour: "Are you sure you want to delete this tour?",
    confirmDeleteEnrollmentTour:
      "When you delete this tour, the family will still keep their seat offer but will be notified of the canceled tour. You can remove the family from the seat in the Seats page. Would you like to continue?",
    deepLinkTitle: "Action center deep links",
    deepLinkInstructions:
      "Share one of these URLs with a provider. They will be able to visit the URL and it will open up the Action center taking the specified action for this item.",
    labelChild: "Child: {Child}",
    labelParent: "Parent / Guardian: {Parent}",
    labelState: "As of: {Date}",
    menuItemChangeContract: "Change tuition rate",
    menuItemSplit: "Split seat",
    menuItemAddTour: "Schedule tour",
    menuItemEditTour: "Edit tour",
    menuItemDeleteTour: "Cancel tour",
    menuItemEditSeat: "Edit seat",
    menuItemEnroll: "Enroll",
    menuItemMarkEnrolled: "Mark as enrolled",
    menuItemFamilyDetails: "View family details",
    menuItemFillSeat: "Mark seat as filled",
    menuItemWaitlistQrCode: "Generate QR Code for Waitlist",
    menuItemWaitlistFormLink: "Send waitlist form to family",
    menuItemDeepLink: "Get Deep Link URL to take action",
    menuItemOfferLink: "Get registration form URL",
    menuItemPass: "Family passed, but keep on waitlist",
    menuItemPpwkSent: "Approve family for enrollment",
    menuItemRemove: "Family passed; remove from waitlist",
    menuItemTour: "Schedule tour",
    noEditLegUpOffer:
      "You must wait for a response from the Kinside family this seat was offered to before making changes.",
    offerLink:
      "Scan this QR code or visit this link to complete registration paperwork with {Center}. Note that this link is unique for this offer",
    sendWaitlistForm:
      "{Name} expressed interest from your Enrollment Page and is not on your waitlist. Would you like to send them instructions on how to join your waitlist?",
    statusSelect: {
      enrolled: "Family Enrolled",
      "ready to enroll": "Ready to Enroll",
      "sent paperwork": "Approve Enrollment",
      "schedule tour": "Ready to Schedule Tour",
      "parent question": "Family Has Question",
      "parent passed": "Family passed, but keep on waitlist",
      "parent declined": "Family passed, remove from waitlist",
      "question answered": "Family Question Answered",
      "tour scheduled": "Schedule A Tour",
      "split seat": "Split Seat",
    },
    tabSeats: "Seats requiring action",
    tabTours: "Tour schedule",
    title: "Action center",
    tourInstructions:
      "Please enter a tour date. The family has the following notes: {Notes}",
    updateSpotInstructions:
      "In order for the family to pass on this offer and stay on your waitlist, you must update their preferences to reflect why they cannot take this offer but could take a future one. If you're unsure what to set, choose \"I'm not sure how the family is considering future offers\" and they will be prompted to update this information themselves.",
    updateSpotTitle: "Update family Preferences",
    waitlistLink:
      "Scan this QR Code or visit this link to sign up for {Center}'s waitlist",
    family: "family",
    families: "families",
    potential: "potential",
    seatOffer: "Seat Offer",
    offers: "Sent offers",
    offersDescription:
      "This section shows families that need to take action on offers sent to them.",
    questions: "Questions asked",
    questionsDescription:
      "This section shows families that have open questions. If the question is answered, click the \"Take Action\" button or move the card to a different column.",
    tours: "Tours scheduled",
    toursDescription:
      "This section shows families that have a tour in the past or in the future. When ready, mark the tour as \"complete\" and then click \"Take Action\", or move the card to a different column.",
    ppwkRequest: "Enrollment requested",
    ppwkRequestDescription:
      "This section shows all families who have requested enrollment and are ready to pay enrollment fees. When ready, click \"Take Action\" or move card to a different column.",
    ppwkSent: "Enrollment approved",
    ppwkSentDescription:
      "This column shows all families that you've approved for enrollment and pending enrollment fees.",
    takeAction: "Take Action",
    question: "Question:",
    questionAnswered: "Question answered",
    questionPopupTitle: "Question from {Name}",
    tourNoShow: "Family didn't show for tour",
    seatOnHold: "Seat on hold",
    paymentPending: "Payment Pending",
    offered: "Offered",
    desired: "Desired Date",
    enrollmentTour: "Enrollment Tour",
    generalTour: "General Tour",
    virtualTour: "Virtual Tour",
    inPersonTour: "In-Person Tour",
    tourModal: {
      title: "Set up a new tour",
      lead: "At what date and time should the tour happen?",
      submit: "Submit tour",
      cancel: "Cancel",
    },
    moveRestriction:
      "You can only move families to the Tour or Enrollment Approved columns",
    noSeats:
      "There are no available seats at this center to offer this family. Please click Post Open Seat if you want to create a new seat for this family",
    moveTo: "Move to {To}",
    searchSeats: "Search Seats...",
    allCenters: "All locations",
    selectLabel: "Choose Center",
    toScheduleTours: "To Schedule",
    pastTours: "Past Tours",
    futureTours: "Future Tours",
  },
  activityLog: {
    active: "Yes",
    you: "You",
    colActive: "Active",
    colDate: "Date",
    colDescription: "Description",
    colState: "Note",
    colTitle: "Title",
    colCenter: "Program",
    notActive: "No",
    title: "Activity Log",
  },
  addParentWaitlist: {
    alertAdded: "Thanks, we have added you to {Center}'s waitlist",
    buttonPayment: "Signup for waitlist",
    familyTitle: "Select Tour Time",
    fromFamilySubtitle:
      "If you have notes about this tour for the provider, you can enter them here",
    fromFamilyTitle: "To Director",
    labelChild: "Select a child",
    navigatorChild: "{Child} (Parent / Guardian {Parent})",
    sectionSpot: "Waitlist Preferences",
    sectionSchedules: "Select Schedules",
    sectionQuestions: "Custom Questions",
    sectionPayment: "Payment Details",
    title: "Join {Center} Waitlist",
  },
  addParentTour: {
    buttonSave: "Request Tour",
    buttonUpdate: "Update Tour",
    familyTitle: "Select Tour Time",
    fromFamilySubtitle:
      "If you have notes about this tour for the provider, you can enter them here",
    fromFamilyTitle: "To Director",
    labelChild: "Select a child",
    navigatorChild: "{Child} (Parent / Guardian {Parent})",
    sectionTourTime: "Select Date and Time",
    sectionNotes: "Notes",
    title: "Schedule Tour",
  },
  address: {
    address: "Address",
    address2: "Suite / Apt",
    city: "City",
    state: "State",
    zipCode: "ZIP Code",
    addressRequired: "Address (*)",
    cityRequired: "City (*)",
    zipCodeRequired: "ZIP Code (*)",
  },
  addFamily: {
    buttonImport: "Or Import List",
    promptForPayment:
      "Prompt the family to pay a waitlist signup fee. If they do not pay within 4 days they will be removed from the waitlist",
    cleanFamily:
      "Send an email to the family to verify they wish to remain on this waitlist",
    comments: "Comments",
    preferredSchedule: "Preferred Schedule",
    referral: "Referral",
    sectionProgram: "Choose Schedule for {AgeGroup}",
    title: "Add Single Family to {Center}",
    titleReadOnly: "Waitlist Form for {Center}",
    titleFindCenter: "Choose a Program",
    titleLegup: "Add family to Kinside List",
  },
  inviteFamilyDialog: {
    inviteFamilyMenuLink: "Invite family to join waitlist",
    inviteFamily: "Invite family",
    sendButton: "Send invite",
    header: "Invite a family to join your waitlist!",
    subheader: "The family will receive an email with a link to join your waitlist.",
    emailLabel: "Parent/guardian email address",
    childNameLabel: "Child's first name (optional)",
    sendSuccess: "Invitation is on the way!",
    sendFail: "The email could not be created. Please try again or contact support at support@kinside.com.",
    emailValidationError: "Whoops! That email doesn’t look right. Try again.",
  },
  addSeat: {
    legupMatch:
      "There are no families on their waitlist for this seat, but there is a Kinside family nearby that we can offer this seat to",
    legupMatchOverQuota:
      "There are no families on their waitlist for this seat. There is a Kinside family nearby but they've exceeded the number of offers we send to Kinside families",
    open: "There is a child, {Child}, who will be offered this seat in 10 minutes",
    nofamilies: "There are no families on their waitlist for this seat",
    nearHit:
      "The seat has no matches, but we can offer this seat to if they change {Changes}",
    available: "the available date to {Date}",
    minage: "the minimum age to {Age} months",
    minageWeeks: "the minimum age to {Age} weeks",
    maxage: "the maximum age to {Age} months",
  },
  addToWaitlist: {
    addDate: "Waitlist Add Date",
    answerRequired:
      "Please answer all required questions marked with (*) to join this waitlist.",
    closingHours: "Closes at",
    confirm:
      "Confirm your details below to learn more about openings that fit your family's needs:",
    confirmPre: "Please confirm that you want to add ",
    confirmPost: " to this waitlist",
    customAddDate: "Use a different add date than today",
    hasPriority: "Has Priority",
    legupCaption:
      "Search for available in your area, and be the first to know when child care programs add spots that match your family's preference.",
    legupTitle: "Join The Kinside Concierge",
    openHours: "Opens at",
    preferredDate: "Preferred Enrollment Date",
    priority: "Priority",
    title: "Add to Waitlist",
  },
  adhocQuery: {
    buttonDownload: "Download",
    title: "Ad Hoc Query",
    subtitle:
      "Choose a query from the list below to run. If you would like to add a query to this list, reach out to engineering",
    noResults: "Sorry, there were no results for your query",
    results: "Results",
  },
  adminTools: {
    title: "Admin actions",
    names: {
      addfamily: "Add family",
      adhocquery: "Run Ad Hoc Query",
      demographics: "Demographics Report",
      duplicates: "Duplicate Families",
      importprovider: "Import/Export Provider",
      legupfamilyseats: "Send seat offers to Kinside Families",
      partnerprovider: "Update Partner Provider Mapping",
      marketplacedisplay: "Marketplace Ban",
      messagedelays: "Recall sent messages",
      navigators: "Manage Navigators",
      openedemails: "Opened Email Summary",
      partners: "Manage Partners",
      provideraction: "Provider Action Center",
      providerlogin: "Login as provider",
      providertextoptin: "Provider Text Opt-in",
      registrationcode: "Registration Code",
      subsidies: "Manage Subsidies",
      datafiles: "Help Topics",
      waitlistheaders: "Waitlist Form Header",
      reminders: "Reminders",
      viewdashboard: "View Provider Dashboard",
      viewemails: "Outgoing Messages",
      transfers: "Transfer to new Stripe Providers",
      uxsettings: "Manage UX Settings",
      stuckpayments: "Handle Stuck Payments",
    },
  },
  adminUnit: {
    titleDetails: "{Name} Administrative Unit Details",
    titleFindAdminUnit: "Find administrative unit",
    findInstructions:
      "An administrative unit manages multiple providers. On this tab, you can manage administrative units and assign existing providers to them. Please visit the Manager providers or Manage programs page to set up or change programs",
  },
  adminUnitDetails: {
    buttonAdd: "Add Administrative Unit",
    buttonSave: "Save changes",
    managerName: "Admin Unit Contact Name",
    name: "Name",
    email: "Email",
    phoneNumber: "Phone Number",
    title: "Admin Unit Details",
    titleWaitlist: "Waitlist Settings",
    titleWaitlistNoProviders:
      "Waitlist Settings - Save changes to add providers before completing this section",
    waitlistFeeTitle: "Admin Unit Waitlist Fees",
    waitlistForm: "This admin unit has a waitlist form",
  },
  adminUnitList: {
    buttonAdd: "Add Admin Unit",
    findAdminUnit: "Find Admin Unit",
    location: "Location",
    name: "Name",
    noMatch: "No matching administrative units were found",
  },
  ageGroups: {
    buttonAdd: "Add age group and classrooms",
    colAgeGroup: "Age group",
    colAges: "Age range",
    colBudgetedCapacity: "Budgeted Capacity",
    colEditAgeGroup: "Edit age group",
    colSeats: "Open spots",
    colUpdate: "Update parents / guardians",
    colViewWaitlist: "View waitlist",
    deleteAgeGroup: "Delete age group",
    cloneAgeGroup: "Clone age group",
    fillSeat: "Fill spot",
    months: "months",
    title: "Age groups",
    otherAgeGroupTitle: "Other Program age groups",
    viewWaitlist: "View Waitlist",
    weeks: "weeks",
    loadingAgeGroups: "Loading age groups",
    noAgeGroups: "You haven't added any age groups yet",
    addAgeGroup: "Add age group",
    addAgeGroups: "Add age groups",
    editAgeGroup: "Edit age group",
    editAgeGroups: "Edit age groups",
    deleteAgeGroupAreYouSure:
      "Are you sure you'd like to delete this age group?",
    cannotDelete: "You cannot delete this age group",
    cannotDeleteDescription: "You must have at least 1 age group.",
    ageGroupName: "Age group name",
  },
  classrooms: {
    noClassroomsYet: "You haven’t added a classroom for this age group yet",
    addAClassroom: "Add a classroom",
    editSpot: "Edit spot",
    spotHistory: "Spot history",
    duplicateSpot: "Duplicate spot",
    splitSpot: "Split spot",
    unpauseSpot: "Unpause spot",
    pauseSpot: "Pause spot",
    removeSpot: "Remove spot",
    offerSpot: "Offer spot to family",
    paused: "Paused",
    processing: "Processing...",
    open: "Open",
    openSpots: "Open spots",
    addOpenSpot: "Add open spot",
    fillSpotFromWaitlist: "Fill spot from waitlist",
    spotType: "Spot type",
    status: "Status",
    whenAvailable: "When available",
    ageGroup: "Age group",
    ageGroups: "Age groups",
    createClassroomsHeader: "Let's create your classrooms",
    classroomsDescription:
      "This is where you'll organize your enrolled families and post open spots.",
    getStarted: "Get started",
    addLater: "I'll add classrooms later",
    areAgeGroupsCorrect: "First, let's make sure your age groups are correct",
    addClassroom: "Add classroom",
    addClassrooms: "Add classrooms",
    mustAddAgeGroupsFirst:
      "You must add an age group before you can add a classroom.",
    editClassrooms: "Edit classrooms",
    classrooms: "classrooms",
    classroomName: "Classroom name",
    addNamePlaceholder: "Add name...",
    saveClassrooms: "Save classrooms",
    adminOnly: "Admin Only",
  },
  ageGroupGrid: {
    agegroup: "AgeGroup #{AgeGroup}",
    ageGroupInvalid:
      "You have an age group with invalid age ranges such as the maximum age less than the minimum age",
    ageGroupWarningGap:
      "You have a gap between the ages of your age groups. Please fix this, otherwise children may not properly move to the next age group when they hit the maximum age",
    ageGroupWarningOverlap:
      "Heads up: You have a gap between your age groups! Adjust your age groups to make sure children are in the correct group.",
    ageGroupNoName:
      "You have an age group without a name. Please fix this before continuing",
    classroomCapacityWarning:
      "You have classrooms without a budgeted capacity set.",
    classroomNoName:
      "You have a classroom without a name. Please fix this before continuing",
    buttonAddAgeGroup: "Add age group",
    buttonAddClassroom: "Add classroom",
    classrooms: "Classrooms",
  },
  ageGroupList: {
    ageWeeks: "{age} weeks",
    ageMonths: "{age} months",
    buttonAdd: "Add age group",
    buttonImport: "Import waitlists",
    importTitle: "Import Waitlist",
    name: "Name",
    minAge: "Minimum age",
    maxAge: "Maximum age",
    select: "Pick an age group to import into",
    title: "Add another age group or click Import to import waitlists",
  },
  ageGroupWizard: {
    ageGroupDetails: "Enter age group details",
    buttonNextPrograms: "Next - Connect schedules and tuition",
    buttonNextContracts: "Next - Set tuition rates",
    buttonNextDone: "Save changes",
    contractRequired:
      "Please choose at least one tuition rate for each chosen schedule. If you are not sure which pricing options you have associated with each schedule, please visit the Tuition Manager",
    programRequired: "Please choose at least one schedule for this age group",
    selectContracts: "Choose available tuition rate for this age group",
    selectPrograms: "Choose available schedules for this age group",
    titleAdd: "Adding age group to {Center}",
    titleEdit: "Editing {AgeGroup} for {Center}",
  },
  answerQuestion: {
    chooseLabel: "Choose Response",
    missingAnswers: "Please answer all required questions",
    referralLabel: "Please enter your referral",
    sectionQuestions: "Program specific questions",
  },
  applyCoupon: {
    buttonCoupon: "Apply Discount",
    couponApplied: "Thanks. Your discount has been applied",
    couponInvalid: "Sorry, this is not a valid discount code",
    couponLabel:
      "If you have a discount code, enter it here and click Apply Discount to update the payment amount",
    couponText: "Discount code",
    enterCoupon: "Have a discount code?",
  },
  auth: {
    acceptTerms:
      "Thank you for joining Kinside! To continue to our site please accept our Terms of Service.",
    buttonLogin: "Login as different user",
    invalidUser:
      "We could not find a user with the email address {email} in our system. Please make sure you are using the same email address that you receive Kinside communications from, or contact Kinside support at support@kinside.com for assistance.",
    mustAcceptTerms: "You must accept Kinside's Terms of Service to continue.",
    readTerms: "I have read and agree to the ",
    termsOfUse: "Terms of Service",
    loadingZendesk: "Redirecting to Zendesk...",
  },
  basicsFilter: {
    instructions: "",
  },
  billing: {
    buttonSave: "Save changes",
    providerCommissionFees: "Kinside commission for waitlist join",
    includeStripe: "Add Stripe fees (2.9% + 30 cents) to total",
    maximum: "Maximum amount to charge. Enter 0 if no maximum",
    minimum: "Minimum amount to charge",
    parentPays:
      "The commission is added to the parent / guardian's tuition bill",
    percent: "Commission percentage",
    radioDefault: "Use the default Kinside commission for this provider",
    radioNotDefault: "Use a custom Kinside commission for this provider",
    saveSuccess: "Transaction fee updated!",
    saveFail: "There was a problem updating the transaction fee",
    sectionTitle: {
      deposit: "Registration fee",
      waitlist: "Waitlist fee",
      tuition: "Tuition Fee",
    },
    titleBilling: "Commission rate for {Provider}",
    titleFindProvider: "Choose a provider",
  },
  centerCard: {
    addContent: "Add any other child care waitlists you're on",
    addContentNavigator:
      "Add any other child care waitlist your families are on",
    addTitle: "Add a program",
  },
  centerDetails: {
    profile: "Profile",
    selectCenterHelper:
      "Select a location to edit profiles for individual locations",
    advancedOptions: "Advanced options",
    ageGroupWarningGap:
      "You have a gap between the ages of your age groups. Please fix this, otherwise children may not properly move to the next age group when they hit the maximum age",
    ageGroupWarningOverlap:
      "Heads up: You have a gap between your age groups! Adjust your age groups to make sure children are in the correct group.",
    alertInactive:
      "Note that inactive programs will still appear as a location in the marketplace, while deleted programs will be removed from all marketplace UI. Note that YOU ARE RESPONSIBLE to contact any families who may be on this program's waitlist as they will no longer have visibility into this program.",
    buttonAdd: "Add Program",
    buttonDelete: "Delete Program",
    buttonEdit: "Edit",
    buttonEditing: "Editing...",
    centerDetails: "Program details",
    confirmDelete:
      "Are you sure you want to delete this program? Note you can only do this for newly created programs without waitlists",
    deleteFile: "Delete File",
    email: "Email address",
    licenseExpires: "License Has Expiration Date",
    licenseExempt: "Center is License Exempt",
    licenseExpireTitle: "Expiration Date",
    onboarded: "This location linked their website.",
    connect: "Connect",
    managed: "Partner",
    marketplace: "Marketplace",
    inactive: "Inactive (location still open)",
    deleted: "Deleted (location closed)",
    managerEmail: "Manager Email",
    managerName: "Manager Full Name",
    managerPhone: "Manager Phone Number",
    profilePage: "Profile Page",
    profilePageTitle: "Customize Your Enrollment Page",
    none: "None",
    relationship: "Relationship",
    tabSearch: "General Info",
    tabImages: "Image Gallery",
    tabPageStyling: "Page Styling",
    tabPhilosophy: "About Us",
    tabStaff: "Program Staff",
    tabSocial: "Social Media",
    turnaroundTime: "Days families have to respond to an offer (1-4)",
    typeLabel: "Program Type",
    type: {
      none: "Unknown",
      home: "Home",
      center: "Center",
    },
    scheduleTypesList: [
      "Full time",
      "Part time",
      "Full day",
      "Half day AM",
      "Half day PM",
      "Early drop-off",
      "Late pickup",
      "Before school",
      "After school",
      "Extended hours",
      "Overnight",
      "1st Shift / Day shift",
      "2nd Shift / Swing shift",
      "3rd Shift / Night shift",
      "Evening care",
      "24 hour",
      "Weekend",
      "Drop-in",
      "Full year",
      "School year",
      "Summer care",
      "Summer camp",
    ],
    // help
    profileCompleteness: "Profile completeness: {percentage}%",
    profileCompletenessXs: "Completeness: {percentage}%",
    profileEditHelper:
      "Families learn more about your program on your profile. A personalized and complete profile helps interested families know if you are a good fit.",
    addWebsite: "Add website",
    addAboutUs: "Add about us",
    addPhilosophies: "Add philosophies",
    addCurriculum: "Add curriculum",
    addAbilities: "Add abilities",
    addLanguages: "Add languages",
    addEmail: "Add email",
    addLink: "Add {app} link",
    addPrimaryPhone: "Add primary phone",
    addSecondaryPhone: "Add secondary phone",
    addTourLink: "Add video link",
    aboutHelper:
      "Share your educational philosophy or what makes your approach to caregiving special.",
    addDirector: "Add name and photo",
    addAccessibility: "Add accessibility",
    // contact details
    location: "Location",
    website: "Website",
    address: "Address",
    instagram: "Instagram",
    facebook: "Facebook",
    contactDetails: "Contact info",
    primaryPhoneNumber: "Primary phone number",
    alternatePhoneNumber: "Alternate phone number",
    // license
    license: "License #",
    licensed_capacity: "Capacity",
    licenseUrl: "Licensing link",
    accessibility: "Accessibility",
    vettingStatus: "Vetting Status",
    healthAndSafety: "Health & Safety",
    // about
    about: "About",
    aboutUs: "About us",
    director: "Director",
    directorName: "Director name",
    directorPhoto: "Director photo",
    curriculum: "Curriculum",
    philosophies: "Philosophies",
    languages: "Languages",
    taught: "Taught: ",
    spoken: "Spoken: ",
    abilities: "Abilities",
    aboutHelpText:
      "Share your educational philosophy or the day to day caregiving approach that makes you special",
    // Program details
    programDetails: "Program details",
    locationOverview: "Location overview",
    programType: "Program type",
    ageRange: "Age range",
    editAgeRange:
      "Edit in the <a class='{className}' href=\"/classrooms\">Classrooms</a> section",
    editSchedules:
      "Edit in the <a class='{className}' href=\"/contracts\">Tuition</a> section",
    editTuition:
      "Edit in the <a class='{className}' href=\"/contracts\">Tuition</a> section",
    scheduleDetailsFull: "Full days, {full} days. ",
    scheduleDetailsHalf: "Half days, {half} days. ",
    classrooms: "Classrooms",
    daysHours: "Days & Hours",
    tuition: "Tuition",
    schedules: "Schedules",
    scheduleTypes: "Schedule type(s)",
    photosVideos: "Photos & videos",
    locationPhotos: "Photos",
    logo: "Logo",
    tourVideo: "Tour video",
    addLogo: "Upload your logo",
    // sections
    documents: "Documents",
    subsidies: "Subsidy programs accepted",
    enrollments: "Profile settings",
  },
  centerDirectory: {
    centersFound: "{Count} programs found",
    noCenters: "There are no programs in your area at this time",
    noCentersFilter: "There are no programs that match your search.",
  },
  centerForm: {
    availableNow: "Available Now",
    buttonAdd: "Add family",
    buttonEditDepositQuestions: "Edit Registration Form Questions",
    buttonEditWaitlistQuestions: "Edit Waitlist Form Questions",
    editQuestionsNote:
      "To add to or change these questions, reach out to support@kinside.com",
    buttonPreview: "Preview profile",
    kinsideProfileEditor: "Kinside Profile Editor",
    buttonSaveQuestions: "Save changes",
    comments: "Comments",
    commentsLabel: "Any additional comments or special requests?",
    contractLabel: "Preferred Schedule/Pricing Option",
    depositPreview:
      "Here is where your customers would see the registration amount for the pricing option they chose, including the amount that is non-refundable",
    displayMap: {
      none: "Your profile page will not show your address or a map.",
      postal_code:
        "Your ZIP code, including a map, will show on your profile page.",
      show: "Your complete address, including a map, will show on your profile page.",
    },
    displayWaitlist: {
      none: "Families cannot join your waitlist before taking a tour.",
      show: "Families can join your waitlist before taking a tour.",
    },
    displayPaperwork: {
      none: "Families cannot request registration form",
      options: "Families can request registration form when {Options}",
      claim: "claiming an open seat",
      offer: "responding to a seat offer",
      tour: "after a tour",
    },
    displayTours: {
      none: "Families will not be able to schedule tours from your profile page.",
      general: "Families can schedule general tours on your profile page.",
      claim: "Families can schedule enrollment tours (when offered a spot or when a spot is available on your profile page).",
      waitlist: "Families can schedule a tour if they are already on your waitlist.",
      claim_general: "Families can schedule general tours on your profile page and enrollment tours.",
      general_waitlist: "Families can schedule general tours on your profile page, or when they are already on your waitlist.",
      claim_waitlist: "Families can schedule enrollment tours, or schedule tours when they are already on your waitlist.",
      all: "Families can schedule general tours on your profile page, enrollment tours, and when they are already on your waitlist.",
    },
    displayGoogle:
      "We will display tags associated with your Google account {Account}",
    editDisplayMap:
      "How should a map with your location show on your profile page?",
    editDisplayWaitlist:
      "Can families join your waitlist before taking a tour?",
    editDisplayTour:
      "How can families schedule tours on your page? Check all that apply.",
    editDisplayOptions: "Profile settings",
    editImage: "Edit Section Image",
    educationalPhilosophy: "Educational Philosophy",
    invalidOptions:
      "You must have either the waitlist sign-up or schedule tour option available on your profile page",
    mapOption: {
      show: "Show a map with full address details",
      postalCode: "Show a map with only your ZIP code",
      noMap: "Do not show a map or display your address",
    },
    metaDescription:
      "{Center} is a licensed child care program located in {City}.",
    optimalSize:
      "Make sure your image is smaller than 5 MB. Your image will be scaled and cropped to a square. For best results, upload a square image.",
    paidDate: "<b>Paid:</b> {Date}",
    payoutDate: "<b>Payment Transfered:</b> {Date}",
    pageView: "Click to see your live enrollment page",
    receipt: "<b>Receipt:</b> {Receipt}",
    referral: "Referral",
    seatAge: "<b>Age range of Classroom:</b> {Age}",
    seatDate: "<b>Seat Available:</b> {Date}",
    seatNotes: "Notes from provider",
    seatProgram: "<b>Schedule:</b> {Days} | {Program}",
    sectionChild: "Child Details",
    sectionDeposit: "Payment Information",
    sectionParent1: "Parent / Guardian 1 Details",
    sectionParent2: "Parent / Guardian 2 Details (optional)",
    sectionReceipt: "Receipt Details",
    sectionQuestions: "Program-specific Questions",
    sectionSeat: "Seat Details",
    sectionSpot: "Additional Details",
    sectionSubsidies: "Subsidy Programs",
    selectedContract: "<b>Schedule:</b> {Contract}",
    startDate: "<b>Start Date:</b> {Start}",
    subTotal: "<b>Amount due to you:</b> {Total}",
    titleChangeDeposit: "Registration Form Questions",
    titleChangeWaitlist: "Waitlist Questions",
    total: "<b>Total:</b> {Total}",
    seo: "Program SEO Description",
    summary: "Program Summary",
    tour: "Tour Video",
    tourOption: {
      general: "Families can schedule general tours on your profile page.",
      claim:
        "Families can schedule enrollment tours (when offered a spot or when a spot is available on your profile page). ",
      waitlist: "Families can schedule a tour if they are already on your waitlist.",
    },
  },
  centerList: {
    altConnect: "Connect",
    altManaged: "Partner",
    altMarketplace: "Marketplace",
    altInactive: "Inactive",
    altDeleted: "Deleted",
    altNone: "None",
    center: "Program",
    findCenter: "Find Program or Provider",
    formWaitlist: "View Waitlist Form",
    formDeposit: "View Registration Form",
    haveWaitlist: "--",
    import: "No Waitlist",
    location: "City",
    partnerConnect: "This program is a Kinside Connect",
    partnerManaged: "This program is a Kinside Partner",
    partnerMarketplace: "This program is a Marketplace Only customer",
    partnerInactive:
      "This program was a Kinside partner but is no longer an active partner (the location is still open)",
    partnerDeleted:
      "This program was a Kinside partner but the location has closed",
    partnerNone: "This program is not signed up with Kinside",
    provider: "Provider",
    name: "Programs",
    noMatch: "No matching programs were found",
    title: "Program List",
    titleFindCenter: "Choose a Program",
    titlePaperwork: "Program Documents",
  },
  centerQuestions: {
    addQuestion: "Add Question",
    choiceAnswer: "Enter a valid response to this question",
    colForm: "Form",
    colPriority: "Priority",
    colQuestion: "Question",
    colRequired: "Required",
    colQuestionType: "Question Type",
    colDelete: "Delete?",
    defaultPriority: "Default",
    editCaption:
      "Click on a question to edit it, or click the trash icon to remove the question from this program's form. You can change the order that these questions will appear on the form by dragging and dropping them in the order you would like to see",
    editCaptionLegup:
      "Click on a question to edit it, or click the trash icon to remove the question from this list's form. You can change the order that these questions will appear on the form by dragging and dropping them in the order you would like to see",
    formBoth: "Registration and Waitlist",
    formDeposit: "Registration",
    formWaitlist: "Waitlist",
    multiChoice: "Multiple Choice",
    priority: "Priority",
    question: "Question",
    questionsTitle: "Custom Questions",
    required: "Required",
  },
  checkForFamily: {
    buttonNo: "Add {Name}",
    buttonYes: "Use {Name}",
    description:
      "We found an existing child, {Existing}, registered for this parent. Would you like to add a new child, {NewChild}, to this family or use the existing child?",
    title: "Confirm Child Name",
  },
  childDetails: {
    birthDate: "Birth or Expected Date",
    buttonAdd: "Add Child",
    buttonSave: "Save changes",
    buttonSeat: "Edit enrollment",
    buttonReEnroll: "Re-enroll",
    buttonEnroll: "Enroll",
    childTitle: "Child Details",
    female: "Female",
    firstName: "First Name",
    gender: "Gender",
    lastName: "Last Name",
    male: "Male",
    noGender: "Not Specified",
    otherGender: "Other",
    trying: "Expected Birth Date is TBD",
    useParentAddress: "Use same address as parent / guardian 1",
  },
  childDuplicate: {
    buttonDismiss: "These are different children",
    link: "Click here to remove {Duplicate} and keep this child ({Name})",
    subtitle: "See duplicate child records?",
    title: "Do you have the same child entered twice?",
    warning:
      "By proceeding, we will remove {Duplicate} from our records. Waitlist and tour details for {Duplicate} will be added to this child ({Name}). This action is irreversible. Would you like to continue?",
  },
  childHistory: {
    noHistory: "This child has not yet been processed by Kinside",
    states: {
      "added to waitlist": "Child added to waitlist",
      "contacted to stay":
        "Kinside reached out to the family to see if they are interested in staying on the waitlist",
      "deletion reminder":
        "The family was on hold for more than 30 days and sent a final deletion reminder",
      enrolled: "Child enrolled!",
      migrated: "The child aged out of one waitlist and onto another waitlist",
      "no response":
        "The family did not respond to their seat offer and were put on hold",
      "no response after inactive":
        "The family did not respond to a final warning after being on hold more than 30 days and were deleted. If you believe this is in error, contact support@kinside.com to reactivate",
      "parent confirmed":
        "The family confirmed they want to stay on the waitlist",
      "parent declined":
        "The family passed on a seat offer and removed themselves from your waitlist",
      "parent passed":
        "The family passed on the offer but stayed on your waitlist",
      "parent question": "The family asked a question about the seat offer",
      "parent requested removal":
        "The family requested to be removed from the waitlist",
      "question answered": "You answered the family's question",
      "ready to enroll": "The family indicated they are ready to enroll",
      "return to waitlist":
        "A family that had enrolled in a seat returned to the waitlist",
      "schedule tour": "The family requested a tour",
      "seat offered": "A seat was offered",
      "seat removed":
        "The seat which had been offered to this family was deleted",
      "sent paperwork": "Registration Form was sent to the family",
      "spot reactivated":
        "The family was on hold and reactivated their waitlist spot",
      "tour scheduled": "You scheduled an enrollment tour",
      "xf-parent question":
        "The family claimed a seat from your enrollment page and asked a question",
      "xf-tour scheduled":
        "The family claimed a seat from your enrollment page and scheduled an enrollment tour",
      "xf-ready to enroll":
        "The family claimed a seat from your enrollment page and is ready to enroll",
      "xf-sent paperwork":
        "The family claimed a seat from your enrollment page and is ready to enroll",
      "xf-question answered":
        "The family claimed a seat from your enrollment page and asked a question",
      "xp-parent question":
        "You offered this seat directly and indicated the family had a question",
      "xp-tour scheduled":
        "You offered this seat directly and scheduled a tour",
      "xp-ready to enroll":
        "You offered this seat directly and sent registration paperwork",
      "xp-sent paperwork":
        "You offered this seat directly and send registration paperwork",
      "xp-question answered":
        "You offered this seat directly and indicated the family had a question",
      offerRevoked: "You revoked the spot offer",
      enrollment_withdrawn: "Enrollment offer withdrawn:",
      enrollment_withdrawn_to_waitlist: "Enrollment offer withdrawn:",
      unenrolled: "Child un-enrolled",
      pending_enrollment: "Enrollment offered (pending)!",
    },
    title: "{Name} History",
  },
  childList: {
    buttonAdd: "Add Child",
    name: "Name",
    title: "Children",
  },
  childQuery: {
    buttonChooseFamily: "Choose Waitlist Family",
    buttonPosition: "Determine Position",
    buttonQRCode: "Generate QR Code",
    buttonRedoPosition: "Recalculate Position",
    buttonWaitlist: "Send waitlist form",
    buttonTour: "Schedule tour",
    buttonSeat: "View seats",
    chooseInstructions:
      "Do you have a new family looking for care and want to see what position they would have on your current waitlist? Enter the birthdate and preferred enrollment date of the new family here. To see this information for an existing family on your waitlist, close this pop-up and choose \"See position details\" next to the family's name in your waitlist.",
    chooseInstructionsExisting:
      "Make any changes to the preferred enrollment date for {Child}, then click below to see their detailed position information",
    labelEmail: "Email Recipient",
    saveSuccess: "Message sent!",
    saveFailure:
      "Something went wrong sending an update - please reach to Kinside support at support@kinside.com for assistance",
    instructionsQRCode: "Or generate a QR Code for the waitlist signup form",
    instructionsWaitlist:
      "Kinside can send the family a link to your waitlist form, and they can sign up online. Instructions will come from support@kinside.com",
    instructionsTour: "Want to schedule a general tour for this family?",
    instructionsSeat:
      "Need to view your open seats to see if there's a good opening for this family?",
    instructionsCenter: "Choose the program that the family is inquiring about",
    qrInstructions: "Scan this QR Code to sign up for {Center}'s waitlist",
    subtitlePosition:
      "If this family were to join the {AgeGroup} waitlist they would be ...",
    subtitlePositionExisting:
      "Here are details of this family's position on the {AgeGroup} waitlist",
    title: "Waitlist Planning",
    familyDetails: "Family details",
    childNotBorn: "Child not born",
    birthdate: "Birthdate",
  },
  childTableEntry: {
    inactive: "No response from parent / guardian",
    labelDNCB: "Do Not Contact Before",
    labelPrograms: "Preferred Schedules",
    noPrograms: "Not set",
  },
  childWaitlists: {
    addToCenter: "Add to program waitlist",
    buttonPositionDetails: "See position details",
    buttonReactivate: "Reactivate",
    colPreferred: "Preferred Enrollment",
    colCenter: "Program",
    colAgeGroup: "Age group",
    colPosition: "Position",
    colStatus: "Status",
    colInternalNotes: "Internal notes",
    missingPrograms: "Please choose preferred schedules",
    noPosition:
      "This program uses a waitlist pool for admissions. Detailed position information is not available",
    noWaitlist: "Not on any program waitlists",
    noWaitlistParent: "You are not on any program waitlists",
    onLegupList: "This child is number {Position} on the Kinside Waitlist",
    onLegupListParent: "You are number {Position} on the Kinside Waitlist",
    seatOffered: "This family has been offered a spot to this program",
    title: "Waitlists",
    transparent: "Click here for more details about this waitlist position",
    updateSpotInstructions:
      "In order to reactivate your waitlist spot with {Center}, you must update your preferences for future spot offers.",
    updateSpotTitle: "Reactivate Waitlist Spot",
  },
  classroomList: {
    badMaxCapacity:
      "The maximum capacity must be at least the current capacity",
    buttonAdd: "Add classroom",
    budgetedCapacity: "Budgeted Capacity",
    deleteClassroom: "Delete Classroom",
    editClassroom: "Edit Classroom",
    name: "Classroom Name",
  },
  commonPrograms: {
    "p.5.31": { name: "5 Day Monday - Friday", program: "5-day Full Time" },
    "p.3.31": { name: "3 Day Monday - Friday", program: "3-day" },
    "p.3.21": {
      name: "3 Day Monday / Wednesday / Friday",
      program: "3-day M/W/F",
    },
    "p.2.31": { name: "2 Day Monday - Friday", program: "2-day" },
    "p.2.10": { name: "2 Day Tuesday / Thursday", program: "2-day Tu/Th" },
    custom: { name: "Custom", program: "" },
    title: "Choose a schedule",
  },
  concierge: {
    buttonBack: "Back",
    buttonContinue: "Continue",
    buttonDone: "Done",
    buttonDoneNoContinue: "Not Right Now",
    buttonPreferredDate: "Click to set preferred date",
    cardTitle: "Your Concierge Results",
    errorNoSelection: "Please make a selection before continuing",
    errorPreferredDateSelection:
      "Please make sure you select a date in the future.",
    errorNoSubsidy:
      "Please let us know if you are eligible for a subsidy or select \"Don't Know\" if you are unsure",
    favoriteCard: {
      title: "See a program you like?",
      content:
        "Click the star in the lower-right of the card to favorite a program. We'll send you alerts when this program has a new spot.<br/><br/>Pro Tip! See a child care program you like? Click <b>View Program</b> or <b>Available Spots</b> to join their waitlist or schedule a tour!",
      buttonText: "Got it!",
    },
    pageTitle: "The Concierge",
    title: "Kinside Concierge",
    questions: {
      welcome: {
        title: "Welcome To Kinside!",
        subtitle:
          "Let's match your family to the best care that meets your needs, so you can get back to everything else.",
        choices: [{ match: "Get Matched to Care" }],
      },
      child: {
        title: "First, which child needs care?",
        subtitle:
          "Need to add another child? <a href=\"/families\">CLICK HERE</a> to add them.",
      },
      childretrieved: {
        title:
          "We saved your answers from the last time you visited The Concierge",
        subtitle:
          "Revisit the questions if you'd like to adjust your preferences, or skip to the end to see your matches.",
        choices: [
          { continue: "Revisit questions" },
          { skip: "Skip to the results" },
        ],
      },
      siblingretrieved: {
        title:
          "We saved your answers from the last time you took The Concierge Survey with a different child",
        label:
          "Please review the questions in The Concierge. We have pre-populated answers based on your responses from your other child's care needs.",
      },
      firstTimeCare: {
        title: "Is this your first time using child care?",
        choices: [
          { firsttime: "Yes, this is my first time" },
          { previous: "No, I've done this before" },
        ],
      },
      careTypes: {
        title:
          "Finding care will be much easier this time! What types of child care have you used in the past?",
        subtitle: "Choose all that apply",
        choices: [
          { center: "Child Care Center" },
          { fcc: "Family Care Program" },
          { nanny: "Nanny" },
          { aupair: "Au Pair" },
          { family: "Friends / Family" },
          { other: "Other" },
        ],
      },
      // Note this one is a bit special case
      preferred_address: {
        title:
          "Are you looking for child care closer to home, closer to work, or somewhere in-between the two?",
        label1: "Work, home, or in-between?",
        choices1: [{ work: "Work" }, { home: "Home" }, { both: "In-between" }],
        label2: "How far are you willing to drive from this location(s)?",
        choices2: [
          { 3: "3 miles" },
          { 5: "5 miles" },
          { 10: "10 miles" },
          { 25: "25 miles" },
        ],
      },
      mostImportant: {
        title: "What's important to you when searching for care?",
        subtitle: "Choose all that apply",
        choices: [
          { price: "Pricing" },
          { location: "Location" },
          { philosophy: "Educational Philosophy" },
          { socialization: "Socialization with peers" },
          { schedule: "Program Schedule" },
          { other: "Other" },
        ],
      },
      // Note this one is a bit different
      address: {
        title: {
          home: "Got it. What is your home address?",
          work: "Got it. What is your work address?",
          both: "Got it. What are those addresses?",
        },
        home: "Home Address",
        work: "Work Address",
        route: "Include programs on the route between these locations",
      },
      workAddress: {
        title: "What's the address of your office?",
      },
      budget: {
        title: "What is your monthly maximum budget for child care?",
      },
      subsidy_eligible: {
        title: "Are you eligible for any child care subsidies?",
        subtitle:
          "If you don't know whether you qualify for a subsidy, select \"I Don't Know\" and we'll send you more information",
        choices: [{ yes: "Yes" }, { no: "No" }, { dontknow: "I don't know" }],
      },
      subsidies: {
        title: "Which child care subsidies do you qualify for?",
        subtitle: "Choose all the subsidies you qualify for",
      },
      careSetting: {
        title: "What type of child care program are you interested in?",
        subtitle: "Choose all that apply",
        choices: [
          { center: "Center-based" },
          { home: "Family Home-based" },
          { school: "School-based" },
          { outdoor: "Nature-based" },
        ],
      },
      preferred_date: {
        title: "When are you hoping to start care?",
      },
      scheduleDayCount: {
        title: "How many days do you need care for?",
        subtitle: "Choose all that apply",
        choices: [
          { 5: "5 Days" },
          { 4: "4 Days" },
          { 3: "3 Days" },
          { 2: "2 Days" },
          { 1: "1 Day" },
          { none: "No preference" },
        ],
      },
      scheduleDaysOfWeek: {
        title: "What days will you need care?",
        subtitle: "",
        choices: [
          { 0: "Monday" },
          { 1: "Tuesday" },
          { 2: "Wednesday" },
          { 3: "Thursday" },
          { 4: "Friday" },
          { 5: "Saturday" },
          { 6: "Sunday" },
          { none: "No preference" },
        ],
      },
      // Note this one is a bit special case
      scheduleHours: {
        title: "What hours do you need care?",
        checkbox: "No preferred hours",
        label1: "Drop-off",
        choices1: [
          { "before.7": "Before 7am" },
          { "after.7": "After 7am" },
          { "after.12": "Afternoon" },
        ],
        label2: "Pick-up",
        choices2: [
          { "before.13": "Before 1pm" },
          { "before.17": "Before 5pm" },
          { "after.17": "After 5pm" },
        ],
      },
      educationalPhilosophy: {
        title: "What educational philosophy are you looking for?",
        subtitle: "Choose all that apply",
        choices: [
          { "Play-Based": "Play-based" },
          { Montessori: "Montessori" },
          { "Reggio Emilia": "Reggio Emilia" },
          { Waldorf: "Waldorf" },
          { Bilingual: "Bilingual" },
          { "High Scope": "High Scope" },
          { Cooperative: "Cooperative" },
          { Religious: "Religious Program" },
        ],
      },
      primaryLanguage: {
        title: "What language should the program primarily speak in?",
        subtitle: "Choose all that apply",
        choices: [
          { English: "English" },
          { French: "French" },
          { Spanish: "Spanish" },
          { Russian: "Russian" },
          { Somali: "Somali" },
          { Arabic: "Arabic" },
          { Mandarin: "Mandarin" },
          { Vietnamese: "Vietnamese" },
          { "American Sign Language": "Sign Language" },
        ],
      },
      taughtLanguages: {
        title:
          "What languages would you like your child to learn while in the program?",
        subtitle: "Choose as many as you'd like.",
        choices: [
          { English: "English" },
          { French: "French" },
          { Spanish: "Spanish" },
          { Russian: "Russian" },
          { Somali: "Somali" },
          { Arabic: "Arabic" },
          { Mandarin: "Mandarin" },
          { Vietnamese: "Vietnamese" },
          { "American Sign Language": "Sign Language" },
        ],
      },
      specialAbilities: {
        title:
          "Do you need a program that supports children with different abilities?",
        choices: [{ yes: "Yes" }, { no: "No" }],
      },
      wheelchairAccessible: {
        title: "Do you need a program that is wheelchair accessible?",
        choices: [{ yes: "Yes" }, { no: "No" }],
      },
      hearAboutUs: {
        title: "Last question - how did you hear about us?",
        upsellTitle: "Almost done! How did you hear about us?",
        choices: [
          { facebook: "Facebook" },
          { friend: "Friend" },
          { ccr: "Child Care Resource and Referral Agency" },
          { google: "Google" },
          { other: "Other" },
        ],
      },
      resultInstructions: {
        title: "Good news! We found programs for you.",
      },
      done: {
        title:
          "Based on the information you provided, we found the following programs that might meet your needs",
        subtitle:
          "Don't worry, you can always come back to recommend matches later!",
        subtitleSearching:
          "We're finding the right programs and openings that match your family's preferences.",
        titleShowMore: "Great job! We saved the programs you liked.",
        subtitleShowMore:
          "Based on your input, it looks like there are a few more programs you might be interested in. Do you want to look at those now?",
        success:
          "Thanks for taking The Concierge Survey. We're finding your matches now. Click the button below to see which programs best fit your preferences. From there you can join waitlists, schedule tours, or apply for openings.",
        fail: "Oops - there was a problem saving your survey responses. If this problem persists, please reach out to support@kinside.com",
      },
    },
  },
  conciergeComplete: {
    alertKeepTitle: "Awesome, we like that program too!",
    alertKeep:
      "We've saved this program on your \"My Programs\" page so you can review it later. Now, let's look at the rest of your matches.",
    alertPassTitle: "Sorry you didn't like that one.",
    alertPass:
      "We've removed it from your matches and won't show it again. Let's keep going and look at the rest of your matches.",
    alternateLabel: "Work",
    buttonAlert: "View Next Match",
    buttonContinue: "Show me more",
    buttonConfirmNo: "No, View next match",
    buttonSeatsYes: "Yes, show me spots",
    buttonWaitlistYes: "Yes, sign me up",
    buttonTourYes: "Yes, sign me up",
    confirmSeats:
      "It looks like {Center} has spots open right now that could meet your needs. Do you want to check them out?",
    confirmWaitlist:
      "It looks like {Center} is accepting waitlist sign ups. Do you want to go ahead and get on the list?",
    confirmTour:
      "It looks like {Center} accepts tour requests. Do you want to sign up for a tour?",
    homeLabel: "Home",
    noResultTitleWizard:
      "Looks like there's no exact matches to your current preferences. You can exit this screen and take the quiz again, or manually search all programs and openings.",
    centerTitleWizard:
      "We've found programs that match your family's preferences. If there's no availability now, you can join the waitlist or schedule a tour. Exit this screen to see additional matches or do a manual search of all programs in the area — don't worry, we'll save the matches below in your Concierge Dashboard to view later.",
    noResultTitleTab:
      "There are no exact matches for your current preferences. That's ok! Revisit the quiz to edit preferences, or click the Search Page to manually search all programs and openings.",
    centerTitleTab:
      "We've found programs that match your family's preferences. If there's no availability now, you can join the waitlist or schedule a tour.",
    doneNextSteps:
      "To see additional programs in your area, visit the Search page. You can revisit this survey at any time.",
    favoriteTip:
      "Really love this choice and want to save to your favorites? We'll let you know when this program has openings!",
    loading: "Please wait as we load your results",
    thumbsDownTip:
      "Don't like this choice? Click thumbs down to remove this program from your Concierge Results and replace it with another choice to.",
    thumbsUpTip:
      "Want to keep this program in your view? Click thumbs up to show you like it.",
    postFavoriteAlert:
      "Thanks - we'll let you know when this program has openings! In the meantime, you can schedule a tour or join the waitlist.",
  },
  conciergeResultInstructions: {
    buttonContinue: "Got it! Show my results",
    save: {
      title: "Save Ones You Love",
      subtitle:
        "Click the \"Thumbs Up\" button to save the programs you like and take a look at them again later.",
    },
    pass: {
      title: "Pass Ones You Don't",
      subtitle:
        "Click the \"Thumbs Down\" button to pass on the programs you're not into and remove them from your matches.",
    },
  },
  contract: {
    buttonImportContracts: "Import Tuition Rates",
    buttonImportPrograms: "Import Schedules",
    buttonSave: "Save changes",
    contractTip:
      "In order to see pricing options, click the arrow next to the schedule name in the list below",
    couponTitle: "Waitlist discount codes",
    feeTitle: "Waitlist fee details",
    findTitle: "Choose a provider",
    tabContract: "Tuition",
    title: "Tuition at {Name}",
    titleProvider: "Tuition and schedules for {Provider}",
    waitlistFeeSection: "Waitlist Fees and Discounts",
    warning:
      "Warning: You have schedules that don't have associated tuition rates. Please click the arrow next to the schedule name in the list below and click \"Add tuition rate\" to add rates",
    warningPublic:
      "Warning: You have schedules that don't have visible tuition options. Please make sure to check \"Visible To Families\" when you add a public-facing tuition rate",
    contractDeleteSuccess: "Tuition rate was successfully deleted.",
  },
  contractList: {
    ratesWithoutAgeGroups: "You have {Count} tuition rate(s) without an assigned age group. To fix, assign an age group by clicking on the ••• menu and selecting \"Edit rate\".",
    buttonAdd: "Add new tuition rate",
    buttonDuplicate: "Duplicate tuition",
    buttonEdit: "Edit tuition",
    buttonRemove: "Remove tuition",
    checked: "Applied",
    confirmDelete: "Are you sure you want to delete the {Name} tuition rate?",
    cost: "Cost",
    deposit: "Registration fee",
    find: "Find Price",
    legupTooltip:
      "Note that this includes a {Percent}% or ${Amount} Kinside fee",
    name: "Name",
    noContracts: "No tuition has been set up for this schedule",
    noContractFiltered: "No matching pricing options found",
    publicallyDisplayed: "Displayed to families",
    refundableDeposit: "Refundable registration fee",
    selectionRequired: "Please choose at least one pricing option",
    schedulesNeeded: "Schedules needed",
    schedulesNeededSubheader:
      "You have to add at least one schedule before you can set up tuitions.",
    addSchedules: "Add schedules",
    addTuitionLater: "I'll add tuition later",
    addSchedulesLater: "I'll add schedules later",
    introModalHeader: "Let’s add your tuition rates",
    introModalSubheader:
      "This is where you'll organize your tuition and fees so you can get paid.",
    getStarted: "Get started",
    plans: "Rates",
    haventAddedAnyPlansYet: "You haven't added any tuition rates yet.",
    noAgeGroups: "To add a tuition rate, you first need to",
    noAgeGroupsLink: "add at least one age group.",
    planName: "Name",
    schedule: "Days",
    times: "Hours",
    rate: "Rate",
    addATuitionPlan: "Add a tuition rate",
  },
  couponList: {
    buttonAdd: "Add discount",
    buttonEdit: "Edit",
    buttonRemove: "Remove",
    confirmDelete: "Are you sure you want to remove the {Code} discount code?",
    noCoupons: "No discounts have been added",
    noMaximum: "No limit",
    noSubsidies: "All families",
    waiveSubsidies: "Waive waitlist and deposit fees for families with subsidy programs",
    code: "Code",
    value: "Discount",
    maxUsage: "Max usage",
    subsidies: "Restriction",
    warningUsed:
      "This discount code has been fully used. Edit the discount to reset the max uses",
    warningExpired: "This discount code has expired",
    subheader:
      "If you'd like to give out discounts to reduce the cost of joining the waitlist, you can set those discounts here.",
  },
  dashboardParent: {
    buttonConcierge: "Get Matched to Care",
    buttonDeposit: "Complete Registration",
    buttonLegup: "Sign Up",
    buttonPaperworkRequested: "Registration Paperwork Requested",
    buttonRequestPaperwork: "Request Registration Paperwork",
    buttonShare: "Share Waitlists",
    buttonTextOptIn: "Opt-in",
    buttonWaitlists: "Add Your Other Waitlists",
    cardSubtitle: "Response Needed",
    cardTitle: "Enrollment Actions",
    centerTours: "Scheduled Program Tours",
    centerTourSubtitle: "Tour Info",
    centerTourInfo:
      "View and manage your upcoming program tours. Click Manage Tour to reschedule or cancel a tour.",
    chartMarketWaitlistSubtitle: "Enrollment Trends",
    chartMarketWaitlistTimeLabel: "Average Time On Waitlist",
    chartMarketWaitlistTimeInfo:
      "Median time that families are on a waitlist in your area before finding care. Half of all families wait more than, and half less than, this amount of time",
    chartTime: " days",
    childTitle: "Waitlist Status for {Child}",
    childTitleNoChild: "Waitlist Status",
    childSubtitle: "Waitlist Info",
    childInfo:
      "Click on an item to change your preferred date or other waitlist preferences",
    currentOpenings:
      "<b>Get matched to available spots in your area.</b> Join The Kinside Concierge.",
    directory: "Search Current Open Spots",
    enrolledSeat: "Enrolled spot for {Child}",
    inactiveChild:
      "<b>You have been marked inactive on a program waitlist!</b> Click on the grayed out waitlist entry below to resume receiving waitlist updates and spot offers.",
    message:
      "{Name} ({Email}) would like more information about joining other waitlists through Kinside!",
    missingPrograms:
      "You are on waitlists without preferred enrollment schedules.\n\nIn order to help us find the right care for your needs, please head to the Dashboard and choose your preferred schedules and days.",
    noDepositForms:
      "You do not have spot offers requiring your attention. Use the Concierge or Search page to find new programs and schedule tours.",
    noSeatOffers:
      "You currently have no spot offers. Use the Concierge or Search page to find new programs and schedule tours.",
    noTours:
      "You have no upcoming tours. Use the Concierge or Search page to find new programs and schedule tours.",
    noWaitlist:
      "You are not on any waitlists. Use the Concierge or Search page to find new programs and schedule tours.",
    paperworkAfterTour:
      "This program doesn't allow you to request registration until you've completed a tour.",
    pendingNone: "You don't have any pending items yet",
    pendingTours: "You toured with {Center}. Click here to join the waitlist.",
    pendingSeats:
      "You have a spot offer from {Center}. Click here to request registration paperwork.",
    pendingEnrollments:
      "You've been approved for enrollment at {Center}. Click here to complete registration.",
    reportMatchedTitle: "Program Matches",
    reportMatchedClose: "{Count} Within 3 miles",
    reportMatchedCheap: "{Count} under {Amount} per month",
    reportMatchedDetail: "Programs near you",
    reportWaitlistsTitle: "Waitlists Joined",
    reportWaitlistsDetail: "Unique Waitlists",
    reportWaitlistsSignups: "{Count} Sign-Ups for {Child}",
    reportToursTitle: "Tours Scheduled",
    reportToursDetail: "Tours",
    reportToursThisWeek: "{Count} This Week",
    reportToursThisMonth: "{Count} in the next 30 Days",
    reportSeatsTitle: "Spot Matches",
    reportSeatsDetail: "Spots Near You",
    reportSeatsAvailable: "{Count} Available within 30 Days",
    reportSeatsLiked: "{Count} Liked Programs",
    requestMessage:
      "{Name} ({Email}) would like information about an open spot in the {Age} age group at program ID {Center}",
    requestSeat:
      "Would you like to inquire with Kinside about this available spot in {Location}?",
    requestSubject: "Parent / guardian request on open spot",
    runMatchedCare:
      "To find and save programs that might fit your child care needs, click the \"Get Matched to Care\" button",
    shareMessage:
      "{Name} ({Email}) is letting you know other waitlists they are on: {Text}",
    shareSubject: "Parent / guardian is sharing other waitlists they are on",
    shareWaitlists:
      "Are you on other child care waitlists? Share which ones and we'll contact them to get their waitlist information in Kinside as well -- it's free for them!",
    shareWaitlistsNavigator:
      "Are any of your families on other child care waitlists? Share which ones and we'll contact them to get their waitlist information in Kinside as well -- it's free for them!",
    shareWaitlistsTitle: "Add Waitlist",
    signupForLegup:
      "Join The Concierge to get matched to programs that meet your family's need.",
    subject: "Parent / guardian request for more waitlists",
    seatStatus: "Open spots for {Child}",
    seatStatusNone: "Open spots",
    subtitle: "Getting into child care is about to become a lot easier.",
    textOptIn: "Never miss a spot offer! Opt in to text messages.",
    title: "Hello, {Name}!",
  },
  listing: {
    about: "{Center} is a licensed child care provider in {City}. ",
    aboutContentTitle: "About the program",

    action: {
      application:
        "Submit an application if you don't see a current opening that meets your needs",
      claim: "Is this your program?",
      contact: "Want to contact us about this program?",
      tour: "Waitlist signups are allowed after a tour has been completed.",
      waitlist: "Sign up now to claim your place on the waitlist",
    },
    actionButton: {
      application: "Apply Now",
      claim: "Claim Listing",
      contact: "Contact Us",
      tour: "Schedule a tour",
      waitlist: "Join Waitlist",
    },
    actionHeader: {
      application: "Application",
      claim: "Claim Page",
      contact: "Contact",
      tour: "Waitlist",
      waitlist: "Waitlist",
    },
    aboutUsHeader: "Our Staff",
    aboutTitle: "The Basics",
    basics: {
      titleProgramType: "Program Type",
      titleLicense: "License Number",
      titleBackground: "Background Check",
      titleAges: "Ages",
      titleHours: "Hours",
      titleDays: "Days",
      titlePaySchedule: "Pay Schedule",
      titleSubsidies: "Subsidized Care",
      programTypeCenter: "Center",
      programTypeFCC: "Family Child Care",
      subsidizedYes: "Accepted",
      subsidizedNo: "Not Accepted",
      no: "No",
      yes: "Yes",
    },
    buttonTour: "Schedule Tour",
    carouselHeader: "Program Photos",
    contact: {
      email: "Email:",
      phone: "Phone Number:",
      website: "Website:",
    },
    contactInfo: "Need to get in touch with {Center}?",
    contactLabel: "Contact",
    educationTitle: "Our Educational Philosophy",
    formBoth:
      "You can review their <a href={HandbookURL} target='_blank'>program handbook</a> for more information about policies at this program. View the <a href={SafetyURL} target='_blank'>health and safety policy</a> to see the latest guidelines in effect at this program.",
    formHandbook:
      "You can review their <a href={HandbookURL} target='_blank'>program handbook</a> for more information about policies at this program.",
    formSafety:
      "You can review their <a href={SafetyURL} target='_blank'>health and safety policy</a> to see the latest guidelines in effect at this program.",
    locationHeader: "Location",
    operationHours: "Operating Hours",
    programs: "Schedules",
    programsFullTime: "{Days} Day Full Time",
    programsPartTime: "{Days} Day Part Time",
    programsBoth: "{Days} Day Full Time and Part Time",
    requestTour: "Schedule a Tour",
    safetyTitle: "Health and Safety",
    seatNotFound: "Sorry, that spot is no longer available",
    sendSuccess: "Thanks. We will be in touch with you shortly",
    sendFail:
      "There was a problem submitting your request. Please try again later",
    specialTitle: "About Our Program",
    stickyOpen: "Available spots",
    stickyTour: "Schedule Tour",
    stickyWaitlist: "Join Waitlist",
    stickyWaitlistApplication: "Apply Now",
    subsidies: {
      title: "Subsidies",
      sub: "{Center} accepts the following subsidies",
    },
    tabSeats: "Availability",
    tabDetails: "General Info",
    tabAbout: "Team",
    tabImages: "Image Gallery",
    tabPhilosophy: "About Us",
    tourLabel: "Tour",
    tourSignUp: "Click below to sign up for a tour.",
    tourVideoLabel: "Program Videos",
    virtualTour: "Virtual Tour",
  },
  dataFilesTool: {
    articleTitle: "Update Dashboard FAQ Content",
    buttonSave: "Save changes",
    labelTitle: "Help Topic Text",
    labelURL: "URL",
    preview: "Preview of provider dashboard card",
    saveArticlesSuccess: "Successfully saved help articles",
    saveArticlesFailure: "There was a problem saving help articles",
    title: "Dashboard Help Articles",
  },
  demographics: {
    allMarkets: "All Markets",
    colAge: "Age range (months)",
    colCapacityCount: "Count (licensed capacity)",
    colBudgetedCapacityCount: "Count (budgeted capacity)",
    colCount: "Count",
    colEnrollCount: "Enrolled",
    colWaitlistCount: "On Waitlist",
    colWaitlistSubsidyCount: "Subsidy Eligible on Waitlist",
    colPercentage: "Percentage Range",
    colStat: "Statistic",
    colAverageTime: "Average Time (days)",
    colMedianTime: "Median Time (days)",
    subtitleChildren:
      "The ages of children who have enrolled through Kinside or are active on a program waitlist. The age is their age as of today, not when they signed up or received an offer. This is a count of children, not waitlist spots (e.g. it is de-duped for children on multiple waitlists).",
    subtitleCapacity:
      "The number of centers that are operating in the given percentage occupancy range as of today. This is calculated by taking the total classroom capacity (both licensed and budgeted), subtracting the number of open spots (including those currently on offer to a family), and dividing by the capacity.",
    subtitleOpenSeats:
      "The number of open spots by age. This does not include spots that are currently being offered to families. Time open is measured from the point that the spot was first added",
    subtitleTimeOpen:
      "The average time a spot has been open in days. This does not include spots that are currently being offered to families and is based on the date at which the spot was first opened",
    subtitleWaitlistOffer:
      "The average / median time between when a family was added to the waitlist until they received a spot offer (or today if they haven't received a spot offer). We <b>exclude</b> families who immediately received an offer, e.g. those who claim a spot from an enrollment page or spots directly offered by providers. This data is per waitlist spot, e.g. a child on multiple waitlists will be counted multiple times",
    subtitleWaitlistEnrollment:
      "The average / median time between when a family was added to the waitlist until they enrolled in a spot. We <b>include</b> families who claim spots from enrollment pages or are directly offered a spot by providers. This data is per waitlist spot, e.g. a family on multiple waitlists will be counted multiple times.",
    subtitleWaitlistInactive:
      "The average / median time between when an inactive family was added to the waitlist until they were removed from a waitlist. Note that because we often import old waitlists, this data tends to be skewed by stale families that have been on a waitlist for years. This data is per waitlist spot, e.g. a family on multiple waitlists will be counted multiple times.",
    titleOverview: "Overview",
    titleChildren: "Child Age Breakdown",
    titleCapacity: "Summary of center occupancy",
    titleOpenSeats: "Open spots by age",
    titleTimeOpen: "Time spot has been open by age",
    titleWaitlistOffer: "Time to Offer",
    titleWaitlistEnrollment: "Time To Enrollment",
    titleWaitlistInactive: "Time to Inactive",
    rowCenterCount: "Onboarded Center Count",
    rowCapacity: "Total Licensed Capacity",
    rowBudgetedCapacity: "Total Budgeted Capacity",
    rowEnrollments: "Overall enrollments month to date",
    header: "Demographic information for {Market}",
    headerNone: "We were unable to find demographic information for {Market}",
  },
  depositReceipt: {
    buttonPrint: "Print",
    labelName: "<b>Name:</b> ",
    labelEmail: "<b>Email:</b> ",
    labelAddress: "<b>Address:</b> ",
    labelCompany: "<b>Company:</b> ",
    labelPhoneNumber: "<b>Phone Number: </b>",
    labelBirthdate: "<b>Birthdate: </b>",
    noPayment: "Registration fee was discounted: ({Reason})",
    title: "Enrollment Confirmation",
  },
  depositWizard: {
    buttonBack: "Back",
    buttonNextAchVerify: "Get Bank Account Details",
    buttonNextFamily: "Next - Family details",
    buttonNextPayment: "Next - Payment Information",
    buttonNextComplete: "Complete Enrollment",
    buttonNextSubmit: "Submit Payment",
    buttonNextDone: "Done",
    doneTitle: "Payment Received",
    doneText:
      "{Child} now has a confirmed spot at {Center}. You will receive your registration fee receipt in your email. {Center} will be in touch to send and collect the rest of your Enrollment Packet shortly.",
    familyDetailsTitle: "Family details",
    noChildName: "Your child",
    ongoingTuition:
      "Click here if you are interested in online tuition payments to this provider",
    parentAckWarning:
      "You must check the checkbox acknowledging that you are the parent or guardian of this child to proceed",
    parentCompleteLabel:
      "Please check here to acknowledge that the person filling out this form is the legal parent or guardian of the enrolling child. Note that this form <b>must be completed by the parent or guardian.</b> ",
    paymentTitle: "Submit payment details",
    seatDetails: "Spot details",
    seatTitle: "Complete Registration to Enroll",
    title: "You've got a spot!",
  },
  developmentStage: {
    SOLIDFOOD: "Eating Solids",
    WALKING: "Walking",
    POTTYTRAINED: "Out of Diapers",
  },
  directory: {
    ages: [
      { age: 2, value: "2 weeks" },
      { age: 4, value: "1 month" },
      { age: 6, value: "6 weeks" },
      { age: 8, value: "2 months" },
      { age: 12, value: "3 months" },
      { age: 16, value: "4 months" },
      { age: 24, value: "6 months" },
      { age: 36, value: "9 months" },
      { age: 48, value: "1 year" },
      { age: 72, value: "18 months" },
      { age: 96, value: "2 years" },
      { age: 120, value: "30 months" },
      { age: 144, value: "3 years" },
      { age: 208, value: "4 years" },
      { age: 240, value: "5 years" },
    ],
    availableNow: "Now",
    colAvailable: "Date Available",
    colCenter: "Program",
    colCost: "Cost",
    colLocation: "Location",
    colTemporary: "Temporary Care",
    colAgeRange: "Age range",
    cost: "From {Cost}",
    displayAge: "{min} - {max} months",
    displayAgeCompact: "{min}-{max} mos",
    displayAgeWeeks: "{min} weeks - {max} months",
    displayAgeWeeksCompact: "{min} wks-{max} mos",
    findCenter: "Find Program",
    findLocation: "Enter Location",
    noAddress: "No address on file",
    noMatch: "No matching openings were found",
    noPhone: "No phone number on file",
    phone: "Phone: {Phone}",
    selectAge: "Select Age",
    selectChild: "Select Child",
    tempYes: "Yes",
    tempNo: "No",
    titleOpenSeats: "Open spots",
  },
  displayOptions: {
    buttonSave: "Save changes",
    deleteFamilies:
      "Remove non-responsive families from the waitlist after 30 days (and successive warnings)",
    noDeleteFamilies: "Families are not auto-deleted from the waitlist",
    hideOtherGender: "\"Other\" is not a choice for gender on waitlist form",
    showOtherGender: "\"Other\" is a choice for gender on waitlist form",
    labelWaitlist:
      "Indicate how families should see their position on this program's waitlist",
    title: "Profile settings",
    titleChange: "Change Enrollment Options",
    showGender: "Gender is shown as an allowable spot preference",
    noGender: "Gender is not shown as an allowable spot preference",
    subsidyRestrictions:
      "Spots can be marked as only available for subsidy or private pay families",
    noSubsidyRestrictions:
      "Spots can be marked as preferring, but not requiring, enrollment by subsidy or private pay families",
    messageApplication: "Waitlist signups are called \"Applications\"",
    waitlist: {
      normal: "Families see their waitlist position number",
      pool: "Families do not see their waitlist position (the program uses a waitlist pool)",
      transparent:
        "Families see a detailed breakdown of the meaning behind their waitlist position",
    },
  },
  downloadReceipts: {
    buttonDownload: "Download CSV file",
    dateFrom: "From",
    dateTo: "To",
    instructions:
      "Select a time frame and click \"Download CSV File\" to download your Kinside receipts",
    title: "Download Receipts",
    headers: {
      centers: "Program",
      date: "Payment date",
      type: "Payment type",
      receipt: "Receipt",
      total: "Total",
      amount: "Your payment",
      transferDate: "Date transfered",
      coupon: "Discount code",
      child: "Child",
      parent: "Parent / Guardian",
      email: "Parent / Guardian Email",
      parent2: "Parent / Guardian #2",
      email2: "Parent / Guardian #2 Email",
    },
    state: {
      lastweek: "The last 7 days",
      lastmonth: "The last 30 days",
      thismonth: "Month to Date",
      thisyear: "Year to Date",
      custom: "Custom Range",
    },
    pendingDate: "Not yet cleared",
    types: {
      deposit: "Registration Fee",
      tuition: "Tuition Payment",
      waitlist: "Waitlist Signup Fee",
    },
  },
  duplicates: {
    buttonDelete: "Fully Delete the family",
    buttonCheck: "Kick off duplicate check",
    buttonMerge: "Delete the parent and merge the child",
    buttonKeep: "Delete the parent and keep all children",
    colParent: "Parent",
    colEmail: "Email",
    colDuplicate: "Duplicate?",
    colDupeParent: "Duplicate Parent",
    colDupeEmail: "Duplicate Email",
    confirmCheck:
      "Note that it can take up to 15 minutes to complete a duplicate family check. If you have recently started a duplicate check, please give it time to complete before kicking off a new one. Are you sure you want to proceed?",
    confirmDelete:
      "This will irreversibly remove the selected parent and their children from Kinside's records. Are you sure you want to continue?",
    confirmMerge:
      "This will irreversibly remove the selected parent from Kinside's records. The child from the deleted family will be merged into the child of the family being kept, leaving you with a family with one child. Are you sure you want to continue?",
    confirmKeep:
      "This will irreversibly remove the selected parent from Kinside's records. The children associated with this parent will be added to the family being kept. If you want to merge some of these children later, you can do so via the Families tab",
    dialogTitle: "Select the Family to Delete",
    familyEmail: "Email: {Email}",
    familySpouse: "Spouse: {Spouse}",
    familyChild: "{Child} born {Date} ({Spots})",
    familyNoChildren: "No children",
    findFamily: "Find Family",
    instructions:
      "Select the family that you want to delete. After that, you'll need to decide what you want to do with the children:<ul><li>Full Delete: The selected parent and children will be deleted. Their waitlist spot and history will be removed</li><li>Keep All: This will take the children from the family to be deleted and add them into the family being kept. You can then combine children individually if necessary using the Merge Children feature on the Families tab</li><li>Merge: If both families have one child, merging will combine their histories and waitlist signups into a single record</li></ul>",
    noDuplicate: "No duplicate matches were found",
    checkingSuccess:
      "We have kicked off a new duplicate check in the background. This can take several minutes to complete. Please come back to this screen later to look for duplicates",
    checkingFailure: "There was a problem kicking off the duplicate check job",
    removeSuccess: "The duplicate family has been removed",
    removeFailure: "There was a problem removing the duplicate family",
    tooltipSeat:
      "This child has a spot. Deleting this copy could have unintended consequences",
    title: "Potentially Duplicated Families",
  },
  editClassroom: {
    buttonSave: "Save changes",
    capacity: "Budgeted Capacity",
    maxCapacity: "Licensed Capacity",
    name: "Classroom Name",
    titleAdd: "Add classroom",
    titleEdit: "Edit Classroom",
  },
  editContract: {
    annually: "Annually",
    buttonAdd: "Add tuition rate",
    buttonSave: "Save tuition rate",
    buttonPublicYes: "Yes, Save Pricing",
    buttonPublicNo: "No, Edit Pricing",
    confirmPublic:
      "It looks like you've chosen to hide this pricing from families. If you continue, any spots posted with this option will not be displayed on your Enrollment Page. Do you want to continue?",
    costAmount: "Tuition amount",
    tuitionRate: "Tuition rate",
    daily: "Daily",
    depositAmount:
      "Total registration fee: {Deposit} ({Total} including Kinside's {Percent}% or ${Amount} fee. Kinside's fees are non-refundable).",
    depositNotes: "Registration notes to family",
    depositNotesSubheader:
      "These will be visible on the profile page and in the offer email.",
    depositSample:
      "Message to family: Registration is {Deposit} ({Refundable} is refundable){Notes}",
    hourly: "Hourly",
    monthly: "Monthly",
    name: "Rate name",
    nonRefundableDepositAmount: "Non-refundable registration fee",
    notes: "Tuition notes to family",
    refundableDepositAmount: "Refundable registration fee",
    sample: "Message to family: Tuition rate is {Cost} {Notes}",
    titleAdd: "Add tuition rate",
    titleEdit: "Edit tuition rate",
    weekly: "Weekly",
    isPublic: "Visible to families",
    selectSchedule: "Select schedule associated with this tuition rate",
  },
  editCoupon: {
    buttonAdd: "Add discount",
    buttonSave: "Save discount",
    consumed: "This discount code has been used {Count} times so far.",
    labelName: "Discount code",
    labelNameHelper:
      "This is the actual code and should be one word like FAMILY or WINTER",
    labelAmount: "Offer a dollar amount discount ($)",
    labelDiscountAmount: "Discount amount",
    labelAutoApply: "Automatically apply this discount to eligible families.",
    labelPercent: "Offer a percentage discount (%)",
    labelReactivate: "Reactivate this discount code",
    labelReset: "Reset the usage of this discount code to 0",
    labelMaxUsage: "Set a limit on usage",
    maxUsage: "Maximum number of uses",
    questionNo: "No. It's available for all families.",
    questionYes: "Yes. For the following question:",
    sectionDescription: "Discount details",
    sectionUsage: "Usage",
    sectionRestriction: "Restrictions",
    sectionQuestions: "Custom questions",
    titleQuestion:
      "Is this coupon only available to those who answer your custom waitlist form questions with a specific answer?",
    titleAdd: "Add discount code",
    titleEdit: "Edit discount code",
    unlimitedUsage: "Unlimited usage",
  },
  editLead: {
    buttonAdd: "Add Lead",
    buttonSave: "Save Lead",
    enterFamilyNotes: "",
    enterInternalNotes: "Internal Notes",
    sectionFamily: "Family details",
    sectionNotes: "Notes",
    labelBirthdate: "Birthdate",
    labelParent: "Parent / Guardian",
    titleAdd: "Add Lead",
    titleEdit: "Edit Lead",
    familyNotesTitle: "Notes from the family",
    internalNotesTitle: "Your notes",
  },
  editProgram: {
    agegroup: "Schedules for {AgeGroup}",
    buttonAdd: "Add schedule",
    buttonSave: "Save schedule",
    errorAvailable: "Please select the appropriate number of days",
    labelDays: "Days per week",
    labelEnd: "End time",
    labelName: "Schedule name",
    labelSelectDays: "Days available",
    labelStart: "Start time",
    selectContracts:
      "Select pricing options associated with this schedule. To add new pricing options, return to the list and click the \"Price List\" tab",
    titleAdd: "Add schedule",
    titleEdit: "Edit schedule",
    warnMissingAgeGroup:
      "You have one or more age groups with no associated schedules",
    warnProgramWithoutAgeGroup:
      "You have one or more schedules that are not assigned to an age group",
    warnProgramDays:
      "You have one or more schedules that haven't set the correct number of days per week based on the number of days the schedule is available",
    warnProgramName: "You have one or more schedules with no name",
  },
  editQuestion: {
    buttonAdd: "Add Question",
    buttonAddChoice: "Add Choice",
    buttonSave: "Save Question",
    colChoice: "Choice",
    colPriority: "Priority Answer",
    defaultChoice: "Choice #{Number}",
    formDetails:
      "Select whether this question should appear on your waitlist form, registration form, or both - and whether an answer is required on those forms",
    formWaitlist: "Question should appear on waitlist form",
    formDeposit: "Question should appear on registration form",
    labelHint: "Help text",
    labelInternal:
      "This question is for internal use and should not be shown to families",
    labelPriority: "An answer to this question gives waitlist priority",
    labelProvider: "This question should appear on all programs' forms",
    labelQuestion: "Question",
    labelRelativePriority: "Enter the relative priority of this answer",
    labelRelativePriorityCaption:
      "Select the priority this question has relative to other questions on your form. For example, if people answering \"Yes\" to this question should have the highest waitlist priority, select \"1\". If second-highest priority select \"2\", etc.",
    labelRequired: "An answer is required",
    labelType: "Type of Question",
    labelUrl: "URL",
    priorityDefault: "Default",
    providerQuestion:
      "Select whether this question should apply to all of your programs. If unchecked, this question will be specific to this program",
    title: "Edit Question",
    titleAdd: "Add new Question",
    tipReferral:
      "The family will be asked a yes/no question. If they answer yes, we will ask them to name the person or organization that referred them",
    types: {
      freeform: "Text Answer",
      html: "HTML Statement",
      yesno: "Yes/No",
      multiple: "Multiple Choice",
      date: "Date",
      referral: "Referral",
    },
  },
  editSeat: {
    ageRange: "Required age range",
    ageRequired: "Please fill in a minimum and maximum age for this spot",
    available: "Spot available",
    availableDate: "Date spot is available",
    buttonAdd: "Add spot",
    buttonOfferFamily: "Manually offer spot to family",
    buttonSave: "Save changes",
    cannotRemove:
      "You cannot remove a spot that has been offered to a family. Please remove the family first.",
    center: "Program",
    centerRequired: "Please select a program",
    childNotesTitle: "Notes for this child",
    childStartingOn: "Child starting on",
    chooseCenter: "Choose a program first",
    chooseClassroom: "Choose a classroom to see schedule options",
    chooseContract: "Choose the prices and schedules available for this spot",
    chooseContractEnrolled:
      "Choose the pricing option that the enrolled family selected",
    chooseProgram: "Choose a schedule to see pricing details",
    classroom: "Classroom",
    classroomReadOnly: "Classroom: {Classroom} - {Center}",
    classroomRequired: "Please select a classroom",
    costTitle: "Tuition rate",
    criteriaSubtitle:
      "If you have any special considerations for Kinside as we match families for this spot, please enter them here.",
    criteriaTitle: "Notes for Kinside",
    edit: "Spot details",
    enterChildNotes: "Enter child notes",
    enterCriteria: "Enter notes",
    genderInstructions: "This spot has a gender requirement",
    offerSubtitle:
      "If you have any notes to share with the parent / guardian, please enter them here. <B>Families may see these notes on your profile page and in offer emails.</B>",
    offerTitle: "Notes for family",
    providerSubtitle: "You can enter your internal notes for this spot here.",
    providerTitle: "Your notes",
    providerSubtitleLegup: "These are internal notes entered by the provider",
    providerTitleLegup: "Provider notes",
    instructions: "Are any development requirements for this spot?",
    min_age: "Minimum age of Child in Months",
    min_age_weeks: "Minimum age of Child in Weeks",
    minAge: "The minimum age must be greater than 0",
    max_age: "Maximum age of Child in Months",
    maxAge: "The minimum age must be less than the maximum age",
    name: "Seat Name",
    noClass: "Please select a classroom for this spot",
    noContractSelected:
      "You must associate at least one pricing option with this spot",
    noContracts:
      "To add an open spot, you first need to update your tuition and schedule details.",
    noContractsLink: "Click here to set up the tuition for this age group.",
    noOtherStage:
      "Custom development skills are not supported. Please let us know if you have another development skill you require.",
    noOtherStageProvider:
      "Please reach out to your Enrollment Support Team if you have another development skill you require for a family to fill this spot.",
    noPublicContracts:
      "It looks like you've chosen a pricing option that's not currently visible to families. To post this spot, you'll need to use a visible pricing option. Would you like to make {Name} visible to families?",
    offerFamily:
      "If you have a family that you'd like to offer this spot to, please click the \"Manually Offer Spot to Family\" button.",
    priceChange: "Prices changed? Edit here",
    reason: "Removal reason",
    sectionSchedule: "Classroom and schedule",
    sectionRequirements: "Spot requirements",
    sectionPreferences: "Spot preferences",
    sectionNotes: "Spot notes",
    stageOther: "Other",
    subsidyInstructions: "Specify family subsidy requirements for this spot",
    title: "Adding an open spot for {Provider}",
    titleAgeGroup: "Edit {AgeGroup} details",
    titleNoName: "Add open spot",
    addTuitionAndSchedules: "Add tuition and schedules",
    temporaryCareLabel: "Spot is only available for temporary care",
    makeOffersLabel: "Enable automated offers on this spot. (We'll offer this spot to relevant families on your waitlist and ours.)",
    confirmPauseStatusDialogHeader: "Almost done!",
    confirmPauseStatusDialogSubheader: "Ready to fill the spot?",
    confirmPauseStatusDialogPauseSpot:
      "Add spot as paused (not visible on your profile)",
    confirmPauseStatusDialogPostNow: "Add spot (make visible on your profile)",
  },
  editSeatPreferences: {
    agePrefs: "Preferred age range",
    error: "Your preferences are broader than your requirements",
    genderInstructions: "Specify a preference for child gender",
    male: "Boys",
    female: "Girls",
    unknown: "Not specified",
    noSubsidy: "Private pay (no subsidy)",
    subsidyInstructions: "Specify a preference for subsidy families",
    labelPreference:
      "Check this box to choose flexible age ranges for this spot and specify subsidy preferences. We will first attempt to fill your spot using these preferences before looking at other children on your waitlist",
  },
  editSubsidy: {
    availability: "Where is this subsidy available?",
    buttonAdd: "Add subsidy",
    buttonSave: "Save changes",
    buttonAddCity: "Add city",
    buttonAddCounty: "Add county",
    code: "Short code (must be unique)",
    labelCity: "City",
    labelCounty: "County",
    labelMarket: "(To change the metro area, contact Engineering)",
    name: "Display name",
    restriction: {
      all: "To all programs nationwide",
      market:
        "Within a metro-area market (To set a subsidy for a metro area, contact Engineering)",
      city: "For a specific list of cities",
      county: "In a specific set of counties",
      state: "Within a state",
    },
    titleAdd: "Add subsidy",
    titleEdit: "Edit subsidy",
    url: "URL",
  },
  editTour: {
    buttonRemove: "Cancel tour",
    buttonSave: "Save changes",
    completedInstructions:
      "Indicate if the family didn't show up for the original tour time. We will keep the tour details in the family's records.",
    noShowWarning:
      "Note that this tour date is in the future, yet you are marking the family as a no show",
    dateLabel: "Tour date",
    familyNotes: "Notes from the family",
    familyNotesProvider: "Notes (shared with the provider)",
    internalNotes:
      "Internal notes about this tour (not shared with the family)",
    length: "Tour length in minutes",
    notes: {
      legup: {
        providerSubtitle: "These are internal notes entered by the provider",
        providerTitle: "Internal notes",
        familySubtitle:
          "These are notes the provider wants to share with the parent / guardian. <B>These notes will be visible to families as written.</B>",
        familyTitle: "To family",
        fromFamilySubtitle:
          "These are notes the family entered about this tour",
        fromFamilyTitle: "From family",
        noNotesFamily: "",
        noNotesFromFamily: "No notes from family",
      },
      provider: {
        providerSubtitle:
          "You can enter your internal notes for this spot here.",
        providerTitle: "Your internal notes",
        familySubtitle:
          "If you have any notes to share with the parent / guardian, please enter them here. <B>These notes will be visible to families as written.</B>",
        familyTitle: "To Family",
        fromFamilySubtitle:
          "These are notes the family entered about this tour",
        fromFamilyTitle: "From family",
        noNotesFamily: "",
        noNotesFromFamily: "No notes from family",
      },
      parent: {
        providerSubtitle: "",
        providerTitle: "Provider notes",
        familySubtitle: "",
        familyTitle: "From director",
        fromFamilySubtitle:
          "If you have notes about this tour for the provider, you can enter them here",
        fromFamilyTitle: "To director",
        noNotesFamily: "The director has not left any notes",
        noNotesFromFamily: "",
      },
    },
    removeInstructions:
      "If the family has canceled the tour, you can remove the tour by clicking \"Cancel Tour.\" If they missed the tour and you'd like them to reschedule, please check above to indicate they didn't show up and press \"Save changes\"",
    requestReschedule: "Send a mail asking the parent / guardian to reschedule",
    sectionDates: "Tour date and Time",
    sectionFamily: "Family details",
    sectionNotes: "Notes",
    sectionUpdate: "Update family",
    sectionVirtual: "Virtual tour instructions",
    selectInstructions:
      "Select a family from your waitlist or enter a new family's details below",
    sendMailAdd: "Send tour details to family",
    sendMailUpdate: "Email tour updates to family",
    setNoShow: "The family didn't show up for this tour",
    setVirtual: "This is a virtual tour",
    virtual: "Video conference instructions",
    virtualInstructions:
      "Please enter the video conferencing details for your virtual tour below.",
    title: "Schedule tour",
    useSchedule: "Use tour schedule",
    startTimeAndDuration: "Start time and duration",
    minutes: "minutes",
    selectedDateHasNoAvailabilities:
      "The selected date has no openings. Please choose another date or uncheck \"Use Tour Schedule\"",
  },
  editTourSchedule: {
    buttonAdd: "Add time slot",
    buttonDelete: "Delete this schedule",
    buttonSave: "Save changes",
    confirmDelete:
      "Do you want to remove this custom tour slot from your schedule? Note that this will not cancel any tours that have already been scheduled during this time period",
    headers: [" ", "Age group", "Start", " ", "End", "Virtual"],
    dialogTitle: "Tour availability",
    dialogExactDateTitle: "Schedule open house",
    instructions: "Choose which day and times you're available for tours.",
    labelAgeGroup: "Age group",
    labelExactDate: "Tour date",
    labelDuration: "How long will your tours be (in minutes)?",
    labelCapacity: "How many families can join each tour?",
    labelExactDateDuration: "How long will the tour be (in minutes)?",
    labelExactDateCapacity: "How many families can join this tour?",
    labelVirtualInstructions:
      "If you have the same video conferencing details for all of your tours, enter them here and they will added to your notes when scheduling a tour. If you use different video links per tour, please leave this section empty.",
    timeSlot: "Tour schedule {Slot}",
    virtual: "Virtual tour",
    virtualInstructions: "Virtual tour instructions",
  },
  enrollments: {
    noFamiliesEnrolled: "There are no families enrolled yet.",
    addFamilyFail: "There was a problem adding this family",
    addFamilyInstructions:
      "Enter the details of a family that is already enrolled at your center",
    addFamilySuccess: "This family was added",
    addFamilyTitle: "Add family",
    buttonGenerateLink: "Generate survey link",
    buttonExportEnrolled: "Export enrollments",
    buttonAddFamily: "Add family",
    buttonSendTuition: "Send tuition",
    colTuition: "Tuition",
    selectAgeGroup: "Age group",
    survey:
      "Would you like to generate a survey link this program can send to existing families? This will allow them to seed the family reviews on their enrollment page. This link will be valid for 7 days. During that time frame, anyone can take this survey - we cannot validate the recipient's identity.",
    surveyLink: "Families can use this link to leave a review: <b>{Link}</b>",
    title: "Enrollments for {Name}",
    titleFindCenter: "Select a Program",
    buttonSendEmails: "Send emails",
    selectAProgramFromDropdown:
      "Select a program from the dropdown menu above in order to send review requests to the families enrolled at that center.",
    emailsSent: "Emails sent!",
    youHaveAlreadySentReviewRequests:
      "You have already sent review requests to all families at this center.",
    reviewsWillBeAnonymized:
      "Reviews will be anonymized and displayed on your public profile.",
    clickButtonToSendEmails:
      "When you click the button below we will send emails to the families that have not yet received a review link inviting them to review this center.",
    haveNotReceivedLinks:
      "This center has <strong>{numSurveysRemaining} families</strong> that have not yet received their unique review link.",
    requestReviewsTitle: "Request reviews from enrolled families",
  },
  errorCodes: {
    APIERROR: "Sorry, we encountered an error. Please try again",
    APIINVALIDAUTH: "Your session has ended. Please login again",
    AUTHEXPIRED: "Your session has ended. Please login again",
    CANTCHANGECOST:
      "You can't change tuition rate after a schedule and tuition option has been created. Please create a new one and remove the existing one.",
    INVALIDPARAMETER: "There was an invalid parameter",
    INVALIDPERMISSION: "You don't have permission to perform this operation",
    INVALIDADDRESS: "The address provided is invalid",
    INVALIDTIMES:
      "The start time cannot be after the end time, and the start date must be in the past for a completed tour",
    INVALIDUSER:
      "This user is not registered with Kinside. Please contact Kinside Support at support@kinside.com for assistance",
    MISSINGCONTRACT:
      "This spot does not have a valid pricing option. Please contact the provider or Kinside support at support@kinside.com for assistance",
    CONFLICTINGTIMES: "There is already a tour at this time",
    INVALIDEMAIL: "The email address is invalid",
    INTERNALERROR: "There was an internal error",
    NOADMINUNIT: "This feature does not support admin units",
    NODATA: "No data was provided",
    NOSTRIPE: "We are unable to collect payment information for this provider",
    DUPLICATEENTRY: "There is already an entry for this user",
    LEGUPUSERCREATIONFAILED: "User creation failed",
    COGNITOUSERCREATIONFAILED: "User creation failed",
    USERNAMEEXISTS: "The user name already exists",
    MAXSEATREQUESTS:
      "You have already held three spots, and cannot hold any additional ones at this time",
    UNSAFEDELETE:
      "Sorry, you can only delete this item after first creating it. If you need assistance, contact your Enrollment Support Team",
  },
  errorPage: {
    text: "Uh oh! There was a problem completing your request. Please try again or or contact <a href=\"mailto:support@kinside.com\">support@kinside.com</a> to let them know you experienced an error.",
    textErrorCode:
        "Uh oh! There was a problem completing your request. Please try again or contact <a href=\"mailto:support@kinside.com\">support@kinside.com</a> and reference error code ${ErrorCode}.",
    textServer:
        "Uh oh! There was a problem completing your request. Double check the link or contact <a href=\"mailto:support@kinside.com\">support@kinside.com</a> to let them know you experienced an internal error.",
  },
  exportWaitlist: {
    colDateAdd: "Date Added",
    colDatePreferred: "Preferred Date",
    colSpotState: "Waitlist Spot State",
    colComments: "Comments",
    colSchedules: "Schedules",
    colParentFirstName: "Parent First Name",
    colParentLastName: "Parent Last Name",
    colParentEmail: "Parent Email",
    colParentPhone: "Parent Home Phone",
    colParentWorkPhone: "Parent Work Phone",
    colParentCellPhone: "Parent Cell Phone",
    colCanText: "Parent Can Text",
    colParentPrimaryPhone: "Primary Phone",
    colParentAddress1: "Parent Street Address",
    colParentAddress2: "Parent Street Address 2",
    colParentCity: "Parent City",
    colParentState: "Parent State",
    colParentPostalCode: "Parent Postal Code",
    colParentCompany: "Parent Company",
    colParentSubsidies: "Parent Subsidies",
    colParent2FirstName: "Parent2 First Name",
    colParent2LastName: "Parent2 Last Name",
    colParent2Email: "Parent2 Email",
    colParent2Phone: "Parent2 Home Phone",
    colParent2WorkPhone: "Parent2 Work Phone",
    colParent2CellPhone: "Parent2 Cell Phone",
    colCanText2: "Parent2 Can Text",
    colParent2PrimaryPhone: "Parent2 Primary Phone",
    colParent2Address1: "Parent2 Street Address",
    colParent2Address2: "Parent2 Street Address 2",
    colParent2City: "Parent2 City",
    colParent2State: "Parent2 State",
    colParent2PostalCode: "Parent2 Postal Code",
    colParent2Company: "Parent2 Company",
    colParent2Subsidies: "Parent2 Subsidies",
    colChildFirstName: "Child First Name",
    colChildLastName: "Child Last Name",
    colChildBirthDate: "Child Birth Date",
    colChildGender: "Child Gender",
    colChildAddress1: "Child Street Address",
    colChildAddress2: "Child Street Address 2",
    colChildCity: "Child City",
    colChildState: "Child State",
    colChildPostalCode: "Child Postal Code",
    colProviderNotes: "Provider Notes",
    colReferral: "Referral",
    confirmTitle: "Choose Download Type",
    confirmDescription:
      "Would you like to perform a full download of all fields or a partial download of family names and email addresses? Note that you should generally only give a provider a full waitlist download if they have churned from using Kinside.",
    fullDownload: "All Fields",
    partialDownload: "Family Names and Email Only",
    prefferedScheduleMissing: "Preferred schedules not available.",
  },
  familyOffer: {
    addFamily:
      "Or add a new family by entering their details below. They will receive an email with instructions to fill in the rest of their details with Kinside",
    buttonOffer: "Offer spot to family",
    childBirthday: "Child birth date",
    childFirstName: "Child First Name",
    childLastName: "Child Last Name",
    contractInstructions:
      "Select a tuition rate to offer to this family. We will send them instructions to enter their payment information for enrollment.",
    email: "Email",
    findChild: "Find child",
    instructions:
      "To hold this spot for a family, choose a family from your waitlist or add family details below.",
    parentFirstName: "Parent / Guardian First Name",
    parentLastName: "Parent / Guardian Last Name",
    seatNotes: "Notes for family",
    selectFamily: "Choose Family from Waitlist",
    stateInstructions:
      "How far is the parent / guardian in the process of engaging with your program?",
    statusSelect: {
      "sent paperwork": "Approve Enrollment",
      "question answered": "Answering Family Questions",
      "tour scheduled": "Schedule Tour",
    },
    title: "Offer {Seat} spot to family",
    titleSelectFamily: "Select a Family",
    tourDate: "A tour is scheduled for {Date}",
  },
  feedback: {
    buttonSubmit: "Submit Feedback",
    buttonSubmitParent: "Ask Kinside Support",
    debug: "Include system information to aid Kinside in troubleshooting",
    enter: "Enter Feedback",
    enterParent: "How can we help?",
    feedback: "Feedback",
    title:
      "Encountered an issue or have suggestions? Share feedback using the form below!",
    titleParent:
      "Need some help? Enter your question below and we'll get back to you!",
  },
  fillSeat: {
    fillOptions: {
      held: "By {Name}",
      waitlist: "By a child on the waitlist",
      tour: "By a child who toured",
      freeform: "By a child not on the waitlist",
    },
    buttonFill: "Fill spot",
    chooseContract: "Select tuition rate (if applicable)",
    childStartingOn: "Child Staring On",
    noChildName: "the child the spot was held for",
    title: "How did you fill this spot?",
    sectionOptions: "Filled spot options",
    sectionChild: "Verify Child Details",
    sectionParent: "Verify Parent/Guardian Details",
  },
  findChildList: {
    email: "Email",
    findParent: "Find Parent / Guardian or Child",
    name: "Name",
    title: "Children of {Name}",
  },
  findProvider: {
    buttonAdd: "Add Provider",
    search: "Find Provider",
    title:
      "Add a new provider or search for an existing provider to add a program",
  },
  gallery: {
    badgeEnrollment: "Enrollment Likely",
    badgePartner: "Kinside Partner Program",
    buttonAction: {
      seat: "View Opening",
      openseats: "Available spots",
      center: "View Program",
      waitlist: "Join waitlist",
      tour: "Schedule tour",
    },
    infoTitleAvailable: "Available Now",
    infoSubtitleAvailable: "Schedule",
    infoDistance: "{Miles} miles to {Label}",
    infoNoOpeningsTitle: "No Openings",
    infoOpeningsTitle: "Openings Available",
    infoOpeningsMultipleSubtitle: "Multiple spots available",
    infoOpeningsSingleSubtitle: "{Seat} available",
    infoToursTitle: "Tours Availabile",
    infoToursSubtitle: "Next Available: {Date} at {Time}",
    noOpenings: "No Openings",
    noOpeningsWaitlist: "No Openings - join the waitlist or schedule a tour!",
    unknownOpenings: "Spot and tour opening details unavailable",
    noPrice: "Pricing details unavailable",
    tooltipEnrollment:
      "Based on historic analysis, you have a good chance of getting into this program if you join the waitlist",
  },
  holdSeatDialog: {
    buttonNext: "Next",
    buttonDone: "Done",
    selectCenter: "First, select a center:",
    selectFamily: "Select a family or enter their details below:",
    selectSeat: "Select a spot to hold for the family:",
    filterSeats: "Filter spots corresponding to the child's age group",
    review: "Please review that the following is correct:",
    buttonSubmit: "Offer spot to family",
    familyCard: {
      parent: "Parent:",
      child: "Child:",
    },
    errors: {
      selectFamily: "Please select or input family details.",
      selectSeat: "Please select one spot.",
    },
  },
  importContracts: {
    buttonImport: "Import Tuition List",
    dialogTitle: "Confirm Import",
    errors: "Please fix the the following errors before proceeding",
    headerTip:
      "You should have the following headers in your CSV file: \"Name\", \"Refundable Deposit\", \"Non-refundable deposit\", \"Cost\", \"Frequency\", and \"Notes\". Please note that tuition rate and registration fee should be a numeric value in cents. That is, a deposit of $100 should be listed as 10000 in your CSV file",
    noErrors: "Nice - no errors were found with this tuition document!",
    subtitle: "Click below to import {Number} tuition",
    warnings: "We found the following warnings",
  },
  importDialog: {
    buttonImport: "Import",
    buttonImportValid: "Import only valid entries",
    dialogTitle: "Confirm Import",
    noErrors: "Nice! No errors were found in this file",
    subtitle: "Click below to import these {Number} entries",
    subtitleValid:
      "Click below if you would like to import the {Number} entries without errors. Entries with errors will download into a separate spreadsheet",
    titleCorrections:
      "We corrected {Count} errors in your input - please review these before proceeding",
    titleErrors:
      "The following {Count} errors need to be fixed before proceeding",
    titleWarnings:
      "We found {Count} warnings in your input - please review before proceeding",
  },
  importList: {
    buttonImport: "Import waitlists",
    dialogTitle: "Confirm Import",
    errors: "Please fix the the following errors before proceeding",
    noErrors: "Nice! No errors were found with this waitlist",
    warnings: "We found the following warnings",
  },
  importPrograms: {
    buttonImport: "Import Schedules",
    dialogTitle: "Confirm Import",
    errors: "Please fix the the following errors before proceeding",
    headerTip:
      "You should have the following headers in your CSV file: \"Name\", \"Start\", \"End\", \"Days\", \"Available\", and \"Prices\". Please note that Available and Prices should have values separated with a | character. For example, a Tuesday and Thursday schedule should have Available set to \"Tuesday|Thursday\". If the pricing options named \"Infant Full Time\" and \"Infant Extended\" should be associated, then the Prices field would be set to \"Infant Full Time|Infant Extended\"",
    noErrors: "No errors were found with this set of schedules!",
    subtitle: "Click below to import {Number} schedules",
    warnings: "We found the following warnings",
  },
  importProvider: {
    buttonExport: "Export {Provider}",
    buttonImport: "Import Provider",
    importFailure: "There was a problem importing this provider",
    importSuccess:
      "Import is in progress. Be sure to go to Manage providers to give the appropriate user access to this new provider",
    instructionsExport:
      "Click to export this provider. Save the downloaded file and use it in the other environment to import this provider's details",
    needPurge:
      "The provider you are trying to import already exists in this environment. If you continue, we will have to first COMPLETELY PURGE this provider before doing the import. Even if the import fails, we will no longer have the existing provider's data.\n\nDO YOU UNDERSTAND THE RISK AND DO YOU WANT TO CONTINUE?",
    title: "Import / Export Provider",
    subtitleExport: "Select a provider to export them to another environment",
    subtitleImport: "Have an exported provider file? Click here to import it",
    tooltipNoProd:
      "Sorry, you cannot import a provider into production at this time",
  },
  legupFamilySeats: {
    colCenter: "Program",
    colCity: "City",
    colAgeGroup: "Age group",
    colSeatAdd: "Spot added",
    colParentName: "Parent",
    colEmail: "Email",
    colChildName: "Child",
    confirmMessage:
      "Do you want to offer {Center}'s {AgeGroup} spot to {Child}?",
    instructions: "Click on the spot you would like to offer",
    loading: "Please be patient, this may take some time...",
    noFilteredSeats: "There are no programs that match your filter",
    noSeats: "We didn't find any open spots with matching Kinside families",
    search: "Program name",
    title: "Centers with spots that can be offered to Kinside Families",
  },
  legupList: {
    buttonFill: "Fill program spot",
    buttonSave: "Save Questions",
    filterInstructions:
      "At this point we would filter the Kinside Waitlist to only show parents / guardians that are a match for {AgeGroup} at {Center}. Coming soon.",
    findChild: "Find child",
    findList: "Find List",
    loading: "Please wait for the Kinside List to load",
    market: "Kinside List",
    questionsTitle: "Custom Questions",
    title: "Kinside list for {Market}",
    titleSelect: "Select a Kinside List",
  },
  login: {
    authError:
      "{Error}<br><br>Please try again in a few minutes or contact <a href=\"mailto:support@kinside.com\">support@kinside.com</a> if the problem persists",
    authErrorGeneric:
      "Sorry, there was an error with the authentication process.",
    buttonLogin: "Login",
    buttonLogout: "Log out",
    verifyEmailParent:
      "Thanks for completing registration for Kinside. Please verify your email address by using the link sent to your inbox.",
    verifyEmailProvider:
      "Thanks for completing registration for The Kinside Enrollment Platform. Please verify your email address by using the link sent to your inbox.",
  },
  mailPrefs: {
    buttonSave: "Update Preferences",
    subtitle:
      "Let us know which of the following locations to consider when searching for openings for your child",
    title: "Kinside Opening Mailing Preferences",
    checkHome: "Search near your home address",
    checkCenter: "Search near other programs you are on a waitlist for",
    checkAlternate: "Search near this address:",
    saveSuccess: "Your preferences have been updated",
    saveFail: "There was a problem updating your preferences",
  },
  main: {
    previewProfile: "View your profile",
    allMarkets: "All Markets",
    allPrograms: "All Programs",
    allLocations: "All locations",
    buttonViewComments: "Comments",
    buttonDownload: "Download Datapoints",
    chartDepositRevenueLabel: "Registration Fee Revenue",
    chartFillLabel: "Days to fill spots",
    chartFillInfo:
      "Average time to fill an open spot, measured from when the spot is first added to the point that a family enrolls",
    chartLegupOccupancyLabel: "Overall Occupancy",
    chartLegupOccupancyInfo:
      "Overall occupancy percentage based on total capacity across all registered programs less their open spots",
    chartLegupBudgetedOccupancyLabel: "Your Budgeted Occupancy",
    chartLegupLicensedOccupancyLabel: "Your Licensed Occupancy",
    chartPreferredEnrollmentTitle: "Preferred Enrollment",
    chartPreferredEnrollmentInfo:
      "This pie chart shows you the current preferred enrollment date, by quarter, of children on waitlists. This can be filtered by age range.",
    chartPreferredMaxTime: "More than 1 year out",
    chartPreferredPastTime: "Past preferred date",
    chartPreferredSuffix: " children",
    chartSubtitle: "Enrollment Report",
    chartSupplySubtitle: "Supply Data",
    chartWaitlistTimeSubtitle: "Enrollment Trends",
    chartWaitlistTimeTitle: "Your Time on Waitlists",
    chartWaitlistTimeInfo:
      "The number of weeks you have been on program waitlists",
    chartWaitlistTimeMarketLabel: "Median waitlist time in {Market}",
    chartWaitlistTimeTooltip: " weeks",
    chartDays: " days",
    chartSeats: " spots",
    defaultQuote:
      "Whether you think you can, or you think you can't -- you're right.",
    defaultAuthor: "Henry Ford",
    enrollment: {
      "parent question": "Answer the {Name} family's question",
      "schedule tour": "Schedule tour for the {Name} Family",
      "ready to enroll": "Approve {Name} Family for Enrollment",
    },
    enrollmentTitle: "Drive Enrollment",
    enrollmentSubtitle: "Pending Enrollment Tasks",
    enrollmentButton: "Take Action Now",
    feedback: "Leave Feedback",
    helpButton: "View More FAQs",
    helpLines: [
      "Can I offer a spot to a family?",
      "How do I remove a family from the waitlist?",
      "How do I add a tour for a family that doesn't have a spot offer?",
    ],
    helpSubtitle: "Enrollment FAQs",
    helpTitle: "Learning Center",
    infoPipeline: "Interested",
    infoPipelineDetail: "Families",
    infoPipelineEnroll: "{Count} Ready to Enroll",
    infoPipelinePotential: "{Amount} Monthly Potential",
    infoOpen: "Open spots",
    infoOpenDetail: "Spots",
    infoTours: "Upcoming Tours",
    infoToursDetail: "Tours",
    infoToursWeek: "{Count} this week",
    infoToursIn30: "{Count} in the next 30 days",
    infoWaitlist: "Waitlist",
    infoWaitlistDetail: "Families",
    infoWaitlistMonth: "{Count} want care this month",
    infoWaitlistYear: "{Count} want care this year",
    preferredFilterLabel: "Age range",
    preferredFilterAll: "All Ages",
    greeting: "Welcome, {Name}",
    greetingNoName: "Welcome to Kinside",
    titleTourComments: "Tour Survey Comments",
    titleEnrollmentComments: "Post-Enrollment Survey Comments",
    surveyEnrollmentComments:
      "These are free-form comments from people who enrolled in your program through Kinside.",
    surveyEnrollmentNoComments:
      "You haven't received any free-form comments yet from the post-enrollment survey.",
    surveyTourComments:
      "These are free-form comments from people who scheduled tours for your program through Kinside.",
    surveyTourNoComments:
      "You haven't received any free-form comments yet from the tour survey.",
    filledSeats: "FILLED SPOTS (LAST 30 DAYS)",
    familyOffers: "PROCESSED OFFERS (LAST 30 DAYS)",
    findItem: "Find Program, Parent / Guardian, or Child",
    postSeats: "Add open spot",
    buttonSeats: "Show spots",
    buttonSetTourSchedule: "Set Tour Availability",
    buttonStart: "Get Started",
    buttonStripe: "Sign up with Stripe",
    buttonTextOptIn: "Opt-in",
    buttonUpload: "Upload Waitlist",
    colCenter: "Program",
    colUpload: "Upload",
    logout: "Log out",
    moreItems: "Type more to refine your search",
    noResults: "No results found",
    noTextOptIn:
      "Get text messages when families are interested in your openings! Opt-in for text message alerts.",
    noTourSchedule:
      "Please enter your tour availability so families can schedule program tours.",
    notifyOffered:
      "Since your last login, Kinside has offered {Offered} spots to families!",
    notifyFilled:
      "Since your last login, {Filled} families have been sent to you to move forward with enrollment.",
    notifyFilledAndOffered:
      "Since your last login, Kinside has offered {Offered} spots and {Filled} families have been sent to you to move forward with enrollment.",
    resultCenter: "Program",
    resultChild: "Child",
    resultParent: "Parent / Guardian",
    search: "Search for a family, child or location",
    searchEmail: "Search for email or mobile number",
    searchEmailProvider: "Enter provider email or phone number",
    sendDetails: "Send us your classroom details to get started!",
    stripeOnboard:
      "Waitlist fees are collected from families and paid to you through Stripe, our third-party payment processor. To receive your waitlist fees on the 1st business day of every month, please setup your payout account now.",
    uploadTitle:
      "Let's get started! Next step to help you manage waitlists and fill spots faster is to upload your current waitlist. ",
    waitlistFamiliesAll: "All Families",
    waitlistFamiliesPrivate: "Private Pay",
    waitlistFamiliesSubsidy: "Subsidy",
    welcome: "Welcome to Kinside!",
    welcomeName: "Welcome to Kinside, {Name}!",
    welcomeProvider: "Welcome {Name}!",
  },
  marketplaceDisplay: {
    allCenters: "All Programs are being displayed in search results",
    cardFind: "Select a program to hide from browse opening search results",
    cardHidden: "Programs hidden from browse opening search results",
    colCenter: "Program",
    colDelete: "Remove from banned list",
    colLocation: "Location",
    title: "Search Display Settings",
  },
  marketplaceFilter: {
    buttonSave: "Save Filters",
    dayInstructions: "Choose how many days per week you need care",
    dowInstructions:
      "Select the days of the week that you are interested in care. We will only show spots that are available those days",
    programInstructions:
      "Click here to only show spots with specific schedules",
    title: "Set Filters",
    noBirthdateFilter: "Filter on child birthdate",
    noPreferredDate: "Show all enrollment months",
    birthdate: "Birth Date",
    centerHour: "Care Options",
    alternateCare: {
      "24hour": "24 hour care",
      nights: "Nights",
      weekends: "Weekends",
      beforeschool: "Before school care",
      afterschool: "After school care",
    },
    centerType: "Program Type",
    centerTypes: {
      center: "Center",
      home: "Family Child Care",
    },
    child: "Child",
    enrollmentMonths: "Preferred Enrollment",
    priceRange: "Set Max Price",
    subsidies: "Accepts Subsidies",
    anySubsidy: "Any subsidy",
    labelPreferredRange:
      "We'll show you openings within a month of your preferred date",
    noPrice: "Choose maximum price or leave unchecked to show all spots.",
    noSubsidies:
      "Choose subsidy preferences or leave unchecked to show all programs.",
    daysPerWeek: "Days per week",
    daysOfTheWeek: "Days of the week",
    showAnyDays: "Show any days",
    instructions: "",
    tabSeats: "Enrollment Preferences",
    tabCenters: "Program Filters",
  },
  messageDelays: {
    buttonRecall: "Recall pending message(s)",
    colRecall: "Recall",
    colId: "Message ID",
    colRecipient: "Recipient",
    colTemplate: "Email template",
    colDate: "Will be sent at",
    noDelayedMessages: "There are no pending messages that can be recalled",
    title: "Recall pending messages before they are processed",
    updateSuccess: "These messages have been flagged to not send",
    updateFailure: "There was a problem recalling these messages",
  },
  myCenters: {
    address: "Address",
    ageTitle: "Ages:",
    buttonFind: "Add More Programs",
    buttonHandbook: "View Program handbook",
    buttonSafetyGuidelines: "View Health and Safety Policy",
    buttonProgram: "View Program",
    buttonTour: "Schedule Tour",
    buttonWaitlist: "Join Waitlist",
    contact: {
      email: "Email:",
      phone: "Phone Number:",
      website: "Website:",
    },
    contactInfo: "Need to get in touch with {Center}?",
    externalWebsite: "Website",
    formBoth:
      "{Center} is a licensed child care provider in {City}. {Schedule}You can review their <a href={HandbookURL} target=\"_blank\">program handbook</a> for more information about policies at this program. View the <a href={SafetyURL} target=\"_blank\">health and safety policy</a> to see the latest guidelines in effect at this program.",
    formHandbook:
      "{Center} is a licensed child care provider in {City}. {Schedule}You can review their <a href={HandbookURL} target=\"_blank\">program handbook</a> for more information about policies at this program.",
    formSafety:
      "{Center} is a licensed child care provider in {City}. {Schedule}You can review their <a href={SafetyURL} target=\"_blank\">health and safety policy</a> to see the latest guidelines in effect at this program.",
    emailSubject: "I found a child care provider on Kinside",
    emailBody:
      "I found a child care provider on Kinside at {url}. To view this profile, you'll need to signup for a free account with Kinside at {signup url}",
    handbook: "Program Handbook",
    joinWaitlist: "Join Waitlist",
    license: "Licensing Information",
    licenseTitle: "License: ",
    noAddress: "No address on file",
    noContact: "No contact information on file",
    noMap: "Don't show map on enrollment page",
    noSchedule: "No schedule information available",
    open: {
      individualDay: "{Day} from {Start} to {End}",
      individualDayTitle: "The program is open {Days}. ",
      sameTimeAllDays: "The program is open every day from {Start} to {End}. ",
      sameTimeSomeDays: "The program is open {Days} from {Start} to {End}. ",
      sameTimeWeekDays:
        "The program is open Monday thru Friday from {Start} to {End}. ",
    },
    openSeatsTitle: "Open spots",
    safetyGuidelines: "Health and Safety Policy",
    sectionFavorite: "Following",
    sectionTour: "Toured Programs",
    sectionWaitlist: "On Waitlist",
    schedule: "Schedule",
    shareMessage:
      "Parent / Guardian with ID {ID} is letting you know other waitlists from the My Programs page: {Text}",
    shareSubject:
      "Parent / Guardian is sharing other waitlists from My Programs",
    titleCenterDetails: "Information for {Center}",
    titleListCenters: "My Programs",
    titleListCentersNavigator: "My Families Programs",
  },
  notFoundPage: {
    text: "Sorry, the page you're looking for doesn't exist. Please check your URL or contact us at <a href=\"mailto:support@kinside.com\">support@kinside.com</a>",
  },
  offerContract: {
    buttonSelect: "Send Registration Form and Request Enrollment Fee",
    buttonWaive: "Save Reason",
    childStartingOn: "Step 2: Select family's start date",
    childStartInstructions:
      "If you know the family's start date, enter it here",
    chooseContract: "Step 1: Select tuition rate (if applicable)",
    chooseContractNotes: "Note that {Notes}",
    chooseContractTitle: "Approve Enrollment and Send Registration Form",
    depositWaived: "(Registration fee waived)",
    familyNotes: "Step 4: Send notes to family (optional)",
    familySubsidy:
      "Because of your account settings, and because this family is eligible for a subsidy that you support, Kinside is waiving their registration fees below. To charge your standard fee, uncheck the box below. To charge a modified fee, manually update the deposit amount below.",
    linkWaivedDeposit:
      "There is no deposit due for this family. Click here to update the deposit due.",
    noteSubsidy: "Subsidy Family",
    unknownStartDate: "I don't know the family's start date",
    notes: "Notes",
    offerDiscount: "Discount or waive fee for this subsidy-eligible family",
    offerExpiration: "Step 3: Set a payment deadline",
    expiration:
      "By default, the enrollment offer will be revoked if payment isn't made within 7 days, and the spot will be offered to the next family on the waitlist. If you want to give the family more time to complete the registration form and pay any fees, select a different deadline.",
    discountReason:
      "Please let us know why you are changing the fee for this family (Required)",
    discountDepositAmount: "Fee to charge family (including Kinside's fee)",
    discountDepositInvalid: "This amount will include Kinside's fee of {Fee}. Please enter a fee amount that's more than Kinside's fee of {Fee}.",
    discountRefundableDeposit: "Refundable registration fee",
    discountRefundableDepositInvalid: "The refundable amount cannot exceed the amount you receive after Kinside's fees",
    discountPayout: "You will receive {Amount} after Kinside's fees",
  },
  offerSummary: {
    choiceButtons: {
      tour: "Enrollment tour",
      accept: "Accept",
      question: "Ask Question",
      pass: "Pass",
      remove: "Remove from list",
    },
    choiceInstructions: {
      tour: "Request a final tour before accepting.",
      accept: "Accept this spot and request enrollment.",
      question:
        "Questions about this offer? Ask them here and the {Center} team will get back to you shortly (the spot will stay held for you)",
      pass: "Need to change your preferred schedule or change your desired enrollment date? Click here to make those changes and keep your position on the waitlist",
      remove:
        "Not interested in {Center} anymore? Click here and you'll be removed from the waitlist",
    },
    choiceInstructionsLegupOffer: {
      tour: "Request an enrollment tour before accepting",
      accept: "Accept this spot and request enrollment.",
      question:
        "Questions for {Center} about this offer? Ask them here and they will get back to you shortly (the spot will stay held for you)",
      pass: "Not interested in {Center}? Does this specific offer not work for you? Click here to let us know so we can send you better matches in the future",
    },
    requestTour: {
      comments: "Additional comments",
      dayTime: ["Morning", "Afternoon", "Evening"],
      instructions:
        "Select your preferred day(s) and time for a tour. We will pass this information to {Center} and they will reach out you to confirm a tour as soon as possible. {Policy}",
      message: "We would like to tour {days} in the {times}",
      selectDay: "Select a day:",
      selectTime: "Select a time:",
      subComments:
        "If you would like to tour after the next week, please indicate your preferred date(s) in the comments below",
      submit: "submit",
      title: "Request a tour",
      weekdayNames: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
    },
    cardCenter: "Program Details",
    cardSeat: "Spot details",
    cardSteps: "Next Steps (CHOOSE ONE)",
    costNotes: "{Cost}. {Notes}",
    deposit: "{Deposit} ({Refundable} is refundable)",
    depositNotes: "{Deposit} ({Refundable} is refundable). {Notes}",
    noChildName: "your child",
    seatAvailable: "Available: {Date}",
    seatSchedule: "Schedule: {Program} ({Days} {Time})",
    seatCost: "Tuition Rate: {Cost}",
    seatDeposit: "Registration Fee: {Deposit}",
    title: "Spot offer from {Center}",
    welcome:
      "Hi {Parent}! A {AgeGroup} spot has become available at {Center}. Do you still need care for {Child}?",
    welcomeLegup:
      "Hi {Parent}! A spot at {Center} has become available and is being held for {Child}. Let us know if you are interested in this spot or not before it becomes available for the general public",
  },
  offerTour: {
    buttonSubmit: "Schedule tour",
    inPerson: "We offer in person tours for this program. {Times}",
    instructions: "Pick an available time for your {Center} tour.",
    noneAvailable:
      "There are no available times at {Center} for the next two weeks. Please indicate the days and times of day that you prefer for a tour and {Center} will get back to you with upcoming tour times.",
    tourDescription:
      "You are requesting a {Duration} minute tour of {Center} at {Time}",
    tourDescriptionVirtual:
      "You are requesting a {Duration} minute virtual tour at {Time}. {Center} will follow up with video conference instructions.",
    virtual:
      "We offer online tours for this program. {Times} You will be sent video conference instructions after your tour is confirmed.",
  },
  onboard: {
    setup: "{Center} has been set up",
    buttonAnother: "Onboard another program",
    buttonNew: "Onboard a program",
  },
  onboardSurvey: {
    buttonBack: "Back",
    buttonContinue: "Continue",
    buttonDone: "View My Dashboard",
    buttonSkipStripe: "Skip Stripe & Finish",
    buttonStart: "Let's Get Started",
    questions: {
      welcome: {
        title:
          "You're in! Managing enrollment activity is about to get a lot easier.",
        subtitle:
          "Welcome to the Kinside Enrollment Platform, your centralized enrollment management tool. We'll start by customizing your enrollment process.",
      },
      welcomeback: {
        title: "Welcome back.",
        subtitle:
          "It looks like you've already started customizing your enrollment process. Let's get started where you left off.",
      },
      waitlistpreferences: {
        title:
          "First, we'll look at your waitlist. When do you allow families to join your waitlist?",
        choices: [
          { any: "Anytime they want" },
          { aftertour: "After they tour" },
          { noopening: "When we don't have openings" },
        ],
      },
      waitlistfees: {
        title: "Do you charge a fee to join your waitlist?",
        choices: [{ yes: "Yes, we do" }, { no: "No, we don't" }],
        labelPrice: "Great! How much?",
        textPrice: "Click to add price",
      },
      waitlistupload: {
        title:
          "Do you have an existing waitlist? If so, you can upload that here.",
        subtitle:
          "If you don't have access to your waitlist right now or you need to take photos of physical documents you can submit that later. Email support@kinside.com or reach out to your Enrollment Support Manager.",
        choices: [
          { none: "We don't have a waitlist" },
          { later: "I'll send this later" },
        ],
        buttonUpload: "Click to Upload File",
      },
      tourpreferences: {
        title:
          "You're doing great! Next, let's take a look at tours. When do you allow families to schedule tours?",
        choices: [
          { any: "Anytime they want" },
          { afterwaitlist: "Only after they've joined my waitlist" },
          { opening: "Only if we have openings" },
        ],
      },
      tourrequirement: {
        title: "Do you require families to tour before they can enroll?",
        choices: [{ yes: "Yes, we do" }, { no: "No, we don't" }],
      },
      enrollmentpacket: {
        title:
          "Great, just a few things left! Do you have an enrollment package you send families during enrollment? If so, you can upload that here.",
        subtitle:
          "If you don't have access to your enrollment packet right now you can submit that later. Email support@kinside.com or look for the \"Program Paperwork\" section in the Enrollment Management Platform.",
        choices: [
          { none: "We don't have an enrollment packet" },
          { later: "I'll add this later" },
        ],
        buttonUpload: "Click to Upload File",
      },
      subsidies: {
        title: "Do you accept any of the following subsidies?",
        subtitle: "Select any that apply",
      },
      stripe: {
        title:
          "Finally, we want you to get paid. Take a moment to connect your account to our payment processing system.",
        subtitle:
          "We use a secure payment processing system called Stripe to send you your enrollment and waitlist fees. By creating a Stripe account, you give us the ability to transfer the money we collect from your families directly into your account.",
        buttonStripe: "Connect Stripe",
      },
      siblingretrieved: {
        title:
          "We saved your answers from the last time you took The Concierge Survey with a different child",
        label:
          "Please review the questions in The Concierge. We have pre-populated answers based on your responses from your other child's care needs.",
      },
      done: {
        title: "Your enrollment process is all set!",
        subtitle:
          "Now you can begin exploring the Enrollment Platform and setting up the tools that will help you manage enrollment activity for all of your leads. Keep an eye out for tips and tutorials along the way to help you learn the ropes.",
      },
    },
  },
  onboardWizard: {
    addCenterTitle: "Add new program details",
    agegroupTitle: "Add age groups",
    contractTitle: "Enter your pricing details",
    done: "You've onboarded this program! You can also:",
    doneTask: "Onboarding Complete!",
    doneTitle: "Onboarding Complete",
    doneSteps: [
      "Upload files such as the program handbook, health and safety guidelines, or logo by clicking on Manage Provider",
      "Import an existing waitlist of children",
      "Give users access to this provider account so the provider can log in themselves to manage their program",
    ],
    editCenterTitle: "Review Details for {Center}",
    editProviderTitle: "Review Details for {Provider}",
    findCenterTitle: "Select a program or add a new one",
    menuTasks: [
      "Find Provider",
      "Review Provider Details",
      "Find Program",
      "Review Program Details",
      "Setup Age groups and classrooms",
      "Enter Schedule Details",
      "Setup Pricing Details",
      "Setup Waitlist Fees",
    ],
    programsTitle: "Set up schedules and pricing options",
    title: "Onboard program",
    welcome:
      "Alright, let's set up a new program to use Kinside! Make sure you have the following details from the provider:",
    welcomeTask: "Onboard new program",
    welcomeTitle: "Onboard program",
    welcomeSteps: [
      "Business details for the program",
      "A pricing list and schedules for the program's classrooms",
      "A list of age groups and classrooms for the program",
    ],
  },
  openedEmails: {
    chartTitle: "Mails Opened Per Day",
    chartSubtitle: "Open Email Report",
    chartInfo:
      "This chart gives you a day-by-day breakdown of views for this template, based on the date sent",
    chartLabelOpen: "% of mails opened",
    chartLabelTime: "Median hours to open",
    colTemplate: "Template",
    colOpened: "Mails Opened",
    colSent: "Mails Sent",
    colPercentage: "Opened Percentage",
    noResult: "No opened email results were found",
    subtitle:
      "Statistics are as of May 17, 2021 and only cover emails with HTML content",
    title: "Opened Emails Summary",
  },
  openSeatsTab: {
    buttonConcierge: "Retake Concierge Survey",
    buttonSeatsAvailable: "Enroll Now",
    confirmExitSurvey:
      "If you exit the survey without completing it, you will not be able to view search results. Are you sure you want to exit?",
    header: "Search for Care",
    legupOnly:
      "Join The Kinside Concierge to browse open spots at quality programs, schedule tours, join waitlists, or request enrollments.",
    noSeatsAtCenter: "Fully Enrolled!",
    saveAddress: "Would you like to save {Address} as your home address?",
    tabCenters: "Programs",
    tabSeats: "Openings",
  },
  openSeat: {
    ages: "Ages {ageRange}",
    availableNow: "Available Now",
    button: "Learn More",
    dateAvailable: "Available {date}",
  },
  parentAdded: {
    errorCode:
      "We have contacted the Kinside Support Team to help re-add your child to this list",
  },
  parentContact: {
    address: "Address: {Address}",
    answers: "Answers to Custom Waitlist Questions",
    age: "Age: ",
    availableNow: "Starting now",
    availableNextMonth: "Starting next month",
    body: "Hi {Parent}!\n\nI'm very excited to share that a spot has become available for {Child} at {CenterName}! [MAP HERE]!\n\nAvailable: {Available}\n{Contracts}\n\n{Links}\nPlease provide a response by [_____] or we will have to offer this spot to the next family on the list.\n\nThank you\nThe Kinside Team",
    buttonContact: "Offer spot to family",
    buttonEditAnswers: "Edit Answers",
    buttonFill: "Fill spot with this child",
    buttonRemove: "Remove from Waitlist",
    buttonRemoveMe: "Remove me from Waitlist",
    buttonSave: "Save changes",
    buttonSaveAnswers: "Save Answers",
    child: "Name: {Name}",
    comments: "Parent / Guardian Comments",
    contactInstructions:
      "Click this button to contact the parent / guardian at {email}. You must click this button to update this spots's status and notify the provider that their spot has been offered to a family.",
    contractName: "Pricing Option: {Name}",
    contractCost: "\n   Tuition Rate: {Cost}",
    contractDeposit: "\n   Registration Fee: {Deposit}",
    contractNotes: "\n   Notes: {Notes}",
    contractMultiple:
      "We have the following options available:\n\n{Contracts}\n\n",
    dateAdded: "Joined waitlist on: ",
    deleteInstructions:
      "If you would like to permanently delete this child from this waitlist please select a reason below. They will no longer show up in your console. This information will be emailed to the family.",
    deleteReasons: [
      { label: "Removed at parent request" },
      { label: "Accidentally added family" },
      { label: "Other", showReasonText: true },
    ],
    deleteReasonsPlaceholder: "Select Reason",
    deleteCheckboxLabel: "Refund will be given (if payment was made)",
    emailLabel: "Email: ",
    emailSubject: "A spot is available!",
    familyDetails: "View family details and activity Log",
    fillInstructions:
      "Filling this spot with this child will automatically remove this child from all other waitlists they are on",
    unfillInstructions:
      "This spot will be re-opened for families on your waitlist unless you remove the spot or pause it from receiving automated offers from Kinside",
    fillTitle: "Do you want to fill this spot with this child?",
    unFillTitle: "Do you want to return this this family to the waitlist?",
    gdprInstructions:
      "NOTE: We do not have a way to programmatically remove a child from our records. Click YES to mark them as deleted - but note that you will also have to let Kinside Technical Support know that they need to permanently purge this child/family from our database",
    gdprTitle: "Do you want to permanently delete this family?",
    holdInstructions:
      "This child was offered a spot on {Date}. Click here to change this status",
    holdInstructionsNoDate:
      "This child was offered a spot. Click here to change this status",
    mailPrefs:
      "Update how you receive alerts of newly opened spots at programs you've favorited.",
    noTour: "No tour has been scheduled. Click here to set a tour date",
    offerCodes: {
      remove: "Click here to remove yourself from this waitlist.",
      pass: "Click here to pass on this spot but stay on this waitlist.",
      tour: "Click here to schedule a final tour before accepting.",
      accept: "Click here to accept this spot and request enrollment.",
      question: "Click here if you have a question before accepting this spot.",
    },
    parent: "Parent / Guardian: {Name}",
    phone: "Phone: {Phone}",
    priority: "Priority: ",
    providerNotes: "Internal Notes (shared with Kinside)",
    referral: "Referral",
    removeInstructions:
      "If you would like to mark this child as inactive on this waitlist please provide a reason below. This will allow them to retain their position if you later wish to reactivate them",
    removeTitle: "Remove from waitlist",
    parentTitle: "Parent / Guardian Details",
    parent2Title: "Parent / Guardian 2 Details",
    reactivateInstructions:
      "Reactivating this child will restore them to their original waitlist position",
    reactivateTitle: "Do you want to reactivate this child?",
    removeMeInstructions:
      "If you would like to remove yourself from this waitlist please provide a reason below for our records",
    setDncb: "Will not enroll before",
    setTemporary: "Only looking for permanent care",
    skipInstructions:
      "Setting the status to active will skip this child and put the spot back into an Open state. At that point, you may offer it to the next family on the list.",
    skipTitle: "Do you want to skip this child?",
    statusTitle: "Status",
    statusActive: "Active on Waitlist",
    statusBacktoWaitlist: "Move Family Back to Waitlist",
    statusHold: "Offered to Family",
    statusFill: "Fill spot with this child",
    statusUnFill: "Return family to waitlist",
    statusInactive: "No Response from Parent / Guardian",
    statusDelete: "Remove from Waitlist",
    statusGDPR: "Family Requested to be Deleted From Kinside",
    subsidies:
      "This family is eligible for subsidized care through {Subsidies}",
    subsidyTitle: "Subsidy Programs",
    title: "Waitlist Details",
    titleEditAnswers: "Edit your answers",
    titlePrograms:
      "Select your preferred schedules. You may select multiple options if you are flexible in your choices",
    titleProgramsReadOnly: "Selected Schedules",
    tookTour: "Tour scheduled",
    tourDate: "Date of program tour",
    tourLabel: "Tour date: ",
  },
  parentDetails: {
    addParent: "Adding a new Parent / Guardian",
    buttonAdd: "Add Parent / Guardian",
    buttonMessages: "View notifications sent to family",
    buttonSave: "Save changes",
    buttonSubsidies: "Save Subsidy Programs",
    canText: "Text me updates.",
    changeEmailTitle: "Changing Email Address",
    changeEmailWarning:
      "Changing your email address here changes the address we use to send emails but DOES NOT change the email you use to login to Kinside. To change that email, go to your user profile. Would you like to continue?",
    company: "Company",
    editParent: "Parent / Guardian 1 Details",
    email: "Email",
    firstName: "First Name",
    lastName: "Last Name",
    navigator:
      "This family is represented by {Name} (email: {Email} / phone: {Phone})",
    noTextTitle: "Opt-in for text message alerts",
    noTextText:
      "Would you like to opt-in to receive text message alerts about child care openings?",
    parent2Title: "Parent / Guardian 2 Details",
    phoneNumber: "Phone Number",
    required: " (Required)",
    requiredAddress: "A mailing address is required",
    requiredPhone: "At least one phone number is required",
    subsidyTitle: "Subsidy Programs",
    tabAddChild: "Adding Child",
    tabParent: "Parent / Guardian",
  },
  parentList: {
    buttonAdd: "Add parent / guardian",
    buttonViewFamily: "View family details",
    buttonViewLead: "View lead details",
    buttonReactivateSpot: "Reactivate waitlist spot",
    buttonMessageFamily: "Send family a message",
    buttonEnroll: "Enroll",
    buttonReEnroll: "Re-enroll",
    buttonEditEnrollment: "Edit enrollment",
    buttonRequestPayment: "Request payment",
    centers: "Programs",
    colChild: "Child",
    colParent: "Parent / Guardian",
    colCenter: "Program",
    colStatus: "Status",
    findParent: "Find parent / guardian or child",
    email: "Email",
    name: "Name",
    noMatch: "No matching parents / guardians were found",
    pageInfo: "",
    status: {
      all: "Status",
      // Spot states
      active: "On waitlist",
      hold: "Spot offered",
      inactive: "Non-responsive",
      deleted: "Deleted",
      filled: "Enrolled",
      unenrolled: "Unenrolled",
      pending_unenroll: "Pending unenrollment",
      pending: "Pending enrollment",
      // Seat states - override spot states
      "parent declined": "Parent Declined Spot Offer",
      "parent passed": "Parent Passed on Spot",
      "parent question": "Asked Question",
      "schedule tour": "Requested Enrollment Tour",
      "ready to enroll": "Ready to Enroll",
      "question answered": "Question Answered",
      "tour scheduled": "Enrollment Tour Scheduled",
      "seat removed": "Spot Removed",
      "sent paperwork": "Enrollment Approved",
      // And special case if seat available date hasn"t passed
      "seat pending": "Starting Soon",
      // Tour states (not on waitlist)
      "t:complete": "Completed tour (not on waitlist)",
      "t:upcoming": "Scheduled tour (not on waitlist)",
      "t:requested": "Requested tour (not on waitlist)",
      // Lead states (not on tour or waitlist)
      "l:lead": "Lead",
    },
    titleFamilies: "Family",
  },
  partnerList: {
    findPartner: "Find Partner",
    location: "Location",
    name: "Name",
    noMatch: "No matching partners were found",
  },
  partnerProvider: {
    chooseDifferentProvider:
      "Please select a different provider than the one you want to replace",
    confirmReplace:
      "Please confirm that you would like to change the provider in this partnership to {Provider} (PID = {ProviderID}). NOTE THAT THIS ACTION CANNOT BE UNDONE",
    colPartner: "Partnership",
    colProvider: "Provider",
    colLocation: "Location",
    colState: "State",
    mergeSuccess:
      "The partnership was successfully updated. Please contact engineering to resend any enrollment events to the partner if necessary",
    mergePurgeFail:
      "The partnership was updated, but the original provider was not removed from the database. Please contact engineering to complete this removal",
    search: "Filter providers",
    title: "Partner-based providers",
    noFilteredProviders: "No providers match this filter",
    providerInstructions:
      "Choose the provider-partner mapping you would like to change",
    purgeProvider:
      "Also delete the original provider - {Provider} (PID = {ProviderID}) - from the database",
    changeMappingTitle: "Partnership for {Provider}",
    selectProvider:
      "Select a new provider to replace {Provider} (PID = {ProviderID})",
  },
  navigators: {
    access: {
      full: "Full (Social Worker)",
      signup: "Sign-up Only (CCR&R)",
    },
    colAccess: "Access Type",
    colNavigator: "Primary Contact",
    colName: "Navigator",
    confirmRemove:
      "Do you want to remove access to Kinside for {User}? Note that this will delete this user account.",
    newUser: "Grant Navigator Access",
    noAccess: "No users currently have access to The Kinside Navigator.",
    removeSuccess: "Navigator access was removed for this user",
    removeFail: "There was a problem removing navigator access for this user",
    saveSuccess: "Navigator account added!",
    saveFail: "There was a problem saving this user",
    sendMailLabel:
      "Send this user instructions to login to The Kinside Navigator.",
    titleAdd: "Or Add New Navigator",
    titleChoosePartner: "Select a Navigator",
    titleDetails: "{Navigator} Details",
    titlePartnerAccess: "Users with Access to {Partner}",
  },
  navigatorDetails: {
    access:
      "This navigator has full access to parent communications (e.g. a social worker)",
    buttonAdd: "Add Navigator",
    buttonSave: "Save changes",
    managerName: "Provider Contact Name",
    name: "Name",
    email: "Email",
    phoneNumber: "Phone Number",
    title: "Details for {Name}",
    titleNoName: "Navigator Details",
  },
  partners: {
    firstLevel: {
      providerlist: "Programs associated with admin unit",
      state: "State level",
      county: "County level",
      city: "City level",
      all: "All programs",
    },
    filters: {
      market: "By market",
      state: "By state",
      county: "By county",
      city: "By city",
      individual: "By individual program",
    },
    noFirstLevel: "No dashboard view",
    noFilter: "N/A",
    colFirstLevel: "Dashboard scope",
    colFilters: "Dashboard filters",
    colName: "Partner",
    colWhitelabel: "Whitelabel Access?",
    colNavigators: "Navigator Access?",
    confirmRemove:
      "Do you want to remove access to the partner dashboard for {User}? Note that if this user also has access to the Enrollment Platform, we will NOT remove that access here. Only partner access will be revoked.",
    newUser: "Grant Partner Tool Access",
    noAccess: "No users currently have access to Kinside.",
    removeSuccess: "Partner access was removed for this user",
    removeFail: "There was a problem removing partner access for this user",
    saveSuccess: "Partner account added!",
    saveFail: "There was a problem saving this user",
    sendMailLabel: "Send this user instructions to login to Kinside.",
    titleChoosePartner: "Select a Partner",
    titlePartnerAccess: "Users with Access to {Partner}",
    updateUser: "Update Partner Tool Access",
  },
  paymentDescription: {
    deposit: "Registration Fee for spot at {Center}",
    waitlist: "{Center} Waitlist Fee",
    tuition: "{Center} Tuition",
  },
  paymentForm: {
    buttonSubmitDeposit: "Pay ${Total}",
    bankFees: "Processing Fees (non-refundable): {Amount}",
    ccFees: "Credit Card Fees (non-refundable): {Amount}",
    confirmAchPayment:
      "Please click Submit Payment to confirm this payment via {Bank}",
    depositDetails:
      "The total amount due is {Deposit}.<p/>This includes a non-refundable amount of {NonRefundable} and Credit Card processing fees of {CCFee}",
    depositError:
      "There was a problem saving your form. Please try again later, or contact Kinside Support if the problem persists",
    name: "Name",
    nonRefundable: {
      deposit: "Non-refundable registration fee: {Amount}",
      waitlist: "Non-refundable waitlist fee: {Amount}",
      tuition: "Tuition: {Amount}",
    },
    processingPayment: "Processing payment...",
    refundable: {
      concierge: "Refundable monthly fee: {Amount}",
      deposit: "Refundable registration fee: {Amount}",
      waitlist: "Refundable waitlist fee: {Amount}",
      tuition: "Refundable tuition rate: {Amount}",
    },
    total: "Total: {Total}",
    unknownPaymentError:
      "There was a problem processing your payment. Please try again later, or contact Kinside Support if the problem persists",
    useParentDetails: "Use parent / guardian details",
    useAchSavings:
      "<B>SAVE {Savings}</B> by using a direct debit from your bank account",
    useAchNoSavings:
      "Click here if you'd rather pay using a direct debit from your bank account",
  },
  platformTips: {
    title: "Platform Tips",
    callToAction: "Using Kinside is easy!\n\n Here's what you can do.",
    step1:
      "Manage your waitlist: When a new family joins your waitlist, their name will automatically be added to your waitlist.",
    step2:
      "Fill your open spots: Add and track open spots with Kinside. Once you add an open spot to your classroom, the next eligible family will be notified via email and text. And you'll get notified as well when the family has been contacted, or is ready to enroll!",
    onboard:
      "To get started, send us details about your classrooms and age groups. ",
  },
  positionDetails: {
    title: "Waitlist position details",
    family: {
      instructions:
        "This is your detailed position information for the {Center} {AgeGroup} waitlist. This information is provided to give you greater transparency into your position on the waitlist, however please note that <b>this information can change</b> if families ahead of you on the waitlist make changes to their preferences.<br><br>The likelihood to get care at this program is based on your position and the current capacity of this program for your child's age group.",
      noscore:
        "This is your detailed position information for the {Center} {AgeGroup} waitlist. This information is provided to give you greater transparency into your position on the waitlist, however please note that <b>this information can change</b> if families ahead of you on the waitlist make changes to their preferences.",
      absolute: "Overall position on the waitlist",
      sameMonth: "Among those looking within a month of {Date}",
      program: "Among those willing to enroll in a {Schedule} schedule",
      programSameMonth:
        "Among those willing to enroll in a {Schedule} schedule within a month of {Date}",
      status: {
        great: "You have a very good chance to get care at this program",
        good: "You have a decent chance to get care at this program",
        poor: "You have a lower likelihood to get care at this program",
      },
    },
    provider: {
      instructions:
        "This is the detailed waitlist information for this family. This information is provided to give greater transparency into a family's position on the waitlist, however please note that <b>this information can change</b> if families ahead of them make changes to their preferences.<br><br>The likelihood to get care is based on the family's position and your current capacity for {AgeGroup}.",
      noscore:
        "This is the detailed waitlist information for this family. This information is provided to give greater transparency into a family's position on the waitlist, however please note that <b>this information can change</b> if families ahead of them make changes to their preferences.",
      absolute: "Overall waitlist position",
      sameMonth: "Among those looking within a month of {Date}",
      program: "Among those willing to enroll in a {Schedule} schedule",
      programSameMonth:
        "Among those willing to enroll in a {Schedule} schedule within a month of {Date}",
      status: {
        great: "This family has a very good chance of getting care",
        good: "This family has a good chance of getting care",
        poor: "This family has a lower likelihood of getting care",
      },
    },
  },
  priceGrid: {
    agegroup: "Pricing for {AgeGroup}",
    buttonAddContract: "Add tuition rate",
    depositSample:
      "Registration with Kinside fees: {Deposit} ({Refundable} is refundable)",
    warnEmptyAgeGroup: "You have an age group that has no pricing options set",
    warnEmptyProgram: "You have a schedule with no pricing options set",
    warnNoName: "You have one or more pricing options with no name",
  },
  profileBanner: {
    addImageTitle: "Add Banner Image",
    confirmDelete: "Are you sure you want to delete this banner image?",
    subtitle:
      "Set either a wide image or three smaller images to show at the top of your Enrollment Page.",
    singleChoice: "Single Banner Image",
    multipleChoice: "Triple Banner Image",
    title: "Banner Image",
  },
  profileBasics: {
    buttonSave: "Save changes",
    editTitle: "Edit Program Features",
    subtitle:
      "Tell us what makes your program different. Families will be able to search for you by these features.",
    title: "Program Features",
    updateSuccess: "Basic Center Details were successfully updated",
    updateFailure: "There was a problem updating center details",
    noChoice: "Clear Selection",
  },
  profileCarousel: {
    addImageHelper:
      "Programs with photos see 3x as many tour requests as those without",
    addImageTitle: "Add a photo",
    addInstructions: "You can add up to 24 images to your profile page.",
    buttonAdd: "Done",
    confirmDelete: "Are you sure you want to delete this image?",
    subtitle: "Add up to 24 images to showcase your program",
    title: "Image Gallery",
  },
  profileSocial: {
    buttonSave: "Save changes",
    subtitle:
      "Enter links to your social media sites. These will be displayed at the bottom of your enrollment page",
    title: "Social Media Links",
    names: {
      facebook: "Facebook",
      instagram: "Instagram",
    },
  },
  profileStaff: {
    buttonAdd: "Add Employee",
    buttonUpdate: "Save changes",
    editInstructions:
      "Update details for this employee. If you need to delete or rearrange the order of your staff as they appear on the enrollment page, use the small buttons on the main view",
    labelFirstName: "First Name",
    labelLastName: "Last Name",
    labelTitle: "Title",
    labelDescription: "Description",
    title: "Our Staff",
    tooltipDelete: "Remove Employee",
    tooltipEdit: "Edit Employee",
    tooltipMoveUp: "Move Up in Order",
    tooltipMoveDown: "Move Down in Order",
  },
  programList: {
    day: "day",
    days: "days",
    buttonAdd: "Add Schedule",
    buttonDuplicate: "Duplicate Schedule",
    buttonEdit: "Edit schedule",
    buttonRemove: "Remove schedule",
    checked: "Applied",
    confirmDelete: "Are you sure you want to delete the {Name} schedule?",
    confirmMove:
      "Are you sure you want to move the {Contract} pricing option from {ProgramFrom} to {ProgramTo}?",
    contractTitle: "Pricing Options",
    errorAvailable: "Please select the appropriate number of days",
    find: "Find schedule",
    infoText:
      "All of your program's schedules and rate information should be on this page. To enter schedules, click on \"Add Schedule\" and enter the schedule's name, days of the week, and times. Click on the down arrow next to the schedule to add a pricing option for this schedule.",
    name: "Name",
    noPrograms:
      "No schedules have been set up for this provider. Click Add Schedule to create one",
    program: "Schedule",
    selectDaysSeat:
      "Select at least {Count} days of the week that this spot is available",
    selectDaysFamily:
      "Select at least {Count} days of the week that you prefer (select more than {Count} if you are flexible)",
    selectionRequired: "Please select at least one schedule",
    firstUxModalHeader: "Let’s set up your schedules",
    firstUxModalSubHeader:
      "This is where you’ll communicate to families the days and times when you offer care.",
    firstUxModalCta: "Get started",
    firstUxModalSecondaryCta: "I'll add schedules later",
    mySavedSchedules: "My saved schedules",
    noSchedulesAddedYet: "You haven't added any schedules yet.",
    scheduleName: "Schedule name",
    daysPerWeek: "Days per week",
    availableDays: "Available days",
    time: "Time",
  },
  providerCenters: {
    buttonAdd: "Add Program",
    title: "Programs",
    titleFindProvider: "Find Provider",
  },
  providerDetails: {
    buttonAdd: "Add Provider",
    buttonSave: "Save changes",
    fileUpload: "Uploaded files",
    managerName: "Provider Contact Name",
    name: "Name",
    businessName: "Business name",
    email: "Email",
    phoneNumber: "Phone number",
    privateInfo: "Program Manager",
    publicInfo: "Program Details",
    title: "Details for {Name}",
  },
  providerFiles: {
    // Edit dialog settings
    ageGroupInstructions:
      "If this file should only be sent to families in certain age groups, please select those age groups here",
    addFileTitle: "Upload document",
    updateFileTitle: "Edit provider file",
    buttonAdd: "Upload document",
    buttonChooseFile: "Upload file",
    labelDisplayName: "Display name",
    fileType: "Please indicate if this file is one of the following:",
    labelHandbook: "Program handbook",
    labelSafetyGuidelines: "Health & safety guidelines",
    noDisplayName: "Please enter a display name",
    uploadInstructions:
      "Accepted file types are Microsoft Word documents and PDFs.",
    urlInstructions: "Or add a URL",
    includeInstructions: "Choose where families are presented with this file:",
    includeInstructionsSubtitle: "(Check all that apply.)",
    include: {
      profilepage: "Profile page",
      offer: "Spot offers",
      enrollment: "After completing registration form",
      tour: "After booking a tour",
      waitlist: "After waitlist signup",
    },
    includeShort: {
      profilepage: "Enrollment Page",
      offer: "Spot offers",
      enrollment: "After Registration Form Completed",
      tour: "After Tour Scheduled",
      waitlist: "After Waitlist Signup",
    },
    // Tab settings
    buttonAddFile: "Add file",
    menuChangeAgeGroups: "Update Age groups",
    menuUpdate: "Update file",
    menuDelete: "Delete file",
    noFiles:
      "Want to share more documents with families from your Enrollment Page, after a tour, or after registration? Click \"Add File\"",
    // Logo strings
    buttonUploadLogo: "Upload Logo",
    buttonUpdateLogo: "Update Logo",
    noLogo: "No logo set",
    confirmDelete: "Are you sure you want to delete this file?",
  },
  providerList: {
    additionalProviders:
      "Find additional providers to add to this administrative unit",
    buttonAdd: "Add Provider",
    findProvider: "Find Provider",
    location: "Location",
    name: "Name",
    noMatch: "No matching providers were found",
    title: "Providers that are part of this Administrative Unit",
  },
  providerLogin: {
    button: "Generate login URL",
    instructions:
      "Enter the email address or phone number of the provider user that you want to log in as. This will not work for other user types. Heads up: you will be logged in as this user and can take actions on their behalf.",
    jwtInstructions:
      "The following link will allow you to login as {User} from {Name}:<p><div style=\"text-align: center; word-break: break-word\">{URL}</div></p>This URL will expire if not used within 10 minutes.<p>To login as this user, do the following:<p><ul><li>Copy the above URL to your clipboard</li><li>Logout of the Kinside Admin Tools</li><li>Paste the URL into your browser</li><li>Be sure to logout of the Enrollment Platform when you are done</li>",
    jwtParentInstructions:
      "The following link will allow you to login as {Name} ({User}):<p><div style=\"text-align: center; word-break: break-word\">{URL}</div></p>This URL will expire if not used within 10 minutes.<p>To login as this user, do the following:<p><ul><li>Copy the above URL to your clipboard</li><li>Logout of the Kinside Admin Tools</li><li>Paste the URL into your browser</li><li>Be sure to logout of the Family Tools when you are done</li>",
    title: "Login as a provider",
  },
  providerText: {
    buttonSave: "Save changes",
    canText: "Text me updates.",
    colEmail: "Email",
    colName: "User",
    colProvider: "Provider",
    colPhone: "Phone Number",
    colCanText: "Can Text?",
    dialogTitle: "Update text options for {User}",
    findLabel: "Find user",
    instructions:
      "Update the phone number and indicate whether you have the user's permission to send them text updates",
    none: "None",
    noResults: "No matching users found",
    phoneLabel: "Mobile phone",
    saveFail: "There was a problem updating this user's preferences",
    saveSuccess: "User preferences updated",
    title: "Provider Text Message Opt-In Status",
  },
  partnerSummary: {
    buttonChangeState: "Update",
    buttonSave: "Save changes",
    changeInstructions:
      "Update the onboarding state for {Name} once they are set up and ready to access the Kinside Enrollment Manager",
    changeTitle: "Change onboarding state",
    colProviderName: "Provider",
    colPartnerName: "Partner",
    colDate: "Date",
    partnerSubtitle: "Inform partner once provider onboarding done",
    partnerTitle: "Pending Partnerships",
    labelReason:
      "Please enter the reason the provider rejected onboarding with Kinside",
    labelState: "State:",
    states: {
      onboarding: "Onboarding in progress",
      onboarded: "Onboarding Complete",
      rejected: "Provider rejected onboarding",
    },
  },
  providerSummary: {
    allMarkets: "All Markets",
    noMarket: "No Market",
    noProviders: "There are no matching onboarded providers",
    providerSummary: "Summary of onboarded providers",
    colProviderName: "Provider",
    colProviderOpenSeats: "Spots not offered",
    colProviderFilledSeats: "Filled spots",
    colProviderParentAction: "Awaiting Parent Action",
    colProviderProviderAction: "Awaiting Provider Action",
  },
  qrCode: {
    scanHere: "Scan this QR Code",
    title: "QR Code",
  },
  registrationCode: {
    buttonSave: "Generate Code",
    instructions:
      "Enter configuration values for the registration code you are generating. This will generate a one-time use code the provider can use during the registration process that honors the configuration that you set here",
    labelLicense:
      "Is a license required? If not, the provider can skip the screen to do a license check without entering a license",
    labelNoPayment:
      "The provider receives commission-free waitlist signups and enrollments with no monthly subscription fee",
    labelSubscription:
      "What is the monthly subscription rate, if the provider elects to pay monthly?",
    result:
      "We have created a code to access the registration form. The case insensitive code is <b>{Code}</b>",
    subscriptionAmount: "Monthly subscription rate option",
    title: "Generate Registration Code",
  },
  reminderSummary: {
    buttonSave: "Change Reminder",
    changeTitle: "Change Reminder Time",
    dateAfternoon: "afternoon of {Date}",
    dateMorning: "morning of {Date}",
    labelDate: "New Reminder Time:",
    noReminder: "There are no matching upcoming spot reminders",
    reminderInstructions: "Click on a row to change a reminder",
    reminderSummary: "Upcoming spot reminders",
    colCenterName: "Program",
    colChild: "Child",
    colParent: "Parent",
    colState: "State",
    colReminder: "Next Reminder",
    state: {
      "seat offered": "spot offered",
      "parent reminded": "family reminded",
      "parent question": "family asked question",
      "schedule tour": "family requested tour",
      "ready to enroll": "family ready to enroll",
      "sent paperwork": "family sent paperwork",
      "question answered": "provider answered question",
      "tour scheduled": "tour scheduled",
    },
    summary:
      "{Name} will receive a reminder for their spot currently in a {State} state on the {Date}",
    summaryReminded:
      "{Name} will be removed from the waitlist for no response on the {Date}",
    summaryTour:
      "{Name} has a tour scheduled for their spot on {TourDate}. They will receive a reminder about this spot on the {Date}",
  },
  occupancyCard: {
    occupancy: "Occupancy",
    totalSeats: "Total spots",
    openSeats: "Open spots",
    openSeatsLink: "View open spots",
  },
  removeSeat: {
    buttonAdd: "Yes, edit spot",
    buttonRemove: "No, remove spot",
    confirmMessage: "Are you sure you want to remove this spot?",
    confirmTitle: "Are you sure?",
    labelReason: "Select the reason you need to remove this spot",
    reasons: [
      {
        id: "accident",
        label: "Accidentally added spot",
        tip: "Accidents happen! Help us improve Kinside by letting us know we can improve the spot management process to help avoid these mistakes",
        instructions: "Please provide feedback on spot management",
      },
      {
        id: "noneed",
        label: "No longer need this spot",
        tip: "If your classroom sizes have changed, you can update your classroom capacity on the Manage programs page",
        instructions: "Please let us know why this spot is no longer needed",
      },
      {
        id: "filledoutside",
        label: "Spot was filled outside of Kinside",
        tip: "Did you know you can offer a spot directly to a family if you need to fill it outside of our automated process? Click \"Pause spot\" if you'd like to stop Kinside from making offers directly, or \"Offer spot to family\" if you'd like to manually offer the spot.",
        instructions:
          "Please let us know why you filled the spot outside of the Kinside Enrollment Manager",
      },
      {
        id: "otherchild",
        label: "Filled by a child moving from a different classroom",
        tip: "Ok! Would you like to edit this spot and make it available in the classroom that the child is moving from?",
        instructions: "",
      },
      {
        id: "other",
        label: "Other",
        instructions: "Enter the reason you need to remove this spot",
      },
    ],
    title: "Removing a spot from {Classroom}",
    warning: "Please let us know why you need to remove a spot.",
    removeSeat: "Remove spot",
    pauseSeat: "Pause spot",
    holdSeat: "Manually offer spot to family",
    removeSeatAnyway: "Remove spot anyway",
  },
  requiredFields: {
    message: "Please fill out required fields in red",
    min_age: "Minimum age must be greater than 0",
    max_age: "Minimum age can not be greater than Maximum age",
    sameEmail:
      "Parents / guardians must have unique email addresses as each will receive update notifications. If you do not wish to receive multiple updates, please only enter one parent / guardian details",
    title: "Required Fields",
  },
  seats: {
    alreadyOffered:
      "This spot was already marked as offered to {Child}. If you offer it to another family, the original offer will be rescinded and they will be marked as active on the waitlist. The family will NOT be notified - it will be as if the offer hadn't been made in the first place. If this is not what you intended, please first take action with the initial offer before proceeding to offer this spot again",
    analysis: {
      legupMatch:
        "There are no families on your waitlist for this spot. However, there's a Kinside family nearby that we can invite to apply for your spot. Would you like us to contact this family?",
      legupMatchOverQuota:
        "There are no families on your waitlist for this spot. However, there's a Kinside family nearby that's a match. Since it looks like you've already used your {Count} direct offers to Kinside families this month, please reach out to Kinside Support if you're interested in inviting this family to apply.",
      open: "Thank you for posting your spot to Kinside. You have a child on your waitlist, {Child}, who is a match for this spot. We will automatically send an offer to this family after 10 minutes - in case you need to update this spot or want to hold it for a different family.",
      nofamilies:
        "Thank you for posting your spot to Kinside. At this time, there are no families on your waitlist that are eligible to fill this spot. Our system will continue to monitor your waitlist and make an offer if a family joins who could take this spot.",
      template:
        "There are no families on your waitlist that we can offer this spot to. However, there are families who we can offer this spot to if you were to change {Changes}. Would you like to make these changes to this spot?",
      available: "the available date to {Date}",
      gender: "the gender requirement",
      minage: "the minimum age to {Age} months",
      minageWeeks: "the minimum age to {Age} weeks",
      maxage: "the maximum age to {Age} months",
      subsidy: "the subsidy requirements",
    },
    available: "(Available {Date})",
    buttonExportEnrolled: "Export Enrollments",
    buttonHoldSeat: "Hold spot",
    buttonAddSeat: "Add spot",
    buttonEditSeat: "Edit spot",
    buttonFillCenter: "Fill spot",
    buttonFillLegup: "Fill from Kinside waitlist",
    buttonRemoveSeat: "Remove spot",
    buttonShowHistory: "See spot history",
    buttonShowOpened: "View open spots",
    buttonShowFilled: "View enrolled families",
    buttonDownloadCSV: "Download registration fees",
    changingContractPickOne:
      "This spot was offered to a family with a pricing option you removed. To continue, please select only one pricing option which will be sent to the family",
    changingSelectedContract:
      "This spot was offered to a family with a different pricing option. If you change the pricing option, we will send new paperwork to the family with this option. Please confirm that you would like to continue",
    exportTitle: "Export Enrollments",
    exportELV:
      "Note - if you are using Kinside with Early Learning Ventures CORE CCMS, enrollment information should automatically be populated. If you are not seeing enrollment information in CORE, please contact Kinside Support for assistance",
    exportInstructions:
      "Choose the Child Care Management System (CCMS) you would like to import into. We will export your {Count} enrollments into a compatible CSV file. If your CCMS is not listed below, choose Kinside default to get a list you can hand enter",
    exportTypes: ["Curacubby", "Jackrabbit", "SchoolAdmin", "Kinside default"],
    firstLine: "{center}. Open spots: {count}",
    secondLine: "Capacity (spots): Licensed {normal} / Budgeted {budgeted}",
    classroomTitle: "{Classroom} | Budgeted {budgeted}",
    colExport: "Export?",
    confirmShowAll:
      "This will show ALL families for this program, regardless of whether they meet the age, schedule, or preferred start date requirements of this spot. By continuing, you will be able to offer this spot to any child on the waitlist but YOU MUST DOUBLE CHECK YOURSELF that it's an appropriate match. Is this what you want to do?",
    fillSeatTitle: "Select a set of criteria to use in filling the spot",
    filterDncb:
      "Filter the list of families based on Do Not Contact Before setting",
    filterNoFilter: "Remove all filters and show all waitlist families",
    filterPreferences:
      "Filter the list of families based on priority spot preferences",
    filterProgram: "Filter the list of families based on schedule match",
    labelFilterState: "Filter by status",
    labelFilterCenter: "Filter by location",
    legupMarket: "Kinside List",
    legupTitle:
      "Select a child from the {LegUp} list to fill for this spot {Available}",
    addSeatNoMatchTitle: "No Families Found",
    addSeatNearHitTitle: "Close Match",
    addSeatMatchTitle: "We Found a Match!",
    noClassrooms:
      "You must set up your age groups & classrooms before you can post a spot with Kinside.",
    noClassroomsLink: "Add age groups and classrooms",
    noSeatTitle: "Spot not found",
    noSeatText:
      "Sorry, the spot you're trying to access can't be found. Please try again with a different spot or contact support@kinside.com if you believe you reached this message in error",
    promptAddSeat: "Would you like to add a new spot now?",
    selectChild: "Select a child",
    selectLegupTitle: "Select a Kinside List",
    showAllCenters: "Show All Locations",
    showSeats: {
      "Show All Seats": [],
      "Open Seats": [
        "nofamilies",
        "open",
        "parent declined",
        "parent passed",
        "no response",
      ],
      "Pending Offer Response": ["seat offered", "parent reminded"],
      "Family Asked Question": ["parent question", "question answered"],
      "Tours Scheduled": ["schedule tour", "tour scheduled"],
      "Ready for Enrollment": ["ready to enroll", "sent paperwork"],
    },
    subTitle: "Notes from Provider: {Notes}",
    title: "Classrooms at {Name}",
    titleFilled: "Filled spots",
    titleFill:
      "Select a child for this spot from {Center}'s {Name} classroom {Available}",
    titleFillOffered:
      "This spot is currently offered to {Child}. Select a different child if you need to fill this spot from {Center}'s {Name} classroom with someone else {Available}",
    titleFindFilledCenter: "Select a Program",
    titleFindProvider: "Select a Provider",
    titleProvider: "Classrooms",
  },
  seatCard: {
    available: "Available:",
    availableNow: "Available Now",
    age: "Age:",
    ages: "{min} - {max} months",
    ageWeeks: "{min} weeks - {max} months",
    criteria: "Looking for a {gender} who {criteria}",
    female: "girl",
    genderAny: "child",
    genderOnly: "Looking for a {gender}",
    legupFamily:
      "This spot was offered to a Kinside family. Contact information will be shared with you if they are interested in this spot.",
    legupTitle: "Spot offered",
    legupDescription:
      "This spot was offered to {Parent} from the Kinside waitlist",
    male: "boy",
    notReached: "is not {stage}",
    offerLink: "Offered on {Date}",
    paused: "Kinside is not sending offers for this spot",
    pauseWarning:
      "Are you sure you want to pause this spot? While paused, Kinside will not make automated offers and the spot will not show on your Enrollment Page or on the directory for Kinside families to apply for.",
    program: "Schedule: {Program}",
    qrInstructions: "Scan this QR Code to hold this spot from {Center}",
    reached: "is {stage}",
    stateTooltip: {
      nofamilies:
        "This spot is open for offers. There are no families on the provider's waitlist to offer this spot to",
      open: "We will search the provider's waitlist for a family that is a match for this spot",
      "parent declined": "This spot is open",
      "parent passed": "This spot is open",
      "no response": "This spot is open",
      "seat offered": "This spot was offered to a family - click for details",
      "parent reminded":
        "This spot was offered to a family - click for details",
      "parent question":
        "The spot was offered to a family, and they have a question",
      "question answered":
        "The provider indicated that they answered the family's question about this spot",
      "schedule tour":
        "The spot was offered to a family and they are ready to tour",
      "ready to enroll":
        "The spot was offered to a family and they are ready to enroll",
      "seat removed": "This spot was removed by the provider",
      "sent paperwork":
        "The provider has approved enrollment and the Registration Form has been sent to the family",
      "tour scheduled": "The provider has scheduled a tour for the family",
      enrolled: "A family is enrolled in this spot",
    },
    providerStateTooltip: {
      nofamilies:
        "This spot is open for offers. There are no families on your waitlist that are a match for this spot",
      open: "We will search your waitlist for a family that is a match for this spot",
      "parent declined":
        "We will search your waitlist for a family that is a match for this spot",
      "parent passed":
        "We will search your waitlist for a family that is a match for this spot",
      "no response":
        "We will search your waitlist for a family that is a match for this spot",
      "seat offered": "This spot was offered to a family - click for details",
      "parent reminded":
        "This spot was offered to a family - click for details",
      "parent question":
        "The spot was offered to a family, and they have a question for you",
      "question answered":
        "You indicated that you've answered the family's question about this spot",
      "schedule tour":
        "The spot was offered to a family and they have requested a tour from you",
      "ready to enroll":
        "The spot was offered to a family and they are ready to enroll",
      "seat removed": "You removed this spot",
      "sent paperwork":
        "You approved enrollment, and the Registration Form has been sent to the family",
      "tour scheduled": "You have scheduled a tour for the family",
      enrolled: "A family is enrolled in this spot",
    },
    states: {
      nofamilies: "Open",
      open: "Searching Your Waitlist",
      "parent declined": "Searching Your Waitlist",
      "parent passed": "Searching Your Waitlist",
      "no response": "Searching Your Waitlist",
      "seat offered": "Offered {Date}",
      "parent reminded": "Offered {Date} (parent reminder sent)",
      "parent question": "Family sent question {Date}",
      "question answered": "Family Question Answered {Date}",
      "schedule tour": "Tour Request Sent on {Date}",
      "ready to enroll": "Enrollment Request Sent on {Date}",
      "seat removed": "Spot removed {Date}",
      "sent paperwork": "Enrollment Approved on {Date}",
      "tour scheduled": "Tour Scheduled for {Date}",
      enrolled: "Family Enrolled on {Date}",
    },
    pillStates: {
      nofamilies: "Open",
      open: "Searching Your Waitlist",
      "parent declined": "Searching Your Waitlist",
      "parent passed": "Searching Your Waitlist",
      "no response": "Searching Your Waitlist",
      "seat offered": "Offered: Pending Response",
      "parent reminded": "Offered (Parent Reminder Sent)",
      "parent question": "Family sent question",
      "question answered": "Family Question Answered",
      "schedule tour": "Tour Request Sent",
      "ready to enroll": "Enrollment Request Sent",
      "seat removed": "Spot Removed",
      "sent paperwork": "Enrollment Approved",
      "tour scheduled": "Tour Scheduled",
      enrolled: "Family Enrolled",
      pausedUntil: "Paused Until {Date}",
    },
    title: "Name: {seat}",
    titleNoName: "Open spot",
    menuItemDetails: "Spot details",
    menuItemCopy: "Copy spot",
    menuItemDelete: "Remove spot",
    menuItemFill: "Mark spot as filled",
    menuItemHistory: "Show spot history",
    menuItemPause: "Pause offers for spot",
    menuItemUnpause: "Start sending offers",
    menuItemWaitlist: "View waitlist",
    menuItemQrCode: "Generate QR Code",
    menuItemSplit: "Split spot",
    status: "Status:",
    open: "Open",
    offered: "Offered to",
    holdSeat: "Manually offer spot to family",
    waitlistDetails: "Waitlist details",
    viewCloseMatches: "View close matches for this spot",
  },
  seatNearHits: {
    title: "{Name} Close Matches",
    nearHitReasons: {
      closePreferred: "has a close preferred start date",
      closeDNCB: "has a do not contact before within a week of available date",
      closeGender: "doesn't match the spot's gender preference",
      dropSubsidy: "doesn't match the spot's subsidy requirements",
      closeAge: "has a close age",
      closeProgram:
        "wants a schedule with the right number of days but not the same days of week",
    },
  },
  seatHistory: {
    noHistory: "This spot has not yet been processed by Kinside",
    oldSeat:
      "Note that spots posted before July 2020 may have some gaps in the spot history",
    states: {
      "sent paperwork": "Registration Form was sent to the family",
      "parent question": "The family had a question about the spot offer",
      "seat offered": "The spot was offered to {Child}",
      "tour scheduled": "You scheduled an enrollment tour",
      "no response": "The family did not respond to Kinside",
      "ready to enroll": "The family indicated they are ready to enroll",
      "question answered": "You answered the family's question",
      "parent declined":
        "The family passed on the offer and removed themselves from your waitlist",
      "schedule tour": "The family requested a tour",
      open: "The spot was posted to Kinside",
      "parent passed":
        "The family passed on the offer but stayed on your waitlist",
      nofamilies:
        "Kinside was unable to find a family on your waitlist to offer this spot",
      enrolled: "{Child} enrolled in your program!",
    },
    stateTitles: {
      "sent paperwork": "Your Action",
      "parent question": "Family Response",
      "seat offered": "Spot Offer",
      "tour scheduled": "Your Action",
      "no response": "Family Response",
      "ready to enroll": "Family Response",
      "question answered": "Your Action",
      "parent declined": "Family Response",
      "schedule tour": "Family Response",
      open: "Spot Posted",
      "parent passed": "Family Response",
      nofamilies: "Spot Open",
      enrolled: "Family Response",
    },
    title: "{Name} History",
  },
  seatList: {
    buttonHold: "Learn More",
    buttonSelectChild: "Select Child",
    noSeats: "There are no openings in your area at this time",
    noSeatsFilter:
      "There are no openings that match your search. Try checking \"Programs\" to find a waitlist you can join",
    seatsFound: "{Count} spots found",
    selectChild: "Which child is this spot for?",
    selectChildTitle: "Select a child for this spot",
  },
  seatStateUpdate: {
    noChildName: "your child",
    providerNotified: "The provider has been notified",
    update:
      " The spot {Name} from {Center}'s {Classroom} class moved from \"{OldState}\" to \"{NewState}\"\n",
    updateNoSeat: " Spot ID {ID} was updated to \"{NewState}\"\n",
  },
  searchCriteria: {
    anyChildInputLabel: "All",
    autocompleteLabel: "Search By Address",
    buttonChange: "Filters",
    buttonHideMap: "Hide Map",
    buttonShowMap: "Show Map",
    checkboxLabel: "Save address to profile as your home address",
    childInputLabel: "Select Child",
    dateMenuItem: "Sort by available date",
    preferredDateLabel: "Preferred Enrollment",
    legupCenters: "Allows Online Waitlist Sign Up",
    locationMenuItem: "Sort by distance",
    noPreferredDate: "Show all enrollment dates",
    priceMenuItem: "Sort by price",
  },
  selectTour: {
    selectDate: "Select a date to see available tour times",
    tip: "This is a {Duration} minute tour",
    tipVirtual: "This is an online tour lasting {Duration} minutes",
    tipInPerson: "This is an in-person tour lasting {Duration} minutes",
  },
  signup: {
    buttonPrograms: "Next: Select Preferred Schedules",
    buttonPartnerSignup: "Complete sign up",
    buttonPayment: "Next: Pay Waitlist Fee",
    buttonPay: "Submit {Total} payment",
    buttonNoPayment: "Sign up for Waitlist",
    buttonNoPaymentApplication: "Submit Application",
    confirmation:
      "Thanks for signing up for this waitlist. You can visit {Url} to signup for a Kinside account and track your waitlist status at any time.",
    enterPayment: "Enter payment details",
    error:
      "There was a problem processing your sign up. Please contact Kinside Support at support@kinside.com if the problem persists",
    footer:
      "By joining the waitlist, you agree to receive emails from {Name} and Kinside, who will contact you with waitlist updates and when a spot becomes available.",
    footerApplication:
      "By submitting an application, you agree to receive emails from {Name} and Kinside, who will contact you with updates to your application status.",
    footerPartner:
      "By signing up to access child care openings, you agree to receive emails from {Name} and Kinside, who will contact you with updates on openings in your area.",
    header:
      "<p style=\"text-align: center; font-size: 28px\"><b>Join the waitlist</b></p><p><p style=\"text-align: center; font-size: 24px\">Thank you for your interest in {Name}!</p><p>",
    headerPartner:
      "<p style=\"text-align: center; font-size: 28px\"><b>Signup to Access Child Care Openings</b></p><p><p style=\"text-align: center; font-size: 24px\">Thank you for your interest in {Name}! Fill out the information below to join the waitlist. Once completed, you'll have access to track your waitlist status and update your enrollment preferences at any time.</p><p>",
    invalid:
      "Oops. This is an invalid link. Please contact Kinside support if you feel you reached this page in error",
    metaDescription:
      "{Name} is a child care program in {City}. Join the waitlist today!",
    metaDescriptionPartner:
      "{Name} is a child care shared service organization in {City}.",
    metaTitle: "{Name} Waitlist Signup",
    navigatorWelcome: "Welcome {Navigator}!",
    noAction:
      "Thanks. Please return to your email and select a different link to continue.",
    noPaymentWithCoupon:
      "The discount code you entered covers the signup fee. Please click the button below to complete your signup",
    notInGeo:
      "We're sorry, your address is not in the territory covered by {Name}",
    notInGeoDefinition:
      "We're sorry, in order to sign up for this program you must have an address in {Value}",
    poweredBy: "Powered by",
    sectionCenterQuestions: "Program-specific Questions",
    sectionPartnerQuestions: "Additional Questions",
    sectionProgram: "Select Schedules",
    selectCenter: "Select the Program you are interested in applying to:",
    subtitleCenterQuestions:
      "Please answer the following questions specific to {Name}",
    subtitleProgram:
      "Select the schedules you are interested in for {Name}. You may select multiple options if you are flexible in your choices",
    titleConfirm: "Thanks for signing up with {Name}",
    waitlistPayment:
      "Please fill out this form to pay your waitlist signup fee for {Child} at {Center}. Failure to pay will result in you being removed from their waitlist.",
  },
  splitSeat: {
    additionalSchedule: "Add another spot",
    buttonSplit: "Split spot",
    existingSeatTitle: "Current spot",
    familyWithChildName: "{Parent} (child {Child})",
    familyNoChildName: "{Parent}",
    noFamilyName: "a family",
    firstSeatOffered:
      "This seat has been offered to {Family}. They will be notified of the updated schedule and price",
    subtitleSeat: "Spot #{Seat}",
    subtitleDone: "Done",
    title: "Split {Seat} spot",
    warningMoreDays:
      "The new spots cover more days than the original spot did. Is this what you wanted to do?",
    warningLessDays:
      "The new spots cover fewer days than the original spot did, leaving a hole. Is this what you wanted to do?",
    warningDifferentDays:
      "The new spots cover different days of the week than the original spot did. Is this what you wanted to do?",
    splittingSpot: "Splitting spot...",
  },
  standardQuestions: [
    { question: "Preferred Enroll Date", required: true },
    { question: "Additional Comments", required: false },
    { question: "Referred By", required: false },
    { question: "Parent / Guardian Name (First/Last)", required: true },
    { question: "Parent / Guardian Email", required: true },
    { question: "Parent / Guardian Phone Number", required: false },
    { question: "Parent / Guardian Address", required: false },
    { question: "Parent / Guardian Company", required: false },
    { question: "Parent / Guardian #2 Name (First/Last)", required: false },
    { question: "Parent / Guardian #2 Email", required: false },
    { question: "Parent / Guardian #2 Phone Number", required: false },
    { question: "Parent / Guardian #2 Address", required: false },
    { question: "Parent / Guardian #2 Company", required: false },
    { question: "Child Birthdate / Expected Birthdate", required: true },
    { question: "Child Gender", required: true },
    {
      question: "Child Name (First/Last - First can be empty)",
      required: true,
    },
    {
      question: "Child Address (If Different From Parent / Guardian)",
      required: false,
    },
    { question: "Child Development Stage ({Stage})", required: false },
    { question: "Preferred Schedule", required: false },
    {
      question:
        "Will you have subsidized care (at this program's subsidy programs)?",
      required: false,
    },
  ],
  stuckPayments: {
    actionUnmapped:
      "This payment has a recommended action of {Action} ... but I don't know what that means. Please contact engineering to address.",
    actionUnknown:
      "We can't figure out how this payment is stuck - please contact engineering to dig through the DB",
    actionDuplicate:
      "This payment appears to be a pure duplicate. Would you like to try to delete it from our records?",
    actionOnWaitlist:
      "This family appears to already be on the waitlist represented by this payment. Would you like to try to delete this payment from our records?",
    actionNotOnWaitlist:
      "This is a new no-cost waitlist signup. Would you like us to play this payment through?",
    actionPaidNotOnWaitlist:
      "This is a paid waitlist signup that didn't fully process. Please contact engineering to coordinate playing this payment through Stripe",
    colId: "ID",
    colType: "Type",
    colAmount: "Total",
    colDate: "Paid Date",
    colCenter: "Program",
    colAddress: "Address",
    colParent: "Parent",
    colEmail: "Email",
    colChild: "Child",
    noStuckPayments: "Congratulations - there are no stuck payments!",
    title: "Stuck Payments",
    updateSuccess: "The payment has been updated",
    updateFailure:
      "There was an error updating this payment. Please contact engineering",
  },
  subsidies: {
    addInstructions:
      "Add any subsidies that you accept here. Families will be able to indicate whether they are eligible for a subsidy when they join your waitlist.",
    help: "How does Kinside decide which subsidies to show me? Kinside shows you a list of subsidies available to programs in your city, state, and county.",
    addInstructionsAdmin:
      "Add any subsidies that the provider accepts here. Families will be able to indicate that they are eligible for a subsidy when they join the provider’s waitlist.",
    buttonAdd: "Add program",
    linkAdd: "Add subsidy program",
    chooseSubsidy: "Select a subsidy program",
    noSubsidies: "There are no subsidies registered with this program",
    subsidyLabel: "Will you being paying for child care with a subsidy?",
    title: "Subsidy Programs Accepted",
    titleAdd: "Add subsidy for {Center}",
    noSubsidiesAvailable:
      "There are no subsidies offered to families in your area.",
    noMoreSubsidiesAvailable:
      "There are no more subsidies offered to families in your area.",
  },
  subsidyTool: {
    buttonAdd: "Add subsidy",
    colCode: "Code",
    colName: "Name",
    colRestriction: "Available For",
    colUrl: "URL",
    saveSuccess: "Successfully saved subsidy",
    saveFailure: "There was a problem saving the subsidy",
    title: "Available Subsidies",
  },
  setupWizard: {
    ageGroupCentersQuestion:
      "Are all your age groups the same for all programs?",
    ageGroupLabel: "Now we'll set up age groups and classrooms.",
    ageGroupExplanation:
      "Now that we have your schedules and tuition, let's add your age groups and classrooms and connect them to the right schedule and classroom. Click \"Add age group\" to get started.",
    buttonAddAgeGroup: "Add age group",
    buttonAddNewSchedule: "Add Schedule",
    buttonAddFile: "ADD FILE",
    buttonAddPricing: "Add Tuition",
    buttonDashboard: "See My Dashboard",
    buttonRemoveFile: "REMOVE FILE",
    buttonSendDetails: "Submit the URL",
    buttonSendDepositDetails: "Send your registration details",
    buttonUploadOne: "Upload file",
    buttonUploadMultiple: "Upload files",
    buttonUploadWaitlist: "Upload Waitlist",
    buttonWaitlistFee: "Submit Waitlist fee",
    buttonWelcome: "Get Started",
    colCenter: "Program",
    colNoList: "No Waitlist",
    colUpload: "Upload",
    conciergeGetStarted: "Get Started Now",
    conciergeWelcome: "Welcome to your Centralized Enrollment Platform",
    currentSchedule: "Current Schedule",
    currentScheduleTooltip:
      "Use the arrows below to cycle through each of your schedules and click the \"Add Tuition\" button to add a new tuition for the selected schedule.",
    dropzoneTitle: "Drag and drop or click here to upload files",
    steps: [
      "Upload Handbook",
      "Add Tuition",
      "Import waitlists",
      "Submit Registration Form",
      "Set up Stripe",
    ],
    depositForm:
      "Submit your Registration Form and any enrollment paperwork you want sent to a family.",
    depositFormTitle: "Add your registration fee details",
    depositFormTitleRepeat: "Thank you for submitting your Registration Form",
    depositFormRepeat:
      "If you would like to update this information, please give us your new details below.",
    depositFormText:
      "Please note, Kinside adds a 25% or $25 non-refundable registration fee to the family's total to cover our costs, including credit card processing fees.",
    getStarted:
      "That's it!<br/><p/>Thanks for submitting your information! We know you're busy, so our team is going to complete your account setup for you. We will be in touch soon when it's complete. Please reach out to your Enrollment Support Team at <a href=\"mailto:support@kinside.com\">support@kinside.com</a> if you have any questions.<br/><p/>Thank you, <br/><b>The Kinside Team</b>",
    getStartedExplanation:
      "Thanks for adding your information. Your Enrollment Support team will finish up set up with you now to make onboarding even easier. Next, feel free to explore your Enrollment Platform and customize your Enrollment Page to make it your own.",
    getStartedMarketplace:
      "Your spot is live!<br/><p/>To view your spot, click on \"Search\" in the left-hand menu",
    getStartedTitle: "That's it!",
    handbookTitle: "Upload your Program Handbook",
    handbookTitleRepeat: "Thanks for uploading your handbook",
    handbookUpdate:
      "Need to upload changes to your handbook? You can do so here, or at any time by visiting your Manage programs page",
    handbookUpload:
      "This will reduce the amount of questions asked by families, and will be visible to families interested in your program and on your waitlist.",
    importListFeeTitle: "Enter your waitlist fee",
    importListFeeText:
      "Kinside adds a $15 waitlist fee to help cover our costs. You incur no credit card processing fees, and will receive the full amount above for every waitlist signup",
    importListTitle: "Upload your waitlist details",
    importListLabel: "Enter waitlist form URL",
    importList:
      "Please submit your waitlist form and your existing waitlists below!",
    importListTitleRepeat: "Thanks for submitting your waitlist details",
    importListRepeat:
      "If you would like to update those details, you can submit a new waitlist form and existing waitlists below.",
    importDone:
      "Thank you for setting up your waitlists. You are now ready to start using Kinside to post your open seats and view your current program waitlists. Click Next for tips on using our platform",
    importDoneTitle: "Thanks for setting up your waitlists",
    priceListTitle: "Add Your Classroom and Tuition Details",
    priceList:
      "How many classrooms do you have? What registration fee do you charge to join your program? Please upload your tuition and classroom details below!",
    priceListTitleRepeat: "Thanks for telling us about your classrooms",
    priceListRepeat:
      "If you would like to update this information, please upload your price list and classroom details below!",
    priceListLabel: "Enter tuition and classroom URL or details",
    priceListText:
      "Have this information on your website? Share the page url and we'll import this information for you!",
    pricingLabel: "Next, let's set up tuition.",
    pricingExplanation:
      "Now that we have your schedules, we need to know what tuition you charge for each schedule. Use the arrows below to cycle through each of your schedules and click the \"Add Tuition\" button to add your tuition rate for the selected schedule.",
    programScheduleLabel: "Let's start with your schedules.",
    programScheduleExplanation:
      "Do you offer full-time or part-time? 3-day schedules or 2-day schedules? Add all of your schedules here. On the next pages, we'll add your tuition rate and attach these schedules and tuition to specific age groups and classrooms.",
    stripeExplanation:
      "Kinside helps you easily collect enrollment fees and waitlist fees (if applicable) online. And we take care of credit card fees for you! In order to send fees collected from families to your bank account, we use a secure third-party processing platform called Stripe. First we need you to set up an account or connect your existing account.  Please click the button below to get started. It takes less than 3 minutes!",
    stripeOnboard:
      "All payments are collected from families and deposited into your bank account, minus Kinside's fees. You will incur no credit card fees. We use <a href=\"https://stripe.com/\" target=\"_blank\">Stripe</a> as our 3rd party payment processor.",
    stripeOnboardSteps: [
      "Stripe is a trusted solution to accept online payments.",
      "Fees are transfered 2-3 business days after a family pays.",
    ],
    stripeOnboardMarketplace:
      "Take two minutes to securely add your bank account information to post your seat. We use <a href=\"https://stripe.com/\" target=\"_blank\">Stripe</a>, one of the most secure payment processors, for payments. A quick remind -- you will receive your full fee, and won't be charged any credit card fees.",
    stripeTitle: "Last, let's get you paid.",
    stripeSetup:
      "Thank you for setting up your Stripe account with Kinside. You will now be able to receive waitlist and enrollment fees from families through <a href=\"https://stripe.com/\" target=\"_blank\">Stripe</a>, our third-party payment processor. Please click Next to continue to the next step.",
    subtitles: [
      "Welcome",
      "Add Schedules",
      "Tuition",
      "Add age groups",
      "Upload Program Documents",
      "Setup Stripe account to get paid",
      "Summary",
    ],
    title: "Get Started with Kinside",
    uploadLink: "Upload",
    uploadLabel: "Great! Let's talk about your program's documents.",
    uploadExplanation:
      "From your Handbook to your Enrollment Packet, we make it easy for you to send families paperwork at the right time. Click the checkbox below to share what document you want to upload, and then upload them on the right hand side. We accept .pdf files for documents, .png or .jpeg for images, and .csv or .xlsx for spreadsheets.",
    welcome: "Welcome to your Enrollment Platform. With Kinside:",
    welcomeExplanation:
      "Hey there! Welcome to your centralized Enrollment Platform. We've done some set up for you, but to finish please take a few minutes to add your classroom schedules, tuition and fees, age groups, and link your bank account so we can pay you.",
    welcomeTitle: "Welcome!",
    welcomeSteps: [
      "You can add and track your classroom spots",
      "Your waitlist is managed for you, with automatic updates to families",
      "Your open spots are shared with families across the city when you don't have anyone on your waitlist to fill them",
      "You can send spot offers and collect registration fees in minutes",
    ],
  },
  shareCard: {
    button: "Share Kinside",
    buttonChoose: {
      facebook: "Facebook",
      email: "Email",
    },
    buttonFacebook: {
      share: "Share on Facebook",
      send: "Send message to friends",
    },
    mailSubject: "Find child care when you need it",
    quote:
      "I finally found a service that relieves the burden of searching for child care. You can search for programs, find open spots, and schedule tours when it's convenient for you. Check it out!\n\nhttps://www.kinside.com",
    subtitle:
      "Help your community find child care faster by sharing the good news about Kinside with your friends and colleagues.",
    title: "Spread the Love",
    titleFacebook: "How would you like to share Kinside on Facebook?",
    titleChoose: "Where would you like to share Kinside?",
  },
  submit: "Submit",
  survey: {
    nonPerfect: "Please let us know why you left less than a 5-star rating",
  },
  tabs: {
    action: "Action center",
    adminunits: "Admin units",
    businessDetails: "Program",
    contracts: "Tuition",
    dashboard: "Home",
    enrollments: "Enrollments",
    families: "All families",
    adminFamilies: "LegUp Families",
    newFamilies: "All families",
    familyDetails: "My family",
    familyHelp: "Contact us",
    feedback: "Feedback",
    fill: "Spots",
    help: "FAQs",
    info: "My programs",
    legup: "Kinside waitlist",
    onboard: "Onboard program",
    openSeats: "Search",
    tools: "Admin actions",
    tours: "Tours",
    userProfile: "User profile",
    kinsideAdmin: "Kinside admin",
    waitlists: "Waitlist",
    classrooms: "Classrooms",
    profile: "Public profile",
    profileManage: "Manage profiles",
    businessInfo: "Business",
    provMessages: "Messages",
  },
  tourActivity: {
    colCenter: "Program",
    colTime: "Tour Time",
    colStatus: "Status",
    instructions: "Click below to edit or schedule a tour",
    statusNoShow: "No Show",
    statusScheduled: "Scheduled",
    statusRequested: "Requested",
    statusCanceled: "Canceled",
    requestTitle: "The family would like to tour {Dates}",
    title: "Tour Activity",
  },
  tourCard: {
    subtitleNoTours:
      "You have no tours scheduled this week. Visit the <b>Manage Tours</b> page to add tours",
    subtitleTours:
      "Visit the <b>Manage Tours</b> page to see this week's tours",
    tours: "Tours This Week",
    tourLink: "View Tours",
  },
  tourChangeState: {
    buttonSave: "Save changes",
    instructions:
      "Please indicate whether the tour was completed or canceled so we can update your records",
    state: {
      "no change": "The tour has not happened and is still scheduled",
      completed: "The family completed the tour",
      cancel: "The tour was canceled",
    },
    title: "Update Tour",
  },
  tourContact: {
    age: "Age: ",
    buttonClose: "Close",
    emailLabel: "Email: ",
    parentLabel: "Parent: ",
    phoneLabelHome: "Home Phone: ",
    phoneLabelWork: "Work Phone: ",
    phoneLabelCell: "Cell Phone: ",
    request: "Family requested a tour {Date}",
    title: "Tour Family details",
    tourLabel: "Tour date: ",
  },
  tourDetails: {
    buttonConfirmUpdate: "Confirm Update",
    buttonRemove: "Cancel tour",
    buttonUpdate: "Reschedule tour",
    date: "Date: {Date}",
    length: "Length: {Length} minutes",
    newTourTitle: "Select a new tour time",
    oldTourTime: "Your tour is currently scheduled for {Date}",
    removeInstructions:
      "If you need to cancel this tour, please let {Center} know why",
    removeNotes: "Cancellation notes",
    sectionDetails: "Tour Details",
    sectionUpdate: "Change Tour",
    title: "Tour for {Center}",
    updateInstructions:
      "If you need to change this tour, please let {Center} know why and when you would like to reschedule.",
    updateInstructionsNoAvailability:
      "{Center} does not have any tour availability for the next 30 days. If you need to change this tour, please enter details of when you would like to reschedule and {Center} will let you know if they can accommodate you.",
    updateInstructionsPickTime:
      "If you need to reschedule, click the Reschedule tour button to pick a new date and time.",
    updateNotes: "Rescheduling notes",
    virtualTour: "This is an online tour",
    virtualTourInstructions:
      "{Center} has provided these instructions to join this tour",
    virtualTourNoInstructions:
      "{Center} has not yet provided instructions to join this tour. Please reach out to them directly for joining instructions",
  },
  tourList: {
    buttonChange: "Change Tour date",
    colCenter: "Program",
    colSeat: "Spot",
    colChild: "Child",
    colParent: "Parent / Guardian",
    colTime: "Tour Time",
    colType: "Tour Type",
    colAction: "Tour Actions",
    colChange: "Change",
    inPersonTour: "In Person Tour",
    linkManage: "Manage Tour",
    missingVirtualNotes:
      "This is a virtual tour but no video conference details have been set",
    noTours: "You have no tours scheduled",
    virtualTour: "Virtual Tour",
  },
  tourRequest: {
    buttonSendMail: "Send email",
    colChild: "Child",
    colParent: "Parent / Guardian",
    colDate: "Preferred Enrollment",
    colStatus: "Status",
    colTimes: "Requested Times",
    editTourTitle: "Add Tour",
    mail: "Message text",
    requested: "Requested: {Date}",
    sendMailInstructions:
      "Please enter your response to the family below, explaining the reason for declining the tour request.",
    sendMailTitle: "Send Family Email",
    statusSelect: {
      "tour scheduled": "Schedule a tour",
      "send email": "Send email response (do not schedule tour)",
      "tour request": "Family is requesting a tour",
    },
    noRequestSubtitle: "You currently have no outstanding tour requests",
    statusSubTitle:
      "These families have requested a tour through your Enrollment Page. They do not have a spot on hold. You should either schedule a tour or reply as to why a tour won't be scheduled at this time.",
    statusTitle: "Outstanding Tour Requests",
    tourInstructions: "The family has requested to tour {Dates}",
  },
  tours: {
    addInstructions: "Or add a new family by entering details below.",
    buttonAdd: "Add tour",
    downloadCSVButton: "Download families with tours this week",
    scheduledTours: "Scheduled Tours",
    scheduledTourInstructions:
      "Here is a list of your scheduled tours. You can change the date or time of your tour by clicking <b>{Button}</b>.",
    titleFindCenter: "Select a Program",
    titleTours: "Tours for {Name}",
    upcomingTours: "Upcoming tours",
    tourSettings: "Tour settings",
    allScheduledTours: "All Scheduled Tours",
    download: "Download",
    filterBy: "Filter by:",
    noTourScheduled: "No Tours Scheduled",
    editTour: "Edit tour",
    notOnWaitlist: "NOT on waitlist",
    onWaitlist: "On waitlist",
    pill: "Seat on hold",
    useFamily: "Family",
    today: "Today",
    none: "none",
    generalTours: "General tours",
    enrollmentTours: "Enrollment tours",
    waitlistFamilies: "Waitlist families",
    nonWaitlistFamilies: "Non-waitlist families",
    tooltipConfirmed: "Parent confirmed",
    availabilityTitle: "Weekly Tour Availability",
    availabilitySubtitle:
      "Duration {duration} minutes | Capacity {capacity} per tour",
    exactDateTitle: "Open House",
    exactDateSubtitle:
      "Tour times for specific dates in addition to the weekly calendar",
    addExactDateAvailability: "Schedule Open House",
    family: "family",
    families: "families",
    noTourAvailability: "No Tour Offered",
    allAgeGroups: "All Age groups",
    virtualTour: "Virtual Tour",
    notVirtualTour: "Not a Virtual Tour",
    unavailableTourDatesTitle: "Unavailable Tour dates",
    unavailableTourDatesSubtitle:
      "Enter specific dates you are unavailable for tours",
    addUnavailableTourDate: "Add unavailable date",
  },
  tourPolicy: {
    advanceNoticeHours:
      "Families must give {Hours} business hours advanced notice when scheduling a tour",
    advanceNoticeDays:
      "Families must give {Days} business days advanced notice when scheduling a tour",
    buttonSave: "Save changes",
    dialogTitle: "Change Tour Policies",
    noPolicy: "You have not set your tour policy",
    policy: "Your tour policy is set to:",
    title: "Tour Policies",
    subtitle: "Enter your tour policies",
    virtualInstructions: "Your virtual tour instructions are set to:",
    labelAdvanceNotice:
      "Enter the advanced notice you need for scheduled tours. If a family schedules a tour after your program is closed, they will not be able to schedule a tour until this many hours past your opening time",
    labelTourPolicy:
      "If you have tour policies that you would like to share with families, enter them here. These instructions will appear on your Enrollment Page and in spot offers",
    labelVirtualInstructions:
      "If you have the same video conferencing details for all of your tours, enter them here and they will added to your notes when scheduling a tour. If you use different video links per tour, please leave this section empty.",
    advanceNoticeHoursText: "hours",
    advanceNoticeDaysText: "days",
    tourPolicyText: "Tour policy",
    virtualInstructionsText: "Virtual tour instructions",
    updateTourPolicy: "Update Tour Policies",
  },
  tourSchedule: {
    scheduleAgeGroup: "We offer tours for {AgeGroup} families on {Schedule}.",
    scheduleNoAgeGroups: "We offer tours on {Schedule}.",
    scheduleWithAgeGroups:
      "We offer tours to all families on {Schedule}. {AgeGroups}",
  },
  tourSchedules: {
    ageGroupAll: "All",
    buttonAdd: "Add new tour slots",
    capacity:
      "The number of families that can attend per tour time slot is <b>{Capacity}</b>",
    duration: "Your default tour duration is <b>{Duration} minutes</b>",
    headers: {
      day: "Day",
      ageGroup: "Age group",
      timeSlot: "Time Slot",
      virtual: "Virtual",
    },
    noTours: "No tours offered",
    tourSchedules: "Tour Availability",
    tourSchedulesInstructions:
      "These are the days and times that you are available to conduct program tours. You can make changes by clicking the pencil icon to the right.",
  },
  transfers: {
    alertMessage: "Are you sure you want to send {Amount} to {Provider}?",
    buttonTransfer: "Transfer funds",
    noTransfers:
      "There are no pending transfers that need to be sent to providers",
    instructions:
      "This is a list of providers that we took payment for waitlist signups or enrollments before the provider signed up with Stripe. As a result, they have money that we owe them. If the Transfer Funds button is disabled, it means they still have not set up Stripe so we are unable to pay them. Otherwise, click the Transfer Funds button to initiate a transfer with Stripe",
    title: "Eligible Transfers",
    colName: "Provider",
    colCount: "Number of transactions",
    colTotal: "Total value to transfer",
    colTransfer: "Transfer",
    saveFail: "There was a problem setting up transfers for this provider",
    saveSuccess: "Stripe transfers have been set for this provider",
  },
  tuition: {
    buttonAdd: "Add Child Manually",
    buttonPaid: "Mark Paid",
    buttonRemind: "Send Reminder",
    buttonSave: "Select Children",
    buttonSelect: "Select From Enrolled Children",
    buttonReset: "Reset this list from enrolled children",
    buttonUpdateTuition: "Set Tuition",
    changeTuitionTitle: "Set Tuition",
    colChild: "Child",
    colParent: "Parent",
    colEmail: "Email",
    colCost: "Cost",
    instructions:
      "Please fill out this form to complete your payment for {Child}'s tuition at {Center}. You can pay either by credit card or a direct debit from your bank account.",
    instructionsPaid: "You paid {Child}'s tuition at {Center} on {Date}",
    instructionsPending:
      "You initiated a bank transfer for {Child}'s tuition at {Center} on {Date}. We will notify you when this payment has cleared",
    statusChangeTuition: "Update Tuition",
    statusUpdate: "Update family",
    titleEnrolled: "Enrolled children",
    enrolledInstructions:
      "Select children previously enrolled through Kinside that you would like to send tuition reminders to. You can edit the pricing details after you've selected the families to send tuition reminders to",
    noEnrollInstructions:
      "You have not enrolled any families through Kinside. You will have to enter details of families you want to send tuition reminders to manually",
    noBillsInstructions:
      "You have not set up any families to send tuition reminders. Check your enrolled families or manually enter families to send statements to",
    noBillsSent:
      "You have not sent out any tuition reminders. Click on Send Tuition Bills to generate tuition reminders for families.",
    markPaidSuccess: "These bills have been marked as paid",
    markPaidFail: "There was a problem marking these bills as paid",
    markRemindSuccess: "Tuition reminders have been sent",
    markRemindFail: "There was a problem sending reminders",
    pending: "{Date} (not yet cleared)",
    updateTuition: {
      success: "Tuition amount has been updated",
      fail: "There was a problem updating the tuition amount",
    },
  },
  updateSpot: {
    parent: {
      comments: "Other Comments",
      currentPreferred:
        "Not ready yet? Update your preferred enrollment date, which is currently {Date}.",
      dncbTitle:
        "Click here if you don't want to receive offers for spots before your preferred enrollment date.",
      buttonSubmit: "Submit",
      otherComments:
        "Let us know if there is a different reason you don't want this offer.",
      changeExcludeCenter: "Not interested in this program?",
      changeSchedule:
        "{Program} schedule doesn't work for you? Please update your schedule preferences.",
      changeDeactivate:
        "Want to stay on the waitlist but need more time to decide?",
      changeTemporary: "I'm not interested in temporary care",
      deactivateCheckbox:
        "Click here to put your waitlist spot on hold. You will retain your position but will not receive seat offers until you tell us that you'd like to reactivate your spot",
      excludeCenter:
        "Click here if you are not interested in attending this program. Please let us know why in the comments below.",
      notes: "Notes",
      sectionProgram:
        "Select the schedules you are interested in. You may select multiple options if you are flexible",
      temporary: "Don't want a temporary spot?",
    },
    provider: {
      comments: "Other Comments",
      currentPreferred:
        "Is the family not ready yet? Update their preferred enrollment date, which is currently {Date}.",
      dncbTitle:
        "Click here if they would not like to receive offers before their preferred enrollment date.",
      buttonSubmit: "Submit",
      otherComments:
        "Enter a different reason the family doesn't want this offer",
      changeExcludeCenter: "The family isn't interested in this program",
      changeSchedule:
        "If a {Program} schedule doesn't meet their needs, please update their preferred schedule(s).",
      changeDeactivate:
        "I'm not sure how the family is considering future offers. Place them on hold so they can update their preferences",
      changeTemporary: "The parent is only looking for permanent care",
      deactivateCheckbox:
        "Click here to put the family waitlist spot on hold. They will retain their position but will not receive spot offers until they tell us that they'd like to reactivate their spot",
      excludeCenter:
        "Click here if the family is not interested in attending this program",
      notes: "Notes",
      sectionProgram:
        "Select the schedules the family is interested in. You may select multiple options if they are flexible",
      temporary: "Is the family not willing to consider a temporary spot?",
    },
  },
  uploadTypes: {
    importList: "waitlist",
    priceList: "tuition rates",
    depositForm: "registration form",
  },
  user: {
    buttonSave: "Save changes",
    canText: "Receive text message notifications",
    canTextInstructions:
      "Some notifications can be sent directly to your mobile phone.",
    changeEmailTitle: "Changing Email Address",
    changeEmailWarning:
      "If you change your email address, you will be logged out and must sign back in with your new email address. Be sure to click Sign Up to signup for an account with this new email address at the login screen. Would you like to continue?",
    email: "Email",
    first_name: "First Name",
    last_name: "Last name",
    full_name: "Name",
    notification: "Notifications",
    notificationSubtitle:
      "Select which notifications you would like to receive from Kinside",
    notificationLabel: {
      centerLabel:
        "Or indicate for which specific program(s) you would like to receive notifications:",
      centerSeats: "Changes to spot status at {Center}",
      "provider-reports": "Weekly financial and operations reports",
      "provider-seats": "Spot status changes",
      "provider-spots": "Waitlist updates",
      "provider-tours": "Tour scheduling and updates",
      "provider-kinsideEmails": "Product updates and education",
      "partner-partners":
        "Receive white-label marketplace notifications for {Partner}",
    },
    password:
      "To change your password, please log out and select \"Forgot password?\" to reset your password.",
    phone: "Mobile number",
    sectionProfile: "Profile",
    sectionPayments: "Payments",
    stripe: "Manage your payment account with Stripe",
    bankAccount: "Bank account and billing details",
    stripeSetup:
      "Click here to setup an account with Stripe to begin receiving payouts from Kinside",
    stripeSetupProvider:
      "Click here to setup an account for {Provider} with Stripe to begin receiving payouts from Kinside",
    stripeUpdate:
      "Click here to update your payment account information with Stripe",
    stripeUpdateProvider:
      "Click here to update payment account information for {Provider} with Stripe",
    title: "User profile",
  },
  userAccess: {
    alertNoNotification:
      "You must elect to receive either operational or report notifications",
    buttonAdd: "Add User",
    buttonEdit: "Edit User",
    buttonRemove: "Remove User",
    canText: "Receive text message notifications",
    canTextInstructions:
      "Opt-in to receive certain notifications directly to user's mobile phone",
    colAccess: "Access level",
    colName: "Name",
    colUser: "User",
    confirmAdd:
      "Are you sure you want to create a new user account for {User}? This will set up an account and allow them to access Kinside.",
    confirmAddNoCenters:
      "Note there are no centers associated with this account! Providing access to {User} will give them a degraded experience. Are you sure you want to continue giving them access to Kinside?",
    confirmRemove: "Are you sure you want to remove access for {User}?",
    deleteAccount:
      "Check here if also you want to delete this user's Kinside account, in addition to removing access to this provider",
    email: "Email",
    first_name: "First Name",
    last_name: "Last Name",
    newUserLabel: "Create an account to access {Name}",
    noAccess: "No users have account access to {Name}",
    noContact:
      "Check here if you don't want to send an email to the user informing them of their account access",
    notificationLabel: "Choose notification preferences",
    phone: "Mobile number",
    receiveOperating:
      "Receive operational notifications from Kinside related to spot, waitlist spot, and tour updates",
    receiveReports:
      "Receive weekly reports from Kinside on business and financial operations",
    title: "Users with access to {Name}",
  },
  confirmSpot: {
    buttonConfirm: "Confirm",
    instructionsMailer:
      "If you'd like to opt-in to receive spot offer notifications via text message, please update the phone number we have on file and check the box to opt-in to text messages.",
    instructionsNo:
      "Are you sure you want to remove {Child} from {Center}'s waitlist? Please click \"Confirm\" below to confirm",
    instructionsYes:
      "Thanks! Please click below to confirm your interest in keeping {Child} on {Center}'s waitlist",
    instructionsYesMobile:
      "Thanks! Please click \"Confirm\" below to confirm your interest in keeping {Child} on {Center}'s waitlist. If you'd like to opt-in to receive spot offer notifications via text message, please update the phone number we have on file and check the box to opt-in to text messages.",
    canText: "Text me updates.",
    phoneLabel: "Mobile phone",
  },
  vacationDays: {
    buttonAdd: "Add unavailable tour date",
    chooseTitle: "Choose an unavailable tour date",
    instructions: "Enter specific dates that you are not available for tours",
    noneSet: "You do not have any upcoming unavailable tour dates",
    title: "Unavailable tour dates",
    titleAddDay: "Add an unavailable tour date",
  },
  validateLink: {
    buttonNo: "No",
    buttonCancelTour: "Cancel Tour",
    buttonSignUp: "Sign up with Kinside",
    buttonSubmit: "Submit",
    buttonYes: "Yes",
    confirmations: {
      "clean:yes":
        "Thanks for confirming your interest for {Child} at {Center}! Note that this confirmation is for this program and this child only. If you received other mails from Kinside about different programs or children in your household, you will need to confirm those separately.",
      "clean:no":
        "We have removed {Child} from the {Center} waitlist. Note that this confirmation is for this program and this child only. If you received other mails from Kinside about different programs or children in your household, you will need to confirm those separately.",
      "mailer:reactivate":
        "Thank you - we have reinstated your spot on the waitlist.",
      "mailer:remove": "We have removed you from this waitlist",
      "mailer:textoptin": "Thank you for updating your contact preferences",
      "mailer:unsubscribe":
        "Thank you, we will remove you from receiving marketing mails. Note that if you are on a program's waitlist, you will still receive email communications from Kinside related to that program and any openings that may arise. If you wish to remove yourself entirely from all program waitlists and mails from Kinside, please contact us at support@kinside.com",
      survey: "Thank you for submitting your survey response!",
      "offer:remove": "We have removed you from this waitlist",
      "offer:pass":
        "Thanks for letting us know this spot doesn't work for you. You will retain your waitlist position. We will review your notes and make any updates necessary for future openings",
      "offer:pass:legup":
        "Thanks for letting us know this spot doesn't work for you. We will review your notes and make any updates necessary for future openings",
      "offer:tour":
        "We have let the provider know you would like to schedule a final tour. You can expect to hear from them within 2 business days",
      "offer:accept":
        "Thanks! We have let the provider know to send your final paperwork",
      "offer:question":
        "We have sent your question to the provider. You can expect to hear from them within 2 business days",
      "offer:paperwork":
        "Thanks for completing your registration form! You should hear from the provider soon.",
      "tour:confirm":
        "Thanks, we have let the provider know you have confirmed your tour.",
      "tour:update":
        "Thanks, we have let the provider know about this change to your tour.",
      "tuition:bill":
        "Thanks for paying your tuition for {Center}. We have sent you an email confirmation",
      "waitlist:bill":
        "Thanks for paying your waitlist deposit for {Center}. We have sent you an email confirmation",
    },
    contractText:
      "the family is interested in the price option {Name}: {Cost} with registration fee {Deposit}",
    currentTour:
      "You currently have a tour scheduled with {Center} at {Date} for {Child}",
    error: {
      default:
        "Uh oh! There was a problem completing your request. Please try again or contact <a href=\"mailto:support@kinside.com\">support@kinside.com</a> to let them know you experienced an error.",
      INVALIDLINK:
        "This appears to be an invalid URL. Please double-check the link or contact Kinside support",
      INVALIDPARENT:
        "Uh oh! We don’t have a parent account for you in our system. Contact <a href=\"mailto:support@kinside.com\">support@kinside.com</a> for help getting logged in.",
      INVALIDSURVEY:
        "This appears to be an invalid survey link. Please contact Kinside support if you have any questions",
      NOOFFERFOUND:
        "We were unable to find an outstanding spot offer for you. Please contact the child care provider directly for more details about your offer.",
      NOSEATFOUND:
        "We were unable to find this spot opening in our system. Please contact the child care provider directly for more details.",
      NOWAITLISTBILLFOUND:
        "We weren't able to find an unpaid waitlist registration for your account. Please contact the child care provider directly if you have any questions",
      NOTOURFOUND:
        "We weren't able to find a tour for your account. Please contact the child care provider directly if you have any questions",
      NOTUITIONBILLFOUND:
        "We weren't able to find an unpaid tuition bill for your account. Please contact Kinside support if you have any questions.",
      SEATINDIFFERENTSTATE:
        "It looks like the status of your spot has changed. Please contact the child care provider directly to request your registration form.",
      SPOTDELETED:
        "We're sorry, you have been removed from this provider's waitlist. Please contact the child care provider directly for more assistance.",
      SPOTNOTINACTIVE:
        "Your waitlist spot has already been reactivated. Please contact the child care provider directly if you have any questions.",
      SURVEYEXPIRED: "We're sorry, this survey is now closed",
      TOURPAST:
        "Sorry, this tour occurred in the past. Please contact the provider to schedule a new tour.",
      TOURDELETED:
        "Sorry, this tour has already been canceled. Please schedule a new tour or contact the Child Care Provider for more assistance.",
    },
    linkActiveMessage:
      "Sorry, we encountered a problem. You may have previously indicated your enrollment preference. Please reach out to us at support@kinside.com, or login to Kinside at {Url} to continue",
    instructions: {
      "mailer:reactivate":
        "In order to reactivate your spot on the waitlist, please review and update your current waitlist settings",
      "mailer:remove":
        "Please let us know why you'd like to be removed from this waitlist, then click submit to confirm your removal.",
      "mailer:unsubscribe":
        "Please click below to confirm that you want to unsubscribe {Email} from any marketing mails from Kinside. Note that if you are on a program's waitlist, you will still receive email communications from Kinside related to that program and any openings that may arise. If you wish to remove yourself entirely from all program waitlists and mails from Kinside, please contact us at support@kinside.com",
      "offer:remove":
        "Please let us know why you'd like to be removed from this waitlist, then click submit to confirm your removal.",
      "offer:pass":
        "Please let us know why you would like to pass on this opening. You will retain your waitlist position and we will update our records for future openings",
      "offer:pass:legup":
        "Please let us know why you are not interested in this offer. You will remain on our mailing list and we will update our records for future openings",
      "offer:tour":
        "Thanks! In order to schedule your tour, please provide any notes or preferred dates and times for your final tour.",
      "offer:accept":
        "Please review the available pricing options and let us know your preference for this spot",
      "offer:question": "Please enter your question for the provider",
    },
    invalid:
      "Oops. This is an invalid link. Please contact Kinside support if you feel you reached this page in error",
    noAction:
      "Thanks. Please return to your email and choose a different link to continue.",
    noChildName: "your child",
    notes: "Notes",
    removeOptions: [
      "Found care in a different program",
      "Found care through an in-home solution (nanny, family)",
      "Not interested in this program anymore",
      "Other (enter below)",
    ],
    signUp:
      "Want to keep track of your waitlist status? Sign up for a free account with Kinside!",
    tourCancel:
      "If you need to cancel this tour, click here. If you want to reschedule, please select an available date and time below.",
    tourCancelSection: "Need to cancel?",
  },
  viewDashboard: {
    title: "View Provider Dashboard",
    titleFindProvider:
      "Choose a provider or admin unit to view their dashboard",
  },
  viewEmails: {
    buttonClose: "OK",
    buttonFind: "Find Messages",
    buttonResend: "Resend Message",
    colCenter: "Program",
    colRecipient: "Recipient",
    colSubject: "Subject",
    colMessage: "Message",
    colSent: "Sent",
    html: "HTML Content",
    labelFrom: "From",
    labelTo: "To",
    noResults:
      "We didn’t find any notifications to this user in the last month.",
    noSubject: "No Subject",
    parentTitle: "Notifications sent to {Name}",
    parentSubtitle:
      "This is a list of notifications sent by Kinside to {Name} in the past month. Heads up: this doesn’t include any messages you’ve sent to families; which you can see <a href=\"{Path}\">here</a>.",
    sent: "Sent {Date}. Note that we are not tracking read receipt of this message",
    sentNoView: "Sent {Date}. The recipient has not yet viewed this message",
    sentMessage:
      "The message was re-sent. It will come from support@kinside.com",
    sentInvalidFormat:
      "This message is in a format (like a text message) that cannot be resent",
    sentMessageFail: "There was a problem sending this message",
    serverResponse: "Server responded {State} at {Date}",
    title: "View Outgoing Messages",
    viewedOnce: "Sent {Sent}. This message was viewed {Date}",
    viewedMultiple:
      "Sent {Sent}. This message was viewed {Count} times between {Start} and {End}",
  },
  waitlistTableEntry: {
    labelCenter: "Program",
    labelAgeGroup: "Age group",
    labelEnrollment: "Preferred enrollment",
    labelPosition: "Position",
    labelPositionInfo: "Position info",
    labelInternalNotes: "Internal notes",
    labelStatus: "Status",
  },
  waitlistChildren: {
    addChild: "Add Child To Waitlist",
    ageAsOf: "Age as of date spot available",
    ageMonth: "{Month} month",
    ageMonths: "{Month} months",
    ageYearMonth: "{Year} year, {Month} month",
    ageYearMonths: "{Year} year, {Month} months",
    ageYearsMonth: "{Year} years, {Month} month",
    ageYearsMonths: "{Year} years, {Month} months",
    buttonDeposit: "View Registration Form",
    buttonCSV: "View CSV Form",
    colAge: "Current age",
    colAgeGroup: "Age group",
    colChild: "Name",
    colClassroom: "Classroom",
    colDate: "Preferred Enrollment",
    colDncb: "Do Not Contact Before",
    colPaid: "Payment Date",
    colProgram: "Schedules",
    colTemp: "Permanent Care Only",
    colTourDate: "Tour date",
    colDevStage: "Development Stage",
    colDepositUnfillSeat: "Registration / Waitlist",
    colFilled: "Date Filled",
    colPriority: "Priority",
    colPosition: "Position",
    colStart: "Start Date",
    edit: "Edit age group",
    export: "Export Waitlist",
    import: "Import Waitlist",
    importHeaders: "Download Import Template",
    importMessage:
      "Clicking this button will allow you to import an existing waitlist via a CSV file",
    noMatch: "No matching children were found for this waitlist",
    noMatchFilled: "No children have been filled for this program",
    noProgram: "Not set",
    notBorn: "Not yet born",
    preferredLabel: "Preferred Enrollment",
    preferredAll: "Any month",
    preferredAsOf: "{Date} or earlier",
    programNotSet: "Not chosen",
    seatOffered: "This family has been offered a spot",
    ageGroupLabel: "Age group",
    ageGroupAll: "Any Age",
    programLabel: "Schedule",
    programAll: "Any Schedule",
    statusLabel: "Status",
    statusAll: "Any Status",
    statusUnpaid: "Active but not yet paid",
    statusActive: "Active on Waitlist",
    statusHold: "Offered to Family",
    statusInactive: "No Response from Parent / guardian",
    statusTour: "Toured but not on waitlist",
    subsidiesAll: "Private Pay or Subsidy",
    subsidiesAny: "On any Subsidy",
    subsidiesLabel: "Subsidy",
    subsidiesPrivate: "Private Pay",
    title: "Viewing {Name} for {Center}",
    titleSelect: "Find a child",
    tourOnly: "Tour (not on waitlist)",
    trying: "No child expected yet",
  },
  waitlistFees: {
    buttonAddFee: "Add new program fee",
    editFees: "Edit fees",
    buttonSet: "Activate waitlist",
    buttonSave: "Save changes",
    centerFee: "Including Kinside's fee of {Comission}, parents will pay {Fee} to join your waitlist.",
    subsidyPrograms: "Waiving waitlist and deposit fees for families with subsidy programs is {state}.",
    centerFeeWithCommission: "Including Kinside's fee, parents will pay {Fee} to join your waitlist.",
    centerFeeVariable: "The waitlist signup fee will be {Fee}.",
    centerFeeVariableNoSel:
      "The waitlist signup fee depends on which program(s) you choose.",
    colCenter: "Program",
    colCost: "Cost",
    costWithFees:
      "Including Kinside's fees, the family fee to signup for your waitlist will be {Cost}, plus credit card fees",
    costWithFeesIncludingStripe:
      "Including Kinside's fees, the family cost to signup for your waitlist will be {Cost}, which includes credit card fees",
    dialogTitle: "Edit Waitlist Fees",
    instructionsMutiple: "Please enter your waitlist fees charged per program",
    instructionsSingle: "Please enter your waitlist fee amount",
    noFees: "Waitlist signup fees have not yet been set up",
    feeAmount: "Fee amount",
    feesTitle: "Fees",
    addAFeeHeader: "Add a fee for families to pay when they join your waitlist.",
    addAFeeSubHeader:
      "Note: families will pay this amount in addition to a Kinside fee of",
    ifYouChargeAFeeNote:
      "If you charge families a fee to join your waitlist, you can set that amount here.",
    noFeesCreated: "Click \"Activate waitlist\" to set up your waitlist signup fees and activate your waitlist for families.",
    noFeesHaveBeenAdded: "No fees have been added",
  },
  waitlistHeaders: {
    titleEdit: "Edit waitlist form header",
    titleFindProvider: "Set waitlist form header for a provider or a partner",
    titleSetHeader: "{Provider} Waitlist form header",
  },
  waitlistImport: {
    advancedDownload:
      "(Advanced) Download the full set of headers, not just the minimum required fields",
    buttonImport: "Import waitlist",
    buttonTemplate: "Download Template",
    buttonSendContactToStayEmail: "Send initial emails to ask parents to stay",
    buttonSendWelcomeEmail:
      "Send initial emails to ask parents to enroll in Kinside system",
    step1:
      "Step 1. Download the template with the appropriate column headers for this program.",
    step2:
      "Step 2. Fill out this spreadsheet with the details of the families to import",
    step3:
      "Step 3. Upload the spreadsheet with students to add to this program's waitlists",
  },
  waitlists: {
    answerOptional: "An answer to this question is optional",
    answerRequired: "An answer to this question is required",
    buttonChildQuery: "Waitlist Planning",
    buttonViewFilledSpots: "View filled spots",
    buttonViewUnFilledSpots: "View waitlist spots",
    centerQuestions: "Waitlist form questions",
    choices:
      "This is a multiple choice question with possible answers of {Choices}",
    colAgeGroup: "Age group",
    colAges: "Age range",
    colUpdate: "Update Parents / Guardians",
    noMatch: "No age groups were found for {Name}",
    questionCaption:
      "Note that the \"Edit Questions\" feature isn't available just yet. However, you can reach out to your Enrollment Support Team and they can edit your form ASAP",
    standardQuestionCaption:
      "These standard questions are asked of all families and cannot be changed",
    title: "Waitlist for {Name}",
    titleFindCenter: "Choose a Program",
  },
  ageGroupDetails: {
    addPriority: "Add priority",
    ageAsOf: "Child must be in this age range as of",
    ageAsOfChangeTitle: "Child must be in this age range as of",
    ageAsOfEditable:
      "Child must be in this age range as of {Date}. Click here to change this date.",
    ageAsOfReadOnly: "Child must be in this age range as of {Date}. Please contact support if you need to change this date.",
    ageGroupNames: "Infants|Waddlers|Toddlers|Preschool|Pre-K|Custom...",
    annually: "Annually",
    buttonCancel: "Cancel",
    buttonDelete: "Delete age group",
    buttonSave: "Save changes",
    classroomList: "Classrooms for this age group",
    classroomRequired: "Please add at least one classroom",
    closingTime: "Closing time",
    confirmDelete:
      "Note: You can only delete an age group if there are no children associated with the waitlist",
    confirmDeleteTitle: "Are you sure you want to delete this age group?",
    contractList: "Pricing options available for this age group",
    costAmount: "Tuition",
    customName: "Set custom name",
    maxAge: "Maximum age in years & months",
    minAge: "Minimum age in years & months",
    minAgeLabelWeeks: "Minimum age in weeks",
    minAgeWeeks: "Minimum age is in weeks",
    missingContracts:
      "You must choose at least one pricing option for each chosen schedule",
    missingPrograms: "You must choose at least one schedule",
    monthly: "Monthly",
    name: "Age group name",
    noContracts:
      "You must first set up your tuition before you can save this age group. Please go to the tuition page to update.",
    openingTime: "Opening time",
    prioritiesTitle: "Priority definitions",
    priority: "Priority",
    priorityCriteria: "Priority criteria",
    selectName: "Choose age group name",
    selectPrograms: "Choose available schedules for this age group",
    titleAdd: "Adding age group for {Center}",
    titleEdit: "Editing {Name} for {Center}",
    weekly: "Weekly",
  },
  errorMessages: {
    emailValidationError: "Invalid Email Id",
    zipcodeValidationError: "Please enter the 5 digit zip code",
  },
  notifications: {
    addCenterSuccess: {
      success: "Program was added successfully",
      fail: "There was a problem adding this program",
    },
    updateCenterSuccess: {
      success: "You updated your profile!",
      fail: "There was a problem saving program information",
    },
    deleteCenterSuccess: {
      success: "Successfully deleted the program",
      fail: "There was a problem deleting the program",
    },
    updateCenterCMSSuccess: {
      success: "You updated your profile!",
      fail: "There was a problem updating your custom enrollment page content",
    },
    updateDisplayOptionsSuccess: {
      success: "Program Enrollment Options have been updated",
      fail: "There was a problem updating program enrollment options",
    },
    updateTourSchedulesSuccess: {
      success: "Tour availability has been updated",
      fail: "There was a problem updating your tour availability",
    },
    sendWaitlistFormSuccess: {
      success:
        "Your waitlist form sign-up instructions were sent to this family",
      fail: "There was a problem sending your waitlist form to this family",
    },
    setAgeGroupSuccess: {
      success: "AgeGroup, pricing, and scheduling options were saved",
      fail: "There was a problem saving your AgeGroup, pricing, and schedule updates",
    },
    addProviderSuccess: {
      success: "Provider was added successfully",
      fail: "There was a problem adding the provider",
    },
    updateProviderSuccess: {
      success: "Provider information updated",
      fail: "There was a problem saving provider information",
    },
    updateNoWaiveSubsidiesSuccess: {
      success: "Updated coupon waiver for subsidy family settings",
      fail: "There was a problem updating your subsidy coupon waiver settings",
    },
    updateOnboardingSuccess: {
      success: "Onboarding status updated",
      fail: "There was a problem updating the onboarding status",
    },
    addParentSuccess: {
      success: "Parent / Guardian was added successfully",
      fail: "There was a problem adding the parent / guardian",
    },
    signupSuccess: {
      success: "User account was created",
      fail: "There was a problem creating the user account",
    },
    removeUserSuccess: {
      success: "User access was removed",
      fail: "There was a problem removing the user account",
    },
    updateParentSuccess: {
      success: "Parent / Guardian information updated",
      fail: "There was a problem saving parent / guardian information",
    },
    updateParentSubsidiesSuccess: {
      success: "Subsidy Program information has been updated",
      fail: "There was a problem updating subsidy program details",
    },
    mergeChildrenSuccess: {
      success: "Children were successfully combined",
      fail: "There was a problem combining these children",
    },
    addChildSuccess: {
      success: "Child was added successfully",
      fail: "There was a problem adding the child",
    },
    updateChildSuccess: {
      success: "Child information updated",
      fail: "There was a problem saving child information",
    },
    addCouponSuccess: {
      success: "Successfully added a new discount code",
      fail: "There was a problem adding the discount code",
    },
    updateCouponSuccess: {
      success: "Successfully updated discount",
      fail: "There was a problem updating the discount",
    },
    addLeadSuccess: {
      success: "Successfully added a new lead",
      fail: "There was a problem adding the lead",
    },
    updateLeadSuccess: {
      success: "Successfully updated lead",
      fail: "There was a problem updating the lead",
    },
    addAgeGroupSuccess: {
      success: "Successfully added a new age group",
      fail: "There was a problem adding the age group",
    },
    updateAgeGroupSuccess: {
      success: "Successfully updated the age group",
      fail: "There was a problem updating the age group",
    },
    deleteAgeGroupSuccess: {
      success: "Successfully deleted the age group",
      fail: "There was a problem deleting the age group",
    },
    addClassroomSuccess: {
      success: "Successfully added a new classroom",
      fail: "There was a problem adding the classroom",
    },
    updateClassroomSuccess: {
      success: "Successfully updated the classroom",
      fail: "There was a problem updating the classroom",
    },
    addSpotSuccess: {
      success: "Successfully added to waitlist",
      fail: "There was a problem adding to the waitlist",
    },
    removeSpotSuccess: {
      success: "Success!",
      fail: "There was a problem executing this action.",
    },
    reactivateSpotSuccess: {
      success: "The child was successfully added back to the waitlist",
      fail: "There was a problem adding the child back to the waitlist",
    },
    updateSpotSuccess: {
      success: "Successfully saved waitlist spot details",
      fail: "There was a problem saving updates to this waitlist spot",
    },
    fillSpotSuccess: {
      success: "The spot was successfully filled",
      fail: "There was a problem filling this spot",
    },
    uploadFileSuccess: {
      success: "You updated your profile!",
      fail: "There was a problem uploading your file",
    },
    removeFileSuccess: {
      success: "You updated your profile!",
      fail: "There was a problem removing your file",
    },
    submitFeedbackSuccess: {
      success: "Feedback submitted. Thanks!",
      fail: "There was a problem submitting your feedback",
    },
    submitUrlSuccess: {
      success: "URL submitted. Thanks!",
      fail: "There was a problem submitting your URL",
    },
    setFeesSuccess: {
      success: "Your waitlist fee information was updated",
      fail: "There was a problem updating your waitlist fee information",
    },
    importListSuccess: {
      success: "The waitlist was successfully imported",
      fail: "There was a problem importing this waitlist",
    },
    addSeatSuccess: {
      success: "A spot was posted to Kinside",
      fail: "There was a problem adding a spot",
    },
    updateSeatSuccess: {
      success: "The spot was updated",
      fail: "There was a problem saving this spot",
    },
    offerSeatSuccess: {
      success:
        "The spot has been marked as offered. You will be getting an email shortly to forward to the family",
      fail: "There was a problem offering this spot to this parent",
    },
    offerFamilySeatSuccess: {
      success: "Your spot was offered to this family",
      fail: "There was a problem offering this spot",
    },
    updateSeatStateSuccess: {
      success: "Thanks. The status was updated",
      fail: "There was a problem updating this spot(s) status",
    },
    removeSeatSuccess: {
      success: "This spot was removed",
      fail: "There was a problem removing an open spot",
    },
    pauseSeatSuccess: {
      success: "The spot was updated",
      fail: "There was a problem updating this spot",
    },
    offerLegupFamiliesSuccess: {
      success: "This spot has been marked to offer to a Kinside family",
      fail: "There was a problem offering this spot to a Kinside family",
    },
    splitSeatSuccess: {
      success: "The spot was split",
      fail: "There was a problem splitting this spot",
    },
    addNoteSuccess: {
      success: "Spot status was updated",
      fail: "There was a problem updating the status of this spot",
    },
    updateUserSuccess: {
      success: "User details were updated",
      fail: "There was a problem updating user details",
    },
    addProgramSuccess: {
      success: "Successfully added a new schedule",
      fail: "There was a problem adding the schedule.",
    },
    updateProgramSuccess: {
      success: "Successfully updated the schedule",
      fail: "There was a problem updating the schedule",
    },
    copyProgramSuccess: {
      success:
        "The schedule and its pricing options were successfully duplicated",
      fail: "There was a problem duplicating the schedule",
    },
    deleteProgramSuccess: {
      success: "The schedule was successfully deleted",
      fail: "There was a problem deleting the schedule",
    },
    addTourSuccess: {
      success: "Successfully added new tour details",
      fail: "There was a problem adding the tour",
    },
    updateTourSuccess: {
      success: "Successfully updated the tour",
      fail: "There was a problem updating the tour information",
    },
    removeTourSuccess: {
      success: "Successfully canceled the tour",
      fail: "There was a problem canceling the tour",
    },
    addScheduleSuccess: {
      success: "Successfully added a tour to your schedule",
      fail: "There was a problem adding this tour schedule",
    },
    updateScheduleSuccess: {
      success: "Successfully updated the tour schedule",
      fail: "There was a problem updating the tour schedule",
    },
    removeScheduleSuccess: {
      success: "Successfully removed this tour schedule",
      fail: "There was a problem removing the tour schedule",
    },
    addAdminUnitSuccess: {
      success: "Successfully added a new administrative unit",
      fail: "There was a problem creating the administrative unit",
    },
    updateAdminUnitSuccess: {
      success: "Successfully updated the administrative unit",
      fail: "There was a problem updating the administrative unit",
    },
    addNavigatorSuccess: {
      success: "Successfully added a new navigator",
      fail: "There was a problem creating the navigator",
    },
    updateNavigatorSuccess: {
      success: "Successfully updated The Navigator",
      fail: "There was a problem updating The Navigator",
    },
    updateQuestionSuccess: {
      success: "Saved custom questions for this Kinside list",
      fail: "There was a problem updating the questions for this Kinside list",
    },
  },
  providerOnboarding: {
    firstTimeModalHeader: "Welcome to",
    firstTimeModalSubHeader:
      "Our checklist will help you get up and running in a snap.",
    firstTimeModalCta: "Get started",
    scheduleCallDialogHeader: "Schedule a call to get the most out of Kinside",
    scheduleCallDialogP1:
      " Are you ready to automatically manage your waitlist, book and manage your tour schedule, and easily enroll new families—all from your website?",
    scheduleCallDialogP2:
      "Choose a time to meet with a specialist on our onboarding team to complete your account setup and maximize the benefits of Kinside for your business.",
    scheduleCallDialogButton: "Schedule your call",
    basicDetailsTitle: "Enter basic details",
    ageRangesTitle: "Set age ranges",
    tuitionTitle: "Add tuition rates",
    schedulesTitle: "Add schedules",
    classroomsTitle: "Create classrooms",
    tourAvailabilityTitle: "Enter tour availability",
    profileTitle: "Add info to your profile",
    onboardingCallTitle: "Complete your onboarding call",
    loadingProgress: "Loading setup progress",
    guideHeader: "Finish setting up your account",
    itemStartButton: "Start",
    itemEditButton: "Edit",
    infoPipelineEnrollZeroStateCta: "Add open spots",
    infoToursDetailZeroStateCta: "Enter tour availability",
    infoWaitlistDetailZeroStateCta: "View waitlist",
  },
  locations: {
    title: "Location",
    all: "All locations",
  },
  providerProfile: {
    providerDetails: "Provider details",
    providerCaption: "This information applies to all locations.",
    contactInfo: "Contact info",
    businessName: "Business name",
    address: "Address",
    website: "Website",
    documents: "Documents",
    onboardingCall: "Onboarding call",
    onboardingCallState: {
      complete: "Call complete",
      notComplete: "Call not complete",
    },
    firstTime: {
      title:
        "Complete your profile to tell families what makes your program special!",
      subtitle: "Only takes 5 minutes to set up the basics",
      button: "Get started!",
      exampleImg: "Profile example image",
    },
    centers: "Centers",
    userAccess: "Users with access to this provider",
  },
};

export default strings;
